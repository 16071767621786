import React, { useState } from "react"
import styled, { css } from "styled-components"
import { ReactComponent as Search } from "../../../static/icons/Search.svg"
import PropTypes from "prop-types"
import { LoadingSpinner } from "../../loading-spinner/LoadingSpinner"

const ComponentWrapper = styled.div`
  padding: 10px 0 40px 0;
  position: relative;
`
const InputWrapper = styled.div`
  position: relative;
`

const iconWrapperTransform = (isFocused) => {
  if (isFocused) {
    return css`
      opacity: 1;
      transform: translate(0, -50%);
    `
  } else {
    return css`
      opacity: 0;
      transform: translate(10px, -50%);
    `
  }
}

const transitionIconColor = (isValid) => {
  if (isValid) {
    return css`
      svg g {
        transition: fill 200ms ease-out;
        fill: ${(props) => props.theme.colors.lightBlue};
      }
    `
  }
}

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transition: transform 200ms ease-out, opacity 200ms ease-out;
  ${(props) => iconWrapperTransform(props.isFocused)};
  ${(props) => transitionIconColor(props.isValid)}
`

const LoadingWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 14px;
  svg {
    stroke: ${(props) => props.theme.colors.lightBlue};
  }
`

const StyledInput = styled.input`
  height: 40px;
  font-size: 14px;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 10px 5px 10px 0;
  width: 100%;
  :focus {
    outline: none;
  }
`

const labelTransform = (isFocused) => {
  if (!isFocused) {
    return css`
      opacity: 0;
      transform: translateY(-1px);
    `
  } else {
    return css`
      opacity: 1;
      transform: translateY(10px);
    `
  }
}

const Label = styled.label`
  position: absolute;
  ${(props) => labelTransform(props.isFocused)}
  transition: transform 200ms ease-out, opacity 200ms ease-out;
  font-size: 0.8em;
  z-index: -1;
  color: ${(props) => props.theme.colors.grey};
`

const strokeTransform = (isFocused) => {
  if (isFocused) {
    return css`
      background-color: ${(props) => props.theme.colors.lightBlue};
      height: 2px;
    `
  }
}
const Stroke = styled.div`
  background-color: ${(props) => props.theme.colors.lightGrey};
  height: 1px;
  transition: height 100ms linear, color 200ms ease-out;
  ${(props) => strokeTransform(props.isFocused)}
`
/* We can make this component generic later on if necessary */
const Component = (props) => {
  const [isFocused, setIsFocused] = useState()
  const { isValid, onChange, onExecuteSearch, isLoading } = props
  let { value } = props

  if (value === undefined) {
    value = ""
  }

  const handleExecuteSearch = () => {
    if (isValid) {
      onExecuteSearch()
    }
  }

  return (
    <ComponentWrapper>
      <InputWrapper>
        <StyledInput
          placeholder="Search"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          isFocused={isFocused}
          onChange={(e) => onChange(e.currentTarget.value)}
          value={value}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              handleExecuteSearch()
            }
          }}
        />
        {isLoading && (
          <LoadingWrapper>
            <LoadingSpinner dark size="small" />
          </LoadingWrapper>
        )}

        <IconWrapper isValid={isValid} isFocused={isFocused}>
          {!isLoading && (
            <button onClick={handleExecuteSearch}>
              <Search />
            </button>
          )}
        </IconWrapper>
      </InputWrapper>
      <Stroke isFocused={isFocused} />
      <Label isFocused={isFocused}>{props.label}</Label>
    </ComponentWrapper>
  )
}

Component.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onExecuteSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

export const SearchInput = Component
