import React from "react"
import { ScreenClassProvider } from "react-grid-system"
import { ThemeProvider } from "styled-components"
import theme from "../styles/core/theme"

import Head from "./elements/head"
import GlobalStyles from "../styles/core/global"
import Fonts from "../styles/core/fonts"
import { WHITE } from "../styles/core/colours"

export default ({ children, meta, backgroundColour = WHITE }) => (
  <ThemeProvider theme={theme}>
    <ScreenClassProvider>
      <Head {...meta} />
      <GlobalStyles backgroundColour={backgroundColour} />
      <Fonts />
      {children}
    </ScreenClassProvider>
  </ThemeProvider>
)
