import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import * as moment from "moment"
import { ReactComponent as Twitter } from "../../static/icons/twitter.svg"
import { ReactComponent as TwitterHeart } from "../../static/icons/twitter-heart.svg"

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 1em;

  border: 6px solid ${(props) => (props.selected ? "#1976dc" : "#fff")};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const ProfileImage = styled.img`
  border-radius: 100%;
  max-width: 40px;
`

const UserDetals = styled.div`
  padding-left: 0.5em;
`

const Name = styled.div`
  font-weight: bold;
`

const ScreenName = styled.div`
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.midGrey};
`

const Text = styled.div`
  padding: 1em 0;
  overflow-wrap: break-word;
`

const Time = styled.div`
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.midGrey};
`

const TwitterLogo = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
`

const FavouriteCount = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.midGrey};
  font-size: 0.8em;
  margin-top: 0.6em;
`

const Component = ({
  profileImage,
  name,
  screenName,
  text,
  created,
  favouriteCount,
  selected,
}) => {
  return (
    <Wrapper selected={selected}>
      <Header>
        <ProfileImage src={profileImage} alt={name} />
        <UserDetals>
          <Name>{name}</Name>
          <ScreenName>@{screenName}</ScreenName>
        </UserDetals>
        <TwitterLogo>
          <Twitter />
        </TwitterLogo>
      </Header>
      <Text>{text}</Text>
      <Time>{moment(created).format("hh A - MMM D, YYYY")}</Time>
      <FavouriteCount>
        <TwitterHeart /> &nbsp;{favouriteCount}
      </FavouriteCount>
    </Wrapper>
  )
}

Component.propTypes = {
  name: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  profileImage: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  favouriteCount: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool,
}

export const TwitterCard = Component
