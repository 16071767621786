import React, { useState } from "react"
import { Images } from "./Images"
import { FilePond, registerPlugin } from "react-filepond"
import { SearchInput } from "../form-components/search-input/SearchInput"
import FilePondPluginImageResize from "filepond-plugin-image-resize"
import FilePondPluginImageTransform from "filepond-plugin-image-transform"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import { API } from "../../api"
import { LoadingOverlay } from "./LoadingOverlay"
import { ImageSearchResults } from "./ImageSearchResults"
import { BackButton } from "./PageButton"

registerPlugin(
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateType
)

export const Gallery = ({
  gallery,
  sendGalleryImage,
  deleteGalleryImage,
  eventId,
}) => {
  const [pond, setPond] = useState()
  const [searchingGetty, setSearchingGetty] = useState()
  const [searchGettyWithPages, setSearchingGettyWithPages] = useState()
  const [gettyResponse, setGettyResponse] = useState()
  const [searchQuery, setSearchQuery] = useState()

  const executeImageSearch = async (page) => {
    const response = await API.getImages(searchQuery, page, 9)
    setGettyResponse(response.data)
  }

  const exitSearch = () => {
    setGettyResponse(undefined)
    setSearchQuery(undefined)
  }

  const pageSearch = async (page) => {
    setSearchingGettyWithPages(true)
    await executeImageSearch(page)
    setSearchingGettyWithPages(false)
  }
  return (
    <div>
      {gettyResponse && (
        <div style={{ paddingBottom: "2em" }}>
          <BackButton onClick={exitSearch} />
        </div>
      )}

      <h1>{gettyResponse ? "Search Results" : "Gallery"}</h1>
      <SearchInput
        value={searchQuery}
        isValid={true} // assume any handle can be added
        onChange={(value) => setSearchQuery(value)}
        onExecuteSearch={async () => {
          setSearchingGetty(true)
          await executeImageSearch(1)
          setSearchingGetty(false)
        }}
        label="Search Getty"
        isLoading={searchingGetty}
      />
      <div style={{ display: "none" }}>
        {/** Have to set a global file pond here, and pass it down, otherwise it throws an error when the image collection re-renders, because the child filepond gets unmounted before it's finished. */}
        {/** Because of this, we set the server props on the ref that is passed down. Note, this will result in a "Expected FilePond props to match memoized props before componentDidUpdate." in the browser,
      but functions as expected. This is a bug in filepond or so it appears.*/}

        <FilePond
          allowFileTypeValidation={true}
          acceptedFileTypes={["image/jpeg", "image/png"]}
          imageTransformOutputMimeType="image/jpeg"
          allowImageResize={true}
          allowImageTransform={true}
          imageResizeTargetWidth={1600}
          imageResizeMode="contain"
          ref={(ref) => setPond(ref)}
        />
      </div>

      <div style={{ position: "relative" }}>
        {(searchingGetty || searchGettyWithPages) && <LoadingOverlay />}
        {gettyResponse && (
          <ImageSearchResults
            eventId={eventId}
            sendGalleryImage={sendGalleryImage}
            images={gettyResponse.images}
            hasPrevPage={gettyResponse.currentPage > 1}
            hasNextPage={gettyResponse.currentPage < gettyResponse.totalPages}
            onFinishSearching={exitSearch}
            onPrevPage={async () => {
              await pageSearch(gettyResponse.currentPage - 1)
            }}
            onNextPage={async () => {
              await pageSearch(gettyResponse.currentPage + 1)
            }}
          />
        )}
        {!gettyResponse && (
          <Images
            images={gallery.data}
            sendGalleryImage={sendGalleryImage}
            deleteGalleryImage={deleteGalleryImage}
            eventId={eventId}
            pond={pond}
          />
        )}
      </div>
    </div>
  )
}
