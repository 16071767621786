//Only apply native focus if user is using keyboard to navigate
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    // the "I am a keyboard user" key
    document.body.classList.add("user-is-tabbing")
    window.removeEventListener("keydown", handleFirstTab)
  }
}

window.addEventListener("keydown", handleFirstTab)

export const renderGlobalIframelyWidgets = () => {
  window.iframely && window.iframely.load() // https://iframely.com/docs/reactjs
}
