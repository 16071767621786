import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  text-align: center;
  padding-bottom: 5em;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`
const Title = styled.div`
  font-weight: 700;
  margin-bottom: 1em;
`

const Content = styled.div`
  font-weight: 300;
`

export const EmptyState = ({ title, content }) => {
  return (
    <Wrap>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Wrap>
  )
}
