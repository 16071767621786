import React from "react"
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner"
import styled from "styled-components"
import { DARK_BLUE } from "../../styles/core/colours"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: ${(props) =>
    `rgba(255, 255, 255, ${props.opacity ? props.opacity : 0.4})`};
`
const Text = styled.div`
  font-size: 0.8em;
  color: ${DARK_BLUE};
  margin-top: 0.5em;
`
export const LoadingOverlay = ({ opacity, text }) => {
  return (
    <Wrapper opacity={opacity}>
      <LoadingSpinner dark={true} />
      {text && <Text>{text}</Text>}
    </Wrapper>
  )
}
