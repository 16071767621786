import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import auth0Client from "./Auth"
import { LoadingOverlay } from "./components/loading-overlay/LoadingOverlay"

class Callback extends Component {
  async componentDidMount() {
    try {
      await auth0Client.handleAuthentication()
      this.props.history.replace("/")
    } catch (err) {
      if (err.error !== "login_required") console.log(err.error)
    }
  }

  render() {
    return <LoadingOverlay />
  }
}

export default withRouter(Callback)
