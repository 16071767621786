import React from "react"
import Fade from "react-reveal/Fade"
import { Heading } from "../heading/heading"
import { Container, Row, Col } from "react-grid-system"

import { Wrapper } from "./styles"
import { MomentView } from "./MomentView"
import { augmentMomentsArray } from "."

import styled from "styled-components"

const MomentWrap = styled.div`
  margin-bottom: 2em;

  input {
    padding-top: 0;
  }
`
const List = (props) => {
  const {
    keyMoments,
    maximum,
    sendKeyMoment,
    deleteKeyMoment,
    editKeyMoment,
    eventId,
  } = props

  let lastRenderedKeyMoment

  const momentsToRender = augmentMomentsArray(keyMoments.data || [], maximum)

  return (
    <Wrapper>
      <Fade delay={400}>
        <Container fluid style={{ margin: "0 20px" }}>
          <Row>
            <Col>
              <Heading tag="h1">Key Moments</Heading>
            </Col>
          </Row>
        </Container>
      </Fade>
      <Fade bottom distance="10px">
        <Container fluid style={{ margin: "0 20px" }}>
          <Row>
            <Col>
              <div>
                {momentsToRender && momentsToRender.length ? (
                  <div>
                    {momentsToRender.map((keyMoment) => {
                      let disabled = false

                      if (lastRenderedKeyMoment) {
                        disabled = lastRenderedKeyMoment.id === undefined
                      }

                      lastRenderedKeyMoment = keyMoment

                      return (
                        <MomentWrap>
                          <MomentView
                            disabled={disabled}
                            moment={keyMoment}
                            {...{
                              sendKeyMoment,
                              deleteKeyMoment,
                              editKeyMoment,
                            }}
                            eventId={eventId}
                          />
                        </MomentWrap>
                      )
                    })}
                  </div>
                ) : (
                  <p>No Key Moments to display</p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fade>
    </Wrapper>
  )
}

export default List
