import React from "react"
import { ReactComponent as Oval } from "../../static/icons/oval.svg"
import styled from "styled-components"
import PropTypes from "prop-types"
import { DARK_BLUE, WHITE } from "../../styles/core/colours"

const getDimension = (size) => {
  switch (size) {
    case "small":
      return 16 // Use inside buttons
    default:
      return 32
  }
}
const Wrapper = styled.span`
  display: flex;
  svg {
    height: ${(props) => getDimension(props.size)}px;
    width: ${(props) => getDimension(props.size)}px;
    stroke: ${(props) => {
      return props.dark ? DARK_BLUE : WHITE
    }};
  }
`
const Component = ({ size, dark, style }) => {
  return (
    <Wrapper style={style} size={size} dark={dark}>
      <Oval />
    </Wrapper>
  )
}

Component.propTypes = {
  size: PropTypes.string,
}

export const LoadingSpinner = Component
