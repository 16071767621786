import styled from "styled-components"
import { MID_GREY } from "../core/colours"

export const ContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const AccountIcons = styled.div`
  display: flex;
  a,
  button {
    display: inline-block;
    color: ${MID_GREY};
    text-transform: uppercase;
    margin-right: 2em;
  }
`
