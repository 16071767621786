import React, { useState } from "react"
import { ReactComponent as Bin } from "../../static/bin.svg"
import styled from "styled-components"
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner"

const BinButton = styled.button`
  background-color: ${(props) => props.theme.colors.white};
  width: 44px;
  height: 44px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Image = ({ src, onImageDeleted }) => {
  const [deleting, setDeleting] = useState()
  return (
    <div
      style={{
        backgroundImage: `url(${src}?w=800)`,
        height: "100%",
        backgroundSize: "cover",
        position: "relative",
        opacity: deleting ? 0.8 : 1,
      }}
    >
      <BinButton
        disabled={deleting}
        onClick={async () => {
          setDeleting(true)
          await onImageDeleted()
          setDeleting(false)
        }}
      >
        {deleting ? <LoadingSpinner dark size="small" /> : <Bin />}
      </BinButton>
    </div>
  )
}
