import React from "react"
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: rgba(3, 35, 66, 0.3);
  z-index: 10;
`
const Text = styled.div`
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.white};
  margin-top: 0.5em;
`
export const LoadingOverlay = ({ opacity, text }) => {
  return (
    <Wrapper opacity={opacity}>
      <LoadingSpinner />
      {text && <Text>{text}</Text>}
    </Wrapper>
  )
}
