import React from "react"
import styled from "styled-components"
import { gutterSize } from "."

const ItemWrap = styled.div`
  width: ${100 / 3}%;
  padding: ${gutterSize}em;
  display: flex;
  height: 200px;

  @media screen and (max-width: ${(props) => props.theme.responsive.medium}) {
    width: ${100 / 2}%;
  }
`

const ItemWrapInner = styled.div`
  flex: 1;
`

export const ImageGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -${gutterSize}em;
  margin-right: -${gutterSize}em;
`
export const ImageGridItem = ({ children, key }) => {
  return (
    <ItemWrap key={key}>
      <ItemWrapInner>{children}</ItemWrapInner>
    </ItemWrap>
  )
}
