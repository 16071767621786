import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
import { FormField } from "../form-components/form-field/FormField"
import EmbedField from "../embed-field/EmbedField"
import { validStringValue } from "../../styles/core/forms"
import BaseUpdateForm, {
  sharedBaseUpdateFormProps,
} from "../status-update/BaseUpdateForm"
import * as Yup from "yup"

export const LinkUpdate = ({ onDeleteLinkEmbed, ...props }) => {
  const fieldName = "linkEmbedUrl"
  return (
    <BaseUpdateForm
      {...props}
      canSubmitValidator={(values) => {
        return validStringValue(values.linkEmbedUrl)
      }}
      extraFieldsDefaultValues={{ linkEmbedUrl: "" }}
      extraFields={(formProps, populateTimeField) => (
        <Field name={fieldName}>
          {({ field, form }) => {
            const errorMessage = form.errors[field.name]
            const touched = form.touched[field.name] === true
            return (
              <FormField
                name={field.name}
                animationDelay={100}
                touched={touched}
                errorMessage={errorMessage}
              >
                <EmbedField
                  url={field.value}
                  onUrlChange={(url) => formProps.setFieldValue(fieldName, url)}
                  isError={touched && errorMessage !== undefined}
                  label="Link URL"
                  onBlur={() => formProps.setFieldTouched(field.name, true)}
                  onDelete={onDeleteLinkEmbed}
                  onFocus={populateTimeField}
                />
              </FormField>
            )
          }}
        </Field>
      )}
      validationSchema={Yup.object().shape({
        // Wait till end to run through what validations are required.
        linkEmbedUrl: Yup.string().required(
          "Please add a link to an embeddable content piece"
        ),
      })}
    />
  )
}

LinkUpdate.propTypes = {
  ...sharedBaseUpdateFormProps,
  onDeleteLinkEmbed: PropTypes.func.isRequired,
}
