// I've named this simial to the existing. Will clean this up when I go through to tidy everything at the end
import React, { useState } from "react"
import styled from "styled-components"
import { getInputWrapBorderBottom } from "../../../styles/core/forms"

import Rte from "../rte/Rte"

const InputWrapper = styled.div`
  padding: 0.4em 0 0 0;
  position: relative;
`
const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

// We use a border wrapper to prevent jarring when the border size
// changes on focus
const BorderWrapper = styled.div`
  ${(props) => getInputWrapBorderBottom(props)}
`
export const TextArea = (props) => {
  const { icon } = props
  const [focus, setFocus] = useState()

  return (
    <InputWrapper>
      <BorderWrapper focus={focus} isError={props.isError}>
        <Rte
          {...props}
          onFocus={(e) => {
            setFocus(true)
            props.onFocus && props.onFocus(e)
          }}
          onBlur={(e) => {
            setFocus(false)
            props.onBlur && props.onBlur(e)
          }}
          onChange={(e) => {
            props.onChange && props.onChange(e)
          }}
        />
      </BorderWrapper>
      {icon && <IconWrapper>{icon}</IconWrapper>}
    </InputWrapper>
  )
}
