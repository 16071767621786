import styled from "styled-components"
import {
  MID_BLUE,
  DARK_BLUE,
  WHITE,
  LIGHT_BLUE,
} from "../../../styles/core/colours"
import { ReactComponent as PlusIcon } from "../../../static/icons/plus.svg"
import { ReactComponent as UploadIcon } from "../../../static/icons/upload.svg"

export const Wrapper = styled.div``

export const Content = styled.div`
  width: 100%;
  padding: 25px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justifty-content: flex-start;
  background-color: ${DARK_BLUE};
`

export const SubHeading = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #0091ea;
`

export const MinuteMark = styled.span`
  display: inline-block;
  margin: 11px 0 7px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: ${WHITE};
`

export const TeamName = styled.span`
  display: inline-block;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: ${WHITE};
`

export const PlayerName = styled.span`
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  color: ${WHITE};
`

export const GoalImagesSubHeading = styled.span`
  display: inline-block;
  margin-top: 24px;
  font-size: 8px;
  font-weight: 300;
  line-height: 9px;
  color: #dbdbdb;
  text-transform: uppercase;
`

export const GoalImageContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 8px;
`

export const GoalImagesSelector = styled.div`
  display: block;
  padding: 11px 31px;
  border: 1px solid #8191a0;
  transition: border 200ms;
  cursor: pointer;

  &:hover {
    border: 1px solid ${WHITE};
  }
`

export const EditOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: all 200ms;
  pointer-events: none;

  svg {
    g {
      fill: ${WHITE};
    }
  }
`

export const GoalImagesSelectorWithImage = styled.div`
  min-width: 96px;
  min-height: 50px;
  border: 1px solid ${LIGHT_BLUE};
  position: relative;
  transition: border 200ms;

  &:hover {
    cursor: pointer;

    ${EditOverlay} {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.4);
      pointer-events: all;
    }
  }
`

export const GoalImagesSelectorPlusContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledPlusIcon = styled(PlusIcon)`
  g {
    fill: ${WHITE};
  }
`

export const ActionCta = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${WHITE};
`

export const PostedText = styled.span`
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${MID_BLUE};
`

export const ActionCtaShareContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledShareIcon = styled(UploadIcon)`
  width: 20px;
  height: 20px;
`
