import React from "react"
import styled from "styled-components"
import { ReactComponent as Plus } from "../../static/icons/plus.svg"
import PropTypes from "prop-types"

const Button = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.lighterGrey};
  width: 44px;
  height: 44px;
  z-index: 4;
`
const IconWrap = styled.div`
  transform: rotate(45deg);
`

export const DeleteButton = ({ onClick }) => {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <IconWrap>
        <Plus />
      </IconWrap>
    </Button>
  )
}

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
