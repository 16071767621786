import styled, { css } from "styled-components"
import { GREY, WHITE, BLACK } from "../../styles/core/colours"

export const TopNavigationContainer = styled.div`
  display: flex;
  background: ${(props) => (props.hasTopPanelOpen ? "transparent" : GREY)};
  transition: 0.3s background ease-in-out;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  height: 70px;
  svg {
    fill: ${(props) => props.theme.colors.midGrey};
  }

  /* Without having time to go back and re building the header component and styling, I need to add these classes so that I can override
  the child styles in edit mode. This component can be thoroughly simplified by having the color and fills inherited from the top
  level styled component only, then you you could simply toggle them in that components styles, rather than hooking into them with css classes.  */
  ${(props) =>
    props.hasTopPanelOpen &&
    css`
      .top-nav-account-icons {
        svg {
          fill: ${props.theme.colors.navEditModeColor};
        }
      }

      .event-info-team-name,
      .event-info-date {
        color: ${props.theme.colors.navEditModeColor};
      }

      .event-info-back-icon {
        svg {
          g {
            fill: ${props.theme.colors.navEditModeColor};
          }
        }
      }
    `}

  &.condensed {
    background: transparent !important;
    a {
      color: ${WHITE};
      &:after {
        border-color: ${(props) => props.theme.colors.coreBlue};
      }
    }
    svg {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`

export const Left = styled.div`
  flex: 1;
`

export const Right = styled.div`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`
export const Back = styled.a`
  min-width: 50px; /* At least width of side nav */
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;

  ${(props) =>
    props.condensed &&
    css`
      svg {
        g {
          fill: ${props.theme.colors.coreBlue};
        }
      }
    `}/* &:after {
    content: " ";
    display: inline-block;
    border-right: 1px solid ${BLACK};
    border-bottom: 1px solid ${BLACK};
    transform: rotate(-225deg);
    width: 15px;
    height: 15px;
    position: absolute;
  } */
`
