import React, { useState } from "react"
import PropTypes from "prop-types"
import { SearchInput } from "../form-components/search-input/SearchInput"

const Component = ({ handle, setHandle, isLoading }) => {
  const [value, setValue] = useState(handle || "")

  const cleanForSending = (value) => (value ? value.replace("@", "") : "")

  return (
    <div>
      <div>
        <SearchInput
          value={value}
          isValid={true} // assume any handle can be added
          onChange={(value) => setValue(value)}
          onExecuteSearch={() => setHandle(cleanForSending(value))}
          label="Example: @premierleague"
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

Component.propTypes = {
  handle: PropTypes.string,
  setHandle: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

export const SetHandle = Component
