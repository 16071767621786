import { useState, useEffect, useReducer } from "react"
import { API, extractValidationMessageFromApiError } from "../api"
import { TwitterPanels } from "../components/sidebar-twitter/SidebarTwitterTabs"

const DEFAULT_COMMENT_PAGE_SIZE = 300 //TODO remove this once we have pagination / load more inplace

export const editorReducer = (state, action) => {
  switch (action.type) {
    case "INIT_REDUCER":
      return { ...state, isLoading: true, isError: false }

    case "FETCH_EVENT_SUCCESS":
      return {
        ...state,
        event: { isLoading: false, data: action.payload },
      }

    case "EDIT_EVENT_SUCCESS":
      return {
        ...state,
        hasTopPanelOpen: false,
        isEditingEvent: false,
        event: { isLoading: false, data: action.payload },
      }

    case "FETCH_UPDATES_REQUEST":
      return {
        ...state,
        updates: { ...state.updates, isFetching: true },
      }
    case "FETCH_UPDATES_SUCCESS":
      return {
        ...state,
        updates: { ...state.updates, isFetching: false, data: action.payload },
      }

    case "FETCH_EVENT_FAILURE":
      return {
        ...state,
        event: {
          ...state.event,
          hasErrored: true,
          errorMessage: action.error,
          isLoading: false,
        },
      }
    case "FETCH_UPDATES_FAILURE":
      return {
        ...state,
        hasErrored: true,
        errorMessage: action.error,
        event: {
          ...state.event,
        },
        updates: { ...state.updates, isFetching: false },
      }
    case "SET_ACTIVE_SECTION":
      return {
        ...state,
        activeSection: action.payload,
      }
    case "SECT_ACTIVE_IMAGE":
      return {
        ...state,
        imageAsset: action.payload,
      }

    case "FETCH_PAST_EVENTS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        hasErrored: false,
        hasPastEvents: true,
        errorMessage: "",
        data: { ...state.data, pastEvents: action.payload },
      }
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
        errorMessage: action.error,
      }

    case "START_UPDATE_EDIT":
      return {
        ...state,
        editableUpdate: state.updates.data.find((u) => u.id === action.payload),
      }

    case "CANCEL_UPDATE_EDIT":
      return {
        ...state,
        editableUpdate: undefined,
      }

    case "DELETE_UPDATE_SUCCESS":
      return {
        ...state,
        updates: {
          ...state.updates,
          data: state.updates.data.filter((u) => u.id !== action.payload.id),
        },
      }

    case "FETCH_IMAGES_SUCCESS":
      return {
        ...state,
        images: {
          ...state.images,
          searchResult: action.payload,
        },
      }
    case "SET_EDITABLE_UPDATE":
      return {
        ...state,
        editableUpdate: action.payload,
      }

    case "SET_IMAGE_ASSET_ID":
      return {
        ...state,
        selectedImageAssetId: action.payload,
      }

    case "SET_TWITTER_ASSET_URL":
      return {
        ...state,
        selectedTwitterAssetUrl: action.payload,
      }

    case "CLEAR_IMAGE_ASSET_ID":
      return {
        ...state,
        selectedImageAssetId: undefined,
      }
    case "CLEAR_TWITTER_ASSET_URL":
      return {
        ...state,
        selectedTwitterAssetUrl: undefined,
      }

    case "CLEAR_EDITABLE_UPDATE":
      return {
        ...state,
        editableUpdate: undefined,
      }

    case "SEND_UPDATE_REQUEST":
    case "SEND_EDIT_UPDATE_REQUEST":
      return {
        ...state,
        updates: {
          ...state.updates,
          isLoading: true,
        },
      }

    case "SEND_UPDATE_SUCCESS":
      return {
        ...state,
        updates: {
          ...state.updates,
          isLoading: false,
          data: [action.payload, ...state.updates.data],
        },
      }

    case "SEND_EDIT_UPDATE_SUCCESS":
      const existing = state.updates.data.find(
        (u) => u.id === action.payload.id
      )
      const indexOf = state.updates.data.indexOf(existing)
      const newArray = [...state.updates.data]
      newArray[indexOf] = action.payload
      return {
        ...state,
        editableUpdate: undefined,
        updates: {
          ...state.updates,
          isLoading: false,
          data: newArray,
        },
      }

    case "SEND_UPDATE_FAILURE":
    case "SEND_EDIT_UPDATE_FAILURE":
      return {
        ...state,
        // Not using server response, as it'll likely be unfriendly
        // (we handle validation before hand in the UI), and won't
        // fit the design we have.
        updates: {
          ...state.updates,
          isLoading: false,
          errorMessage: "Post failed. Try again.",
        },
      }

    case "SEARCH_TWEETS_REQUEST": {
      return {
        ...state,
        tweets: {
          ...state.tweets,
          isSearching: true,
        },
      }
    }

    case "SEARCH_TWEETS_SUCCESS": {
      return {
        ...state,
        tweets: {
          ...state.tweets,
          isSearching: false,
          searchResults: action.payload,
        },
      }
    }

    case "SEARCH_TWEETS_FAILURE": {
      return {
        ...state,
        tweets: {
          ...state.tweets,
          isSearching: false,
        },
      }
    }

    case "SET_TWITTER_SIDEBAR_PANEL": {
      return {
        ...state,
        tweets: {
          ...state.tweets,
          sidebarPanel: action.payload,
        },
      }
    }

    case "GET_HANDLE_TWEETS_REQUEST": {
      const key = action.payload.key
      const newState = prepareTwitterStateKey(state, key)
      newState.isLoading = true

      // This quite brutally will just pull all the existing tweets
      // out of the dom, even if the handle is the same. I'd like
      // to do something nicer, like send a flag into the action
      // if we want to nuke all the existing tweets, but right now
      // I don't have enough time.
      // newState.results = []

      return applyTwitterKeyState(state, key, newState)
    }

    case "GET_HANDLE_TWEETS_SUCCESS": {
      const key = action.payload.key
      const newState = prepareTwitterStateKey(state, key)
      newState.isLoading = false
      newState.results = action.payload.results
      newState.hasSearched = true
      return applyTwitterKeyState(state, key, newState)
    }

    case "GET_HANDLE_TWEETS_FAILURE": {
      const key = action.payload.key
      const newState = prepareTwitterStateKey(state, key)
      newState.isLoading = false
      newState.error = action.payload.error
      return applyTwitterKeyState(state, key, newState)
    }

    case "FETCH_CLIENT_REQUEST": {
      return {
        ...state,
        clientLoading: true,
      }
    }

    case "FETCH_CLIENT_SUCCESS": {
      return {
        ...state,
        clientLoading: false,
        client: action.payload,
      }
    }

    case "FETCH_CLIENT_FAILURE": {
      return {
        ...state,
        clientLoading: false,
      }
    }

    case "FETCH_CLIENT_EVENT_TYPES_SUCCESS": {
      return {
        ...state,
        clientEventTypes: action.payload,
      }
    }

    case "GET_SEASON_LIST_REQUEST": {
      return {
        ...state,
        seasonListLoading: true,
      }
    }
    case "GET_SEASON_LIST_SUCCESS": {
      return {
        ...state,
        seasonListLoading: false,
        seasonList: action.payload,
      }
    }

    case "GET_SEASON_LIST_FAILURE": {
      return {
        ...state,
        seasonListLoading: false,
      }
    }

    case "SET_TEMPUS_IMAGE_SELECTOR_DATA": {
      const matchId = action.payload.matchId
      const time = action.payload.time
      const tempusCard = action.payload.tempusCard
      return {
        ...state,
        tempusImagePickerData: {
          isLoading: false,
          data: { matchId, time, tempusCard },
        },
      }
    }

    // case "GET_CURRENT_EVENT_TIME_REQUEST": {
    //   return {
    //     ...state,
    //     gettingCurrentEventTime: true
    //   }
    // }
    // case "GET_CURRENT_EVENT_TIME_SUCCESS": {
    //   return {
    //     ...state,
    //     gettingCurrentEventTime: false,
    //     currentEventTime: action.payload
    //   }
    // }

    // case "GET_CURRENT_EVENT_TIME_FAILURE": {
    //   return {
    //     ...state,
    //     gettingCurrentEventTime: false
    //   }
    // }

    case "RECEIVED_REAL_TIME_EVENT_DATA": {
      return {
        ...state,
        realTimeEventData: action.payload,
      }
    }

    //Top Panel features

    case "SHOW_EDIT_EVENT":
      return {
        ...state,
        hasTopPanelOpen: true,
        isEditingEvent: true,
      }

    case "SHOW_KEY_MOMENTS": {
      return {
        ...state,
        hasTopPanelOpen: true,
        isEditingKeyMoments: true,
      }
    }

    case "SHOW_GALLERY":
      return {
        ...state,
        hasTopPanelOpen: true,
        isEditingGallery: true,
      }

    case "SHOW_TEMPUS_IMAGE_PICKER":
      return {
        ...state,
        hasTopPanelOpen: true,
        isTempusImagePicker: true,
      }

    case "CLOSE_TOP_PANEL": {
      return {
        ...state,
        hasTopPanelOpen: false,
        isEditingEvent: false,
        isEditingKeyMoments: false,
        isEditingGallery: false,
        isTempusImagePicker: false,
      }
    }

    // Key Moments

    case "FETCH_KEY_MOMENTS_SUCCESS": {
      return {
        ...state,
        keyMoments: {
          data: action.payload.keyMoments,
        },
      }
    }

    case "SEND_KEY_MOMENT_SUCCESS": {
      return {
        ...state,
        keyMoments: {
          data: [...state.keyMoments.data, action.payload],
        },
      }
    }

    case "EDIT_KEY_MOMENT_SUCCESS": {
      const newData = [...state.keyMoments.data]
      const item = newData.find((u) => u.id === action.payload.id)
      const index = newData.indexOf(item)
      newData[index] = action.payload
      return {
        ...state,
        keyMoments: {
          data: newData,
        },
      }
    }

    case "DELETE_KEY_MOMENT_SUCCESS": {
      return {
        ...state,
        keyMoments: {
          ...state.keyMoments,
          data: [...state.keyMoments.data].filter(
            (u) => u.id !== action.payload
          ),
        },
      }
    }

    // Key Moments

    case "GET_GALLERY_SUCCESS": {
      return {
        ...state,
        gallery: {
          data: action.payload.items,
        },
      }
    }

    case "SEND_GALLERY_IMAGE_SUCCESS": {
      return {
        ...state,
        gallery: {
          data: [...state.gallery.data, { image: action.payload }],
        },
      }
    }

    case "DELETE_GALLERY_IMAGE_SUCCESS": {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          data: [...state.gallery.data].filter(
            (u) => u.image.id !== action.payload
          ),
        },
      }
    }

    default:
      return state
  }
}

const prepareTwitterStateKey = (state, key) => {
  const stateForKey = state.tweets.handleTweets[key]
  const newStateForKey = {
    ...stateForKey,
  }
  return newStateForKey
}

const applyTwitterKeyState = (state, key, keyState) => {
  return {
    ...state,
    tweets: {
      ...state.tweets,
      handleTweets: {
        ...state.tweets.handleTweets,
        [key]: keyState,
      },
    },
  }
}

export const defaultState = {
  hasErrored: false,
  errorMessage: "",
  activeSection: "",
  imageAsset: "",
  inEditModel: false,
  editableUpdate: undefined,
  isEditingEvent: false,
  isEditingKeyMoments: false,
  isEditingGallery: false,
  isTempusImagePicker: false,
  hasTopPanelOpen: false,
  selectedImageAssetId: undefined,
  selectedTwitterAssetUrl: undefined,
  client: undefined,
  clientLoading: false,
  clientEventTypes: false,
  seasonListLoading: false,
  seasonList: [],
  event: {
    isLoading: true,
    data: [],
  },
  updates: {
    isLoading: false,
    errorMessage: undefined,
    data: [],
  },
  images: {
    isLoading: true,
    searchResult: {
      images: [],
    },
  },
  tweets: {
    isSearching: false,
    searchResults: [],
    sidebarPanel: TwitterPanels.official,
    handleTweets: {
      // Because multiple panels can have handle tweets (i.e "official" and "opponent"),
      // we use a hash to store handle tweet state so we don't run into silly issues where
      // the user sees the wrong tweets in the wrong tabs, tweets dissapear, etc
      // See the reducer for how this is accomplished.
    },
  },
  keyMoments: {
    isLoading: true,
    data: [],
  },
  gallery: {
    isLoading: true,
    data: [],
  },
  tempusImagePickerData: {
    isLoading: true,
    data: { matchId: "", time: "" },
  },
}

export let EditorContext

const useEditorPageLogic = (eventId) => {
  const [id] = useState(eventId)

  const initialState = {
    ...defaultState,
    eventId: id,
  }

  const [state, dispatch] = useReducer(editorReducer, initialState)

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      await getClient()
      await getClientEventTypes()
      await getEvent(id, didCancel)
      await getUpdates(id, DEFAULT_COMMENT_PAGE_SIZE, didCancel)
      await getSeasonList()
      await getKeyMoments(id)
      await getGallery(id)
    }

    fetchData()

    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const makeApiCall = async (
    apiCallFunc,
    requestType,
    successType,
    errorType,
    didCancel
  ) => {
    try {
      dispatch({ type: requestType })
      let result = await apiCallFunc
      if (!didCancel) {
        dispatch({ type: successType, payload: result.data })
      }

      // We return results also from this function because sometimes we need to perform
      // actions immediately after API calls, like scrolling to new updates (requires the ID from the server).
      // In these cases, using state is too cumbersome for such basic functionality.
      return result
    } catch (err) {
      if (!didCancel) {
        console.error(err)
        dispatch({ type: errorType, error: err })
      }
    }
  }

  const getEvent = (eventId, didCancel) =>
    makeApiCall(
      API.getEvent(eventId),
      "FETCH_EVENT_REQUEST",
      "FETCH_EVENT_SUCCESS",
      "FETCH_EVENT_FAILURE",
      didCancel
    )

  const editEvent = (data, didCancel) =>
    makeApiCall(
      API.editEvent(data),
      "EDIT_EVENT_REQUEST",
      "EDIT_EVENT_SUCCESS",
      "EDIT_EVENT_FAILURE",
      didCancel
    )

  const getImages = async (searchTerm, didCancel) =>
    makeApiCall(
      API.getImages(searchTerm),
      "FETCH_IMAGES_REQUEST",
      "FETCH_IMAGES_SUCCESS",
      "FETCH_FAILURE",
      didCancel
    )

  const deleteUpdate = (id, didCancel) =>
    makeApiCall(
      API.deleteUpdate(id),
      "DELETE_UPDATE_REQUEST",
      "DELETE_UPDATE_SUCCESS",
      "DELETE_UPDATE_FAILURE",
      didCancel
    )

  const getUpdates = (eventId, pageSize, didCancel) =>
    makeApiCall(
      API.getStatusUpdates(eventId, pageSize),
      "FETCH_UPDATES_REQUEST",
      "FETCH_UPDATES_SUCCESS",
      "FETCH_UPDATES_FAILURE",
      didCancel
    )

  const sendUpdate = async (data, didCancel) => {
    return makeApiCall(
      API.sendUpdate(data),
      "SEND_UPDATE_REQUEST",
      "SEND_UPDATE_SUCCESS",
      "SEND_UPDATE_FAILURE",
      didCancel
    )
  }
  const sendEditUpdate = async (data, didCancel) =>
    makeApiCall(
      API.sendEditUpdate(data),
      "SEND_EDIT_UPDATE_REQUEST",
      "SEND_EDIT_UPDATE_SUCCESS",
      "SEND_EDIT_UPDATE_FAILURE",
      didCancel
    )

  const getClient = (didCancel) =>
    makeApiCall(
      API.getClient(),
      "FETCH_CLIENT_REQUEST",
      "FETCH_CLIENT_SUCCESS",
      "FETCH_CLIENT_FAILURE",
      didCancel
    )

  const getClientEventTypes = (didCancel) =>
    makeApiCall(
      API.getClientEventTypes(),
      "FETCH_CLIENT_EVENT_TYPES_REQUEST",
      "FETCH_CLIENT_EVENT_TYPES_SUCCESS",
      "FETCH_CLIENT_EVENT_TYPES_FAILURE",
      didCancel
    )

  const searchTweets = async (searchTerm, didCancel) =>
    makeApiCall(
      API.searchTweets(encodeURIComponent(searchTerm)),
      "SEARCH_TWEETS_REQUEST",
      "SEARCH_TWEETS_SUCCESS",
      "SEARCH_TWEETS_FAILURE",
      didCancel
    )

  const getKeyMoments = async (eventId, didCancel) =>
    makeApiCall(
      API.getKeyMoments(eventId),
      "FETCH_KEY_MOMENTS_REQUEST",
      "FETCH_KEY_MOMENTS_SUCCESS",
      "FETCH_KEY_MOMENTS_FAILURE",
      didCancel
    )

  const sendKeyMoment = async (data, didCancel) =>
    makeApiCall(
      API.sendKeyMoment(data),
      "SEND_KEY_MOMENT_REQUEST",
      "SEND_KEY_MOMENT_SUCCESS",
      "SEND_KEY_MOMENT_FAILURE",
      didCancel
    )

  const editKeyMoment = async (id, data, didCancel) =>
    makeApiCall(
      API.editKeyMoment(id, data),
      "EDIT_KEY_MOMENT_REQUEST",
      "EDIT_KEY_MOMENT_SUCCESS",
      "EDIT_KEY_MOMENT_FAILURE",
      didCancel
    )

  const deleteKeyMoment = async (id) => {
    await API.deleteKeyMoment(id)
    dispatch({
      type: "DELETE_KEY_MOMENT_SUCCESS",
      payload: id,
    })
  }

  const getGallery = async (eventId, didCancel) =>
    makeApiCall(
      API.getGallery(eventId),
      "GET_GALLERY_REQUEST",
      "GET_GALLERY_SUCCESS",
      "GET_GALLERY_FAILURE",
      didCancel
    )

  const sendGalleryImage = async (data, didCancel) =>
    makeApiCall(
      API.sendGalleryImage(data),
      "SEND_GALLERY_IMAGE_REQUEST",
      "SEND_GALLERY_IMAGE_SUCCESS",
      "SEND_GALLERY_IMAGE_FAILURE",
      didCancel
    )

  const deleteGalleryImage = async (id) => {
    await API.deleteGalleryImage(id)
    dispatch({
      type: "DELETE_GALLERY_IMAGE_SUCCESS",
      payload: id,
    })
  }

  const getHandleTweets = async (key, handle) => {
    try {
      dispatch({ type: "GET_HANDLE_TWEETS_REQUEST", payload: { key } })
      const response = await API.getHandleTweets(handle)
      dispatch({
        type: "GET_HANDLE_TWEETS_SUCCESS",
        payload: { key, results: response.data },
      })
    } catch (error) {
      dispatch({
        type: "GET_HANDLE_TWEETS_FAILURE",
        payload: { key, error: extractValidationMessageFromApiError(error) },
      })
    }
  }

  const setTempusImageSelectorData = (matchId, time, tempusCard) => {
    dispatch({
      type: "SET_TEMPUS_IMAGE_SELECTOR_DATA",
      payload: { matchId, time, tempusCard },
    })
  }

  const getTempusCards = async (id, didCancel) =>
    makeApiCall(
      API.getTempusCards(id),
      "FETCH_TEMPUS_CARD_REQUEST",
      "FETCH_TEMPUS_CARD_SUCCESS",
      "FETCH_TEMPUS_CARD_FAILURE",
      didCancel
    )

  const createTempusCard = async (data, didCancel) =>
    makeApiCall(
      API.createTempusCard(data),
      "SEND_TEMPUS_CARD_REQUEST",
      "SEND_TEMPUS_CARD_SUCCESS",
      "SEND_TEMPUS_CARD_FAILURE",
      didCancel
    )

  const editTempusCard = async (data, didCancel) =>
    makeApiCall(
      API.editTempusCard(data),
      "EDIT_TEMPUS_CARD_REQUEST",
      "EDIT_TEMPUS_CARD_SUCCESS",
      "EDIT_TEMPUS_CARD_FAILURE",
      didCancel
    )

  const showSection = (name) =>
    dispatch({ type: "SET_ACTIVE_SECTION", payload: name })

  const setImageAssetId = (assetId) =>
    dispatch({ type: "SET_IMAGE_ASSET_ID", payload: assetId })
  const clearImageAssetId = () => dispatch({ type: "CLEAR_IMAGE_ASSET_ID" })

  const setTwitterAssetUrl = (url) =>
    dispatch({ type: "SET_TWITTER_ASSET_URL", payload: url })
  const clearTwitterAssetUrl = () =>
    dispatch({ type: "CLEAR_TWITTER_ASSET_URL" })

  const startUpdateEdit = (id) =>
    dispatch({ type: "START_UPDATE_EDIT", payload: id })
  const cancelUpdateEdit = () => dispatch({ type: "CANCEL_UPDATE_EDIT" })

  const setEditableUpdate = (editableUpdate) =>
    dispatch({ type: "SET_EDITABLE_UPDATE", payload: editableUpdate })

  const clearEditableUpdateImage = () =>
    dispatch({ type: "CLEAR_EDITABLE_UPDATE" })

  const setTwitterSidebarPanel = (panel) =>
    dispatch({ type: "SET_TWITTER_SIDEBAR_PANEL", payload: panel })

  // Duplicating so that seasons are available on the create events page
  const getSeasonList = async () => {
    try {
      dispatch({ type: "GET_SEASON_LIST_REQUEST" })
      let result = await API.getSeasonList()
      dispatch({ type: "GET_SEASON_LIST_SUCCESS", payload: result.data })
    } catch (err) {
      dispatch({ type: "GET_SEASON_LIST_FAILURE", error: err })
    }
  }

  const receivedRealTimeEventData = (message) =>
    dispatch({ type: "RECEIVED_REAL_TIME_EVENT_DATA", payload: message })

  const showEditEvent = () => dispatch({ type: "SHOW_EDIT_EVENT" })
  const showKeyMoments = () => dispatch({ type: "SHOW_KEY_MOMENTS" })
  const showGallery = () => dispatch({ type: "SHOW_GALLERY" })
  const showTempusImagePicker = () =>
    dispatch({ type: "SHOW_TEMPUS_IMAGE_PICKER" })
  const closeTopPanel = () => dispatch({ type: "CLOSE_TOP_PANEL" })

  const actions = {
    getEvent,
    editEvent,
    getImages,
    deleteUpdate,
    sendEditUpdate,
    getUpdates,
    sendUpdate,
    getClient,
    showSection,
    setImageAssetId,
    clearImageAssetId,
    setTwitterAssetUrl,
    clearTwitterAssetUrl,
    startUpdateEdit,
    cancelUpdateEdit,
    setEditableUpdate,
    clearEditableUpdateImage,
    searchTweets,
    setTwitterSidebarPanel,
    getHandleTweets,
    receivedRealTimeEventData,
    showEditEvent,
    showKeyMoments,
    showGallery,
    showTempusImagePicker,
    closeTopPanel,
    getKeyMoments,
    sendKeyMoment,
    deleteKeyMoment,
    editKeyMoment,
    getGallery,
    sendGalleryImage,
    deleteGalleryImage,
    setTempusImageSelectorData,
    getTempusCards,
    createTempusCard,
    editTempusCard,
  }
  return { state, dispatch, actions }
}

export default useEditorPageLogic
