import React from "react"
import styled, { css } from "styled-components"
import { EventTypeDropdownWrapper } from "../../../styles/core/forms"
import { EventTypeListTrigger } from "./EventTypeListTrigger"
import EventTypeList from "./EventTypeList"
/**
 * Because we have form duplication for updates, and because each update
 * needs the same functionality in regards to choosing event types, I've
 * done my best to extract components that are "pluggabe" into the forms
 * easier than rewriting the same logic each time
 */

const EventTypeListWrapper = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 200ms ease-in-out;
  ${(props) =>
    props.open &&
    css`
      max-height: 500px;
    `}
`
const EventTypeListInner = styled.div`
  margin-bottom: 2em;
`

export const SharedEventTypeListTrigger = ({
  active,
  setActive,
  currentValue,
  clientType
}) => {
  return (
    <EventTypeDropdownWrapper>
      <EventTypeListTrigger
        active={active}
        onClick={() => {
          if (!currentValue) {
            // Only allow closing the list if the user has
            // not already selected a value from it
            setActive(!active)
          }
        }}
        clientType={clientType}
      />
    </EventTypeDropdownWrapper>
  )
}

export const SharedEventTypeField = ({
  active,
  onChange,
  value,
  clientEventTypes,
}) => {
  return (
    <EventTypeListWrapper open={active}>
      <EventTypeListInner>
        <EventTypeList
          onChange={onChange}
          value={value}
          eventTypes={clientEventTypes}
        />
      </EventTypeListInner>
    </EventTypeListWrapper>
  )
}
