export const WHITE = "#fff"
export const BLACK = "#000"
export const LIGHT_GREY = "#D1D1D1"
export const MID_GREY = "#6E6E6E"
export const LIGHTER_GREY = "#F6F7F8"
export const GREY = "#F4F4F4"
export const LIGHT_BLUE = "#1976DC"
export const LIGHT_BLUE_ACTIVE = "#006EF9"
export const CORE_BLUE = "#1CA3E1"
export const MID_BLUE = "#2F5275"
export const DARK_BLUE = "#032342"
export const DARK_BLUE_ACTIVE = "#04315C"
