import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query"
import { Card } from "./card"
import { Column, StyledSpan } from "./SidebarTempus.styles"

const queryClient = new QueryClient()

const GoalEventRenderer = ({
  eventId,
  matchId,
  showTempusImagePicker,
  tempusExApiKey,
  tempusExApiBaseUrl,
  getTempusCards,
}) => {
  const [goalEvents, setGoalEvents] = useState([])
  const [endCursor, setEndCursor] = useState()
  const [tempusCardList, setTempusCardList] = useState([])

  const callGetAllTempusCards = useCallback(async () => {
    const cards = await getTempusCards(eventId)
    setTempusCardList(cards.data)
  }, [getTempusCards, eventId])

  useEffect(() => {
    callGetAllTempusCards()
  }, [callGetAllTempusCards])

  const headers = {
    Authorization:
      tempusExApiKey && tempusExApiKey.includes(".")
        ? `Bearer ${tempusExApiKey}`
        : `token ${tempusExApiKey}`,
    "Content-Type": "application/json",
  }

  const requestBody = {
    query: `
    query HUDSoccerEvents ($tempusMatchId: ID!) {
      node(id: $tempusMatchId) {
        ... on SoccerMatch {
          eventsConnection(first: 1000${
            endCursor ? ` , after: "${endCursor}"` : ""
          }) {
            edges {
              node {
                time
                __typename
                ... on SoccerMatchGoalEvent {
                  time
                  team
                  minuteDisplay
                  shotBy {
                    name
                  }
                }
              }
            }
            pageInfo {
              endCursor
            }
          }
        }
      }
    }`,
    variables: {
      tempusMatchId: matchId,
    },
  }

  const { isLoading, error, data } = useQuery({
    queryKey: ["goalEvents"],
    queryFn: () =>
      axios
        .post(`${tempusExApiBaseUrl}/v2/graphql`, requestBody, { headers })
        .then((res) => {
          callGetAllTempusCards()
          return res.data
        }),
    refetchInterval: 3000,
  })

  useEffect(() => {
    if (!data || !data.data) return

    if (data.data.node.eventsConnection.pageInfo.endCursor) {
      setEndCursor(data.data.node.eventsConnection.pageInfo.endCursor)
    }

    const filteredEvents = data.data.node.eventsConnection.edges.filter(
      (edge) => edge.node.__typename === "SoccerMatchGoalEvent"
    )
    if (filteredEvents.length > 0) {
      setGoalEvents(filteredEvents)
    }
  }, [data])

  if (isLoading) return <StyledSpan>Loading...</StyledSpan>

  if (error || (data.errors !== undefined && data.errors.length > 0)) {
    return <div>An error has occurred</div>
  }

  if (goalEvents.length <= 0) {
    return (
      <StyledSpan>
        No events currently available for the match. Events will appear here as
        they happen
      </StyledSpan>
    )
  }

  const sortedGoalEvents = goalEvents.sort(function (a, b) {
    const minuteA = parseInt(a.node.minuteDisplay)
    const minuteB = parseInt(b.node.minuteDisplay)
    if (minuteA > minuteB) return -1
    if (minuteA < minuteB) return 1
    return 0
  })

  return (
    <Column>
      {sortedGoalEvents.map((goalEvent) => {
        let tempusCard = []
        if (tempusCardList || tempusCardList.length > 0) {
          tempusCard = tempusCardList.filter((card) => {
            const date = new Date(goalEvent.node.time)
            const roundedMilliseconds =
              Math.round(date.getMilliseconds() / 1000) * 1000
            date.setMilliseconds(roundedMilliseconds)
            const roundedTimeString = date.toISOString()
            const trimmedTimeString = roundedTimeString.substring(
              0,
              roundedTimeString.length - 5
            )
            return card.eventTimestamp === trimmedTimeString
          })
        }
        return (
          <Card
            matchId={matchId}
            event={goalEvent.node}
            showTempusImagePicker={showTempusImagePicker}
            tempusExApiKey={tempusExApiKey}
            tempusExApiBaseUrl={tempusExApiBaseUrl}
            tempusCard={tempusCard.length > 0 ? tempusCard[0] : undefined}
            key={goalEvent.node.time}
          />
        )
      })}
    </Column>
  )
}

GoalEventRenderer.propTypes = {
  eventId: PropTypes.number.isRequired,
  matchId: PropTypes.string.isRequired,
  showTempusImagePicker: PropTypes.func.isRequired,
  tempusExApiKey: PropTypes.string.isRequired,
  getTempusCards: PropTypes.func.isRequired,
}

const Component = ({
  eventId,
  matchId,
  showTempusImagePicker,
  tempusExApiKey,
  tempusExApiBaseUrl,
  getTempusCards,
}) => {
  if (!matchId || !tempusExApiKey) {
    return (
      <StyledSpan>
        Please make sure the Match ID is present, along with the API Key
      </StyledSpan>
    )
  }

  return (
    <QueryClientProvider client={queryClient}>
      <GoalEventRenderer
        eventId={eventId}
        matchId={matchId}
        showTempusImagePicker={showTempusImagePicker}
        tempusExApiKey={tempusExApiKey}
        tempusExApiBaseUrl={tempusExApiBaseUrl}
        getTempusCards={getTempusCards}
      />
    </QueryClientProvider>
  )
}

Component.propTypes = {
  eventId: PropTypes.number.isRequired,
  matchId: PropTypes.string.isRequired,
  showTempusImagePicker: PropTypes.func.isRequired,
  tempusExApiKey: PropTypes.string.isRequired,
  getTempusCards: PropTypes.func.isRequired,
}

export const SidebarTempus = Component
