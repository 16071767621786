import React, { useState } from "react"
import styled, { css } from "styled-components"
import { ReactComponent as Upload } from "../../static/icons/upload.svg"
import { ReactComponent as Add } from "../../static/icons/plus.svg"
import { LoadingOverlay } from "../loading-overlay/LoadingOverlay"

const Wrapper = styled.div`
  margin-bottom: 1em;
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
`

const getSelectedBorder = (selected) => {
  if (selected) {
    return css`
      border: 6px solid ${(props) => props.theme.colors.lightBlue};
    `
  }
}

const Image = styled.img`
  display: block;
  width: 100%;
  ${(props) => getSelectedBorder(props.selected)}
`

const Actions = styled.div`
  button:first-child {
    border-right: 1px solid ${(props) => props.theme.colors.lightGrey};
  }
`
const Button = styled.button`
  display: inline-block;
  width: 50%;
  padding: 1em;

  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  svg {
    width: 20px;
    height: 20px;
  }
`

const doRotate = (selected) => {
  if (selected) {
    return css`
      transform: rotate(45deg);
    `
  }
}

const SelectedRotate = styled.span`
  display: inline-block;
  ${(props) => doRotate(props.selected)}
`

export const ImageSearchResult = ({
  src,
  assetId,
  doImageUpload,
  doEditImage,
  selected,
  doClearEditImage,
}) => {
  const [loading, setLoading] = useState()

  const upload = async () => {
    setLoading(true)
    await doImageUpload(assetId)
    setLoading(false)
  }

  const editImage = async () => {
    if (!selected) {
      setLoading(true)
      await doEditImage(assetId)
      setLoading(false)
    } else {
      doClearEditImage()
    }
  }

  return (
    <Wrapper>
      {loading && (
        <LoadingOverlay opacity={0.8} text="Fetching high res image" />
      )}
      <Image selected={selected} src={src} alt="" />
      <Actions>
        <Button onClick={upload} disabled={selected}>
          <Upload />
        </Button>
        <Button onClick={editImage}>
          <SelectedRotate selected={selected}>
            <Add />
          </SelectedRotate>
        </Button>
      </Actions>
    </Wrapper>
  )
}
