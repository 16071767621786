import styled from "styled-components"
import { DARK_BLUE, LIGHT_BLUE, LIGHT_GREY } from "../../styles/core/colours"

const getEditStateColor = (props) => {
  if (props.deleting || props.deleteMode) {
    return props.theme.colors.red
  }

  if (props.editing) {
    return props.theme.colors.lightBlue
  }

  return props.theme.colors.lightGrey
}
export const Container = styled.div`
  border-bottom: ${(props) =>
      props.deleting || props.deleteMode || props.editing ? 2 : 1}px
    solid ${(props) => getEditStateColor(props)};
  padding-bottom: 10px;
  margin: 30px 0;
`

export const StatusContainer = styled.div`
  display: flex;
  position: relative;
`

export const Minute = styled.div`
  width: 50px;
  color: ${DARK_BLUE};
  font-weight: 700;
`

export const EventType = styled.div`
  position: absolute;
  right: 112px;
  bottom: 0;
  button {
    margin: 0;
  }
`

export const Content = styled.div`
  flex: 1;
  border-left: 1px solid ${LIGHT_GREY};
  padding: 0 20px;
  margin-left: 10px;

  img {
    width: 100%;
  }
`

export const TitleElem = styled.p`
  font-weight: 500;
  margin-bottom: 10px;
  color: ${DARK_BLUE};
`

export const CommentElem = styled.div`
  font-weight: 300;
  color: ${DARK_BLUE};
  p {
    line-height: 1.5em;
    padding-bottom: 5px;
  }
`

export const IconContainer = styled.div`
  display: flex;
`

export const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`

export const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  position: relative;
`

export const CancelButton = styled.button`
  border: 0;
  background: 0;
  font-size: 14px;
  color: ${LIGHT_BLUE};
  padding: 5px 10px;
  font-weight: 700;
  width: auto !important;
  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`
