import React, { useState, useEffect, useCallback } from "react"
import Fade from "react-reveal/Fade"
import { Container, Row, Col } from "react-grid-system"
import axios from "axios"
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query"
import Button from "../../components/form-components/button/button"
import { ReactComponent as ChevronLeft } from "../../static/icons/chevronLeft.svg"
import { TempusImageRenderer } from "../tempus-image-renderer"
import {
  Wrapper,
  Heading,
  ImageWrapper,
  ActionCta,
  BackBtn,
  Spacer,
  ConfirmImagesWrapper,
} from "./styles"

const queryClient = new QueryClient()

const Component = ({
  eventId,
  matchId,
  time,
  tempusCard,
  tempusExApiKey,
  tempusExApiBaseUrl,
  createTempusCard,
  editTempusCard,
  closeTopPanel,
}) => {
  const [pageNum, setPageNum] = useState(1)
  const [imageUrls, setImageUrls] = useState([])
  const [selectedImageUrls, setSelectedImageUrls] = useState({
    shotImage: tempusCard && tempusCard.imageUrl1 ? tempusCard.imageUrl1 : "",
    goalImage: tempusCard && tempusCard.imageUrl2 ? tempusCard.imageUrl2 : "",
  })

  const isEditing = tempusCard && tempusCard.imageUrl1 && tempusCard.imageUrl2

  const headers = {
    Authorization:
      tempusExApiKey && tempusExApiKey.includes(".")
        ? `Bearer ${tempusExApiKey}`
        : `token ${tempusExApiKey}`,
    "Content-Type": "application/json",
  }

  const requestBody = {
    query: `
    query ChelseaStreamInfo ($tempusMatchId: ID!) {
     node(id: $tempusMatchId) {
        id
        ... on SoccerMatch {
          avStreamsConnection(first: 20) {
            edges {
              node {
                id
                name
                hlsManifestURL
                downloadURL
              }
            }
          }
        }
      }
    }`,
    variables: {
      tempusMatchId: matchId,
    },
  }

  const { isLoading, error, data } = useQuery({
    queryKey: ["avStreamConnection"],
    queryFn: () =>
      axios
        .post(`${tempusExApiBaseUrl}/v2/graphql`, requestBody, { headers })
        .then((res) => res.data),
  })

  const processStreamId = useCallback(
    (streamId) => {
      const timeStrings = []
      const milisecondsToScrub = 300
      const numberOfPastEvents = 5
      const numberOfFutureEvents = 10
      const originalTime = new Date(time)
      timeStrings.push(originalTime.toISOString())
      // Generate pastTime events
      for (var pastI = 1; pastI <= numberOfPastEvents; pastI++) {
        const pastTime = new Date(
          originalTime.getTime() - milisecondsToScrub * pastI
        )
        const pastTimeStr = pastTime.toISOString()
        timeStrings.push(pastTimeStr)
      }
      // Generate futureTime events
      for (var futureI = 1; futureI <= numberOfFutureEvents; futureI++) {
        var futureTime = new Date(
          originalTime.getTime() + milisecondsToScrub * futureI
        )
        var futureTimeStr = futureTime.toISOString()
        timeStrings.push(futureTimeStr)
      }

      const imageUrls = timeStrings.map(
        (timeString) =>
          `${tempusExApiBaseUrl}/v2/av-streams/${streamId}/preview.jpg?t=${timeString}&fit=1500x1500&exact=true`
      )
      setImageUrls(imageUrls)
    },
    [time, tempusExApiBaseUrl]
  )

  useEffect(() => {
    if (
      !data ||
      !data.data ||
      data.data.node.avStreamsConnection.edges.length <= 0
    ) {
      return
    }

    const programEvent = data.data.node.avStreamsConnection.edges.filter(
      (edge) => edge.node.name === "Program"
    )
    const streamId = programEvent[0].node.id
    processStreamId(streamId)
  }, [data, processStreamId])

  const handleOnClick = (imageUrl, objKey) => {
    if (selectedImageUrls[objKey] === imageUrl) {
      return setSelectedImageUrls({
        ...selectedImageUrls,
        [objKey]: "",
      })
    }
    setSelectedImageUrls({ ...selectedImageUrls, [objKey]: imageUrl })
  }

  const handleContinue = (e, pageNum) => {
    e.preventDefault()

    if (pageNum === "end") {
      const date = new Date(time)
      const roundedMilliseconds =
        Math.round(date.getMilliseconds() / 1000) * 1000
      date.setMilliseconds(roundedMilliseconds)
      const roundedTimeString = date.toISOString()
      const trimmedTimeString = roundedTimeString.substring(
        0,
        roundedTimeString.length - 5
      )

      const card = {
        eventId: eventId,
        eventType: "Goal",
        eventTimestamp: trimmedTimeString,
        imageUrl1: selectedImageUrls.shotImage,
        imageUrl2: selectedImageUrls.goalImage,
      }
      if (isEditing) {
        card.id = tempusCard.id
        editTempusCard(card)
      } else {
        createTempusCard(card)
      }
      closeTopPanel()
      setPageNum(1)
      queryClient.invalidateQueries({ queryKey: ["goalEvents"] })
      return
    }

    document.querySelector("#tempus-image-picker").scroll(0, 0)
    setPageNum(pageNum)
  }

  if (isLoading)
    return (
      <Container>
        <span></span>
      </Container>
    )

  if (error || (data.errors !== undefined && data.errors.length > 0)) {
    console.error(error)
    return <div>An error has occurred</div>
  }

  return (
    <Wrapper id="tempus-image-picker">
      <Fade delay={400}>
        <Container fluid style={{ margin: "0 20px" }}>
          <Row>
            <Col>
              {pageNum === 1 && (
                <>
                  <Spacer></Spacer>
                  <Heading tag="h1">
                    {isEditing ? "Edit" : "Select"} <strong>Shot Image</strong>
                  </Heading>
                </>
              )}
              {pageNum === 2 && (
                <>
                  <BackBtn onClick={() => setPageNum(1)}>
                    <ChevronLeft />
                    <span>Back</span>
                  </BackBtn>
                  <Heading tag="h1">
                    {isEditing ? "Edit" : "Select"} <strong>Goal Image</strong>
                  </Heading>
                </>
              )}
              {pageNum === 3 && (
                <>
                  <BackBtn onClick={() => setPageNum(2)}>
                    <ChevronLeft />
                    <span>Back</span>
                  </BackBtn>
                  <Heading tag="h1">Selected images</Heading>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Fade>
      <Fade bottom distance="10px">
        {pageNum === 1 ? (
          <Container fluid style={{ margin: "0 20px" }}>
            <ImageWrapper>
              {imageUrls.map((url, index) => (
                <TempusImageRenderer
                  key={index}
                  imageUrl={url}
                  tempusExApiKey={tempusExApiKey}
                  onClick={() => handleOnClick(url, "shotImage")}
                  selected={selectedImageUrls.shotImage === url}
                />
              ))}
            </ImageWrapper>
            <ActionCta>
              <Button
                buttonStyle={
                  selectedImageUrls.shotImage === ""
                    ? "transparentLight"
                    : "light"
                }
                type="button"
                onClick={(e) => handleContinue(e, 2)}
                label="Continue"
                disabled={selectedImageUrls.shotImage === ""}
              />
            </ActionCta>
          </Container>
        ) : pageNum === 2 ? (
          <Container fluid style={{ margin: "0 20px" }}>
            <ImageWrapper>
              {imageUrls.map((url, index) => (
                <TempusImageRenderer
                  key={index}
                  imageUrl={url}
                  tempusExApiKey={tempusExApiKey}
                  onClick={() => handleOnClick(url, "goalImage")}
                  selected={selectedImageUrls.goalImage === url}
                />
              ))}
            </ImageWrapper>
            <ActionCta>
              <Button
                buttonStyle={
                  selectedImageUrls.goalImage === ""
                    ? "transparentLight"
                    : "light"
                }
                type="button"
                onClick={(e) => handleContinue(e, 3)}
                label="Continue"
                disabled={selectedImageUrls.goalImage === ""}
              />
            </ActionCta>
          </Container>
        ) : pageNum === 3 ? (
          <Container fluid style={{ margin: "0 20px" }}>
            <ConfirmImagesWrapper>
              <ImageWrapper>
                <TempusImageRenderer
                  imageUrl={selectedImageUrls.shotImage}
                  tempusExApiKey={tempusExApiKey}
                />
                <TempusImageRenderer
                  imageUrl={selectedImageUrls.goalImage}
                  tempusExApiKey={tempusExApiKey}
                />
              </ImageWrapper>
            </ConfirmImagesWrapper>
            <ActionCta>
              <Button
                buttonStyle={"light"}
                type="button"
                onClick={(e) => handleContinue(e, "end")}
                label="Confirm selection"
              />
            </ActionCta>
          </Container>
        ) : (
          <></>
        )}
      </Fade>
    </Wrapper>
  )
}

export const TempusImagePicker = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Component {...props} />
    </QueryClientProvider>
  )
}
