import { createGlobalStyle } from "styled-components"
import { GREY } from "../../styles/core/colours"
export default createGlobalStyle`
  @import '../../static/css/normalise.css';

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body {
    height:100%;
  }
  
  body {
    background-color: ${({ backgroundColour }) => backgroundColour};
    padding: 0;
    margin: 0;
    position: relative;
    background-color: ${GREY};
  }
  
  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
  
  .visually-hidden {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) a:focus,
  body:not(.user-is-tabbing) textarea:focus {
    outline: none;
  }

`
