import React from "react"
import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"

const ANIMATION_DISTANCE = "10px"

export const LivelyFade = ({ children, delay, bottom }) => {
  return (
    <Fade distance={ANIMATION_DISTANCE} delay={delay} bottom={bottom}>
      {children}
    </Fade>
  )
}

LivelyFade.propTypes = {
  animationDelay: PropTypes.number,
  bottom: PropTypes.bool,
}
