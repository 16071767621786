import React, { useState } from "react"
import styled from "styled-components"
import { Choose } from "./Choose"
import PropTypes from "prop-types"
import { DeleteButton } from "../delete-button/DeleteButton"

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.lighterGrey};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 280px;
  position: relative;
`

const SelectedImageWrapper = styled.div`
  img {
    width: 100%;
    display: inherit; /* Fixes strange bottom spacing issue*/
  }
`
export const ImageUploader = ({ imageUrl, onImageChange, onaddfilestart }) => {
  const [pickImage, setPickImage] = useState(false)

  const deleteImage = () => {
    setPickImage(false)

    if (imageUrl) {
      onImageChange(undefined)
    }
  }

  // In order to prevent state issues caused by unmounting
  // the Filepond plugin, we are simply showing or hiding
  // the screens with CSS, rather than ripping them out of
  // the DOM altogether
  // const triggerStyle = {
  //   display: !imageUrl && !pickImage ? "block" : "none"
  // }

  const chooserStyle = {
    display: !imageUrl && !pickImage ? "block" : "none",
  }

  return (
    <Wrapper>
      {imageUrl && (
        <SelectedImageWrapper>
          <img src={imageUrl} alt="uploaded file" />
        </SelectedImageWrapper>
      )}

      {/* <Trigger style={triggerStyle} onClick={() => setPickImage(true)} /> */}
      <Choose
        onaddfilestart={onaddfilestart}
        style={chooserStyle}
        onImageChange={onImageChange}
      />
      {(imageUrl || pickImage) && <DeleteButton onClick={deleteImage} />}
    </Wrapper>
  )
}

ImageUploader.propTypes = {
  imageUrl: PropTypes.string,
  onImageChange: PropTypes.func.isRequired,
}
