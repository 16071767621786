import React, { useState } from "react"
import { Edit } from "./Edit"
import { Create } from "./Create"
import { View } from "./View"

export const MomentView = ({
  moment,
  sendKeyMoment,
  deleteKeyMoment,
  editKeyMoment,
  eventId,
  disabled,
}) => {
  const [isEditing, setIsEditing] = useState()
  const [isDeleting, setIsDeleting] = useState()

  if (!moment.id) {
    // No ID? Then it's a blank editable moment
    return (
      <Create
        disabled={disabled}
        sendKeyMoment={sendKeyMoment}
        eventId={eventId}
      />
    )
  }

  if (isEditing) {
    return (
      <Edit
        moment={moment}
        editKeyMoment={editKeyMoment}
        eventId={eventId}
        onEditComplete={() => setIsEditing(false)}
      />
    )
  }

  return (
    <View
      moment={moment}
      onEdit={() => setIsEditing(true)}
      isDeleting={isDeleting}
      onDelete={async () => {
        setIsDeleting(true)
        await deleteKeyMoment(moment.id)
        setIsDeleting(false)
      }}
    />
  )
}
