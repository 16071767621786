import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { LivelyFade } from "../../lively-fade/LivelyFade"

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.red};
  font-size: 0.8em;
  font-weight: 500;
  max-height: 0;
  overflow: hidden;
  opacity: 0;

  transition: margin-top 200ms ease-in-out, max-height 200ms ease-in-out,
    opacity 200ms ease-in-out;

  ${(props) =>
    props.show &&
    css`
      margin-top: 1.2em;
      max-height: 50px;
      opacity: 1;
    `}
`

const FormFieldLabel = styled.label`
  font-weight: 500;
  color: ${(props) =>
    props.light
      ? props.theme.colors.formLabelLight
      : props.theme.colors.greyText};
  font-size: 0.9em;
  display: block;
  position: relative;
`

const FormFieldLabelRequired = styled.span`
  position: absolute;
  right: 0;
  color: ${(props) => props.theme.colors.grey};
  font-weight: 300;
`

const Wrapper = styled.div`
  margin-bottom: 0.3em;
`

//This needs to wrap around input elements as there are z-index issues on child elements with animation-fill-mode: both default of react reveal
const AnimationFillFix = styled.div`
  > div {
    animation-fill-mode: backwards !important;
  }
`

export const FormField = ({
  children,
  name,
  label,
  touched,
  errorMessage,
  animationDelay,
  light,
  required,
}) => {
  const hasError = touched && errorMessage
  return (
    <Wrapper>
      <LivelyFade delay={animationDelay}>
        <FormFieldLabel light={light} htmlFor={name}>
          {label}
          {required && (
            <FormFieldLabelRequired>Required</FormFieldLabelRequired>
          )}
        </FormFieldLabel>
      </LivelyFade>
      <AnimationFillFix>
        <LivelyFade bottom delay={animationDelay}>
          <>
            {children}
            <ErrorMessage show={hasError}>
              {touched && errorMessage}
            </ErrorMessage>
          </>
        </LivelyFade>
      </AnimationFillFix>
    </Wrapper>
  )
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  touched: PropTypes.bool,
  errorMessage: PropTypes.string,
  animationDelay: PropTypes.number,
  light: PropTypes.bool, // If is on dark background
}
