import React from "react"
import { Wrapper, Selectfield } from "./styles"

class FilterSelect extends React.Component {
  render() {
    const { options, handleChange, value } = this.props
    return (
      <Wrapper>
        <Selectfield value={value} onChange={handleChange}>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))}
        </Selectfield>
      </Wrapper>
    )
  }
}

export default FilterSelect
