import React from "react"
import { ReactComponent as EditIcon } from "../../static/edit.svg"
import { ReactComponent as BinIcon } from "../../static/bin.svg"
import styled from "styled-components"
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner"
import { actionsWidth } from "."

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.white};

  svg {
    fill: ${(props) => props.theme.colors.white};
  }
`
const CopyWrap = styled.div`
  flex: 1 auto;
  margin-right: 2em;
  border-bottom: 1px solid ${(props) => props.theme.colors.midBlue};
  padding-bottom: 1em;
  padding-top: 0.4em;
`

const SpinnerWrap = styled.div`
  display: flex;
  justify-content: center;
  width: ${actionsWidth}px;
  height: 40px;
  align-items: center;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${actionsWidth}px;

  button {
    padding: 1em;
    &:last-child {
      margin-right: -0.7em;
    }
  }
`
export const View = ({ moment, onEdit, onDelete, isDeleting }) => {
  return (
    <Wrapper>
      <CopyWrap>{moment.copy}</CopyWrap>
      {isDeleting && (
        <SpinnerWrap>
          <LoadingSpinner size="small" />
        </SpinnerWrap>
      )}
      {!isDeleting && (
        <Actions>
          <>
            <button onClick={onEdit}>
              <EditIcon />
            </button>
            <button onClick={onDelete}>
              <BinIcon />
            </button>
          </>
        </Actions>
      )}
    </Wrapper>
  )
}
