import React from "react"
// import { ReactComponent as AllIcon } from "../../static/sidebar-icons/all.svg"
// import { ReactComponent as CardsIcon } from "../../static/sidebar-icons/cards.svg"
import { ReactComponent as PhotosIcon } from "../../static/sidebar-icons/image-search.svg"
import { ReactComponent as TwitterIcon } from "../../static/sidebar-icons/twitter.svg"
import { ReactComponent as TempusIcon } from "../../static/sidebar-icons/tempus.svg"
import { MenuItem } from "../../layouts/containers/container-with-sidebar/styles"

export const ALL = "all"
export const CARDS = "cards"
export const PHOTOS = "photos"
export const TWITTER = "twitter"
export const TEMPUS = "tempus"

const SideBarMenu = ({
  activeSection,
  showSection,
  enableImageSearch,
  enablePlayingSurface,
  enableVisualCommentaryCards,
  enableTempusEx,
}) => {
  const sections = []

  if (enableImageSearch) {
    sections.push({ Icon: PhotosIcon, id: PHOTOS })
  }

  if (enableTempusEx) {
    sections.push({ Icon: TempusIcon, id: TEMPUS })
  }

  sections.push({ Icon: TwitterIcon, id: TWITTER })

  const toggleSection = (section) => {
    if (section === activeSection) {
      showSection(undefined)
    } else {
      showSection(section)
    }
  }

  return sections.map((item, i) => (
    <MenuItem
      onClick={() => toggleSection(item.id)}
      active={activeSection === item.id}
      key={i}
    >
      <item.Icon />
    </MenuItem>
  ))
}

export default SideBarMenu
