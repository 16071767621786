export const updateType = {
  link: "link",
  video: "video",
  picture: "picture",
  text: "text",
}

export const determineUpdateType = (update) => {
  if (update.linkEmbed || update.linkEmbedUrl) {
    return updateType.link
  } else if (update.videoEmbed || update.videoEmbedUrl) {
    return updateType.video
  } else if (update.imageUrl) {
    return updateType.picture
  } else {
    return updateType.text
  }
}

const extractCommonFields = (editableUpdate) => {
  return {
    id: editableUpdate.id,
    title: editableUpdate.title,
    content: editableUpdate.content,
    icon: editableUpdate.icon,
    matchTime: editableUpdate.matchTime,
  }
}

export const cleanForLinkUpdate = (editableUpdate) => {
  return editableUpdate
    ? {
        ...extractCommonFields(editableUpdate),
        linkEmbedUrl: editableUpdate.linkEmbedUrl,
      }
    : undefined
}

export const cleanForVideoUpdate = (editableUpdate) => {
  return editableUpdate
    ? {
        ...extractCommonFields(editableUpdate),
        videoEmbedUrl: editableUpdate.videoEmbedUrl,
      }
    : undefined
}
