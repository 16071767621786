import React from "react"
import { EventLiveStatus } from "../../utils/time"
import styled from "styled-components"

const getColor = (props) => {
  const { colors } = props.theme
  switch (props.liveStatus) {
    case EventLiveStatus.Live:
      return colors.lightBlue
    case EventLiveStatus.HalfTime:
      return colors.red
    default:
      return colors.midBlue
  }
}

export const Time = styled.span`
  border-radius: 3px;
  background: ${(props) => getColor(props)};
  text-transform: uppercase;
  color: #fff;
  padding: 5px 1em;
  font-size: 0.8em;
  display: inline-block;
`

export const MatchTime = ({
  liveStatus,
  liveTime,
  liveLabel,
  realTimeEventData,
}) => {
  const matchTime = realTimeEventData
    ? realTimeEventData.data.liveTime
    : liveTime
  const label = realTimeEventData ? realTimeEventData.data.liveLabel : liveLabel

  return (
    <Time>
      {label && <span>{label} :</span>}
      {matchTime && <strong> {matchTime}</strong>}
    </Time>
  )
}
