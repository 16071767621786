import React from "react"
import { string } from "prop-types"
import { Helmet } from "react-helmet"

const Head = (props) => (
  <Helmet>
    {/* <Meta { ...props } /> */}
    <link rel="icon" sizes="192x192" href="/static/touch-icon.png" />
    <link rel="apple-touch-icon" href="/static/touch-icon.png" />
    <link rel="mask-icon" href="/static/favicon-mask.svg" color="#49B882" />
    <link rel="icon" href="/static/favicon.ico" />
  </Helmet>
)

Head.propTypes = {
  title: string,
  description: string,
  url: string,
  ogImage: string,
}

export default Head
