export const actionsWidth = 70
export const augmentMomentsArray = (moments, max) => {
  const momentsToAdd = max - moments.length
  const newMoments = [...moments]

  if (momentsToAdd > 0) {
    for (let i = 0; i < momentsToAdd; i++) {
      newMoments.push({ id: undefined, copy: "" })
    }
  }

  return newMoments
}
