import * as Ably from "ably"

export class EventRealTimeDataClient {
  connect = async (key) => {
    return new Promise((resolve, reject) => {
      if (this.connectionKey) {
        return
      }

      console.log("connecting to Ably client")
      this.connectionKey = key
      this.client = Ably.Realtime(key)
      this.client.connection.on("connected", () => {
        console.log("Client is now connected")
        resolve()
      })
      this.client.connection.on("failed", () => {
        console.error("Client failed to connect")
        reject()
      })
    })
  }

  close = async () => {
    return new Promise((resolve, reject) => {
      this.client.connection.close()
      this.connectionKey = undefined
      this.client.connection.on("closed", resolve)
      this.client.connection.on("failed", reject)
    })
  }

  /**
   * Subscribes to an event. It can be called multiple times
   * without reconnecting to an existing event that is
   * already connected
   */
  subscribeToEvent = async (clientChannelKey, subscriptionName, handler) => {
    if (!subscriptionName) {
      throw Error("subscriptionName is required")
    }
    if (this.eventChannelKey === clientChannelKey) {
      // Don't resubscribe to the same channerl
      return
    }

    await this.detachAnyExistingEventChannel()
    await this.attachNewEventChannel(clientChannelKey)

    console.log("Subscribing to event", subscriptionName)
    this.eventChannel.subscribe(subscriptionName, handler)
  }

  /**
   * Attach a new event channel
   */
  attachNewEventChannel = async (channelKey) => {
    return new Promise((resolve, reject) => {
      if (this.channelKey || this.channel) {
        throw Error("There is already an active event channel in use")
      }

      this.eventChannelKey = channelKey
      this.eventChannel = this.client.channels.get(channelKey)

      this.eventChannel.on("attached", () => {
        console.log(`Event channel is now attached`)
        this.eventChannel.off("attached")
        resolve()
      })
      this.eventChannel.on("failed", (err) => {
        console.error(`Event channel could not attach:`, err)
        this.eventChannel.off("failed")
        reject()
      })

      console.log("Event channel is attaching")
      this.eventChannel.attach()
    })
  }

  /**
   * Detach the existing event channel.
   * If event channel is not already connected, will resolve immediately.
   */
  detachAnyExistingEventChannel = async () => {
    return new Promise((resolve, reject) => {
      if (!this.eventChannel) {
        resolve()
      }

      this.eventChannel.on("detached", () => {
        console.log("Event channel is now detached")
        this.eventChannel.off("detached")
        this.eventChannel = undefined
        this.eventChannelKey = undefined
        resolve()
      })
      this.eventChannel.on("failed", (err) => {
        console.error(`Event channel could not detach:`, err)
        this.eventChannel.off("failed")
        reject()
      })

      console.log("Event channel is detaching")

      this.eventChannel.unsubscribe()
      this.eventChannel.detach()
    })
  }
}
