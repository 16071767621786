import { useState, useEffect, useReducer } from "react"
import { API } from "../api"

const eventsReducer = (state, action) => {
  switch (action.type) {
    case "INIT_REDUCER":
      return {
        ...state,
        hasUpcomingEvents: false,
        hasPastEvents: false,
        isLoading: true,
        isError: false,
      }

    case "SET_SEASON":
      return {
        ...state,
        season: action.season,
      }
    case "FETCH_UPCOMING_EVENTS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        hasUpcomingEvents: true,
        hasErrored: false,
        errorMessage: "",
        data: { ...state.data, upcomingEvents: action.payload },
      }
    case "FETCH_PAST_EVENTS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        hasErrored: false,
        hasPastEvents: true,
        errorMessage: "",
        data: { ...state.data, pastEvents: action.payload },
      }
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
        errorMessage: action.error,
      }

    case "FETCH_CLIENT_REQUEST": {
      return {
        ...state,
        clientLoading: true,
      }
    }
    case "FETCH_CLIENT_SUCCESS": {
      return {
        ...state,
        clientLoading: false,
        client: action.payload,
      }
    }

    case "FETCH_CLIENT_FAILURE": {
      return {
        ...state,
        clientLoading: false,
      }
    }

    case "GET_SEASON_LIST_REQUEST": {
      return {
        ...state,
        seasonListLoading: true,
      }
    }
    case "GET_SEASON_LIST_SUCCESS": {
      return {
        ...state,
        seasonListLoading: false,
        seasonList: action.payload,
      }
    }

    case "GET_SEASON_LIST_FAILURE": {
      return {
        ...state,
        seasonListLoading: false,
      }
    }

    default:
      return state
  }
}

const useEventsFetch = (initialUrl, season) => {
  const [url] = useState(initialUrl)
  const initialData = {
    upcomingEvents: [],
    pastEvents: [],
  }

  const [state, dispatch] = useReducer(eventsReducer, {
    season: season,

    seasonListLoading: false,
    seasonList: [],

    clientLoading: false,
    client: undefined,

    isLoading: false,
    hasErrored: false,
    errorMessage: "",
    data: initialData,
  })

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      dispatch({ type: "INIT_REDUCER" })
      await getClient()
      await getPastEvents(10, state.season, didCancel)
      await getUpcomingEvents(3, didCancel)
      await getSeasonList()
    }

    fetchData()

    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  // Had to duplicate this from the editor reducer. Would be worth creating
  // a root level reducer in an ancestor component for shared state like this.
  const getClient = async (didCancel) => {
    try {
      let result = await API.getClient()
      if (!didCancel) {
        dispatch({ type: "FETCH_CLIENT_SUCCESS", payload: result.data })
      }
    } catch (err) {
      if (!didCancel) {
        dispatch({ type: "FETCH_CLIENT_FAILURE", error: err })
      }
    }
  }

  const getPastEvents = async (number, seasonFilter, didCancel) => {
    try {
      let result = await API.getPastEvents({
        pageSize: number,
        seasonID: seasonFilter,
      })
      if (!didCancel) {
        dispatch({ type: "FETCH_PAST_EVENTS_SUCCESS", payload: result.data })
      }
    } catch (err) {
      if (!didCancel) {
        dispatch({ type: "FETCH_FAILURE", error: err })
      }
    }
  }

  const getUpcomingEvents = async (number, didCancel) => {
    try {
      let result = await API.getUpcomingEvents({ count: number })
      if (!didCancel) {
        dispatch({
          type: "FETCH_UPCOMING_EVENTS_SUCCESS",
          payload: result.data,
        })
      }
    } catch (err) {
      if (!didCancel) {
        dispatch({ type: "FETCH_FAILURE", error: err })
      }
    }
  }

  const setSeason = async (season) => {
    dispatch({ type: "SET_SEASON", season: season })

    await getPastEvents(10, season)
  }

  const getSeasonList = async () => {
    try {
      dispatch({ type: "GET_SEASON_LIST_REQUEST" })
      let result = await API.getSeasonList()
      dispatch({ type: "GET_SEASON_LIST_SUCCESS", payload: result.data })
    } catch (err) {
      dispatch({ type: "GET_SEASON_LIST_FAILURE", error: err })
    }
  }
  return { ...state, getPastEvents, getUpcomingEvents, setSeason }
}

export default useEventsFetch
