import styled from "styled-components"

export const Header = styled.h3`
  margin-bottom: 20px;
`

export const ListContainer = styled.section`
  width: 100%;
  margin-bottom: 50px;
`

export const ListItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  a:hover {
    text-decoration: none;
  }
`

export const Item = styled.li`
  width: 100%;
`

export const One = styled.span`
  flex: 4 0 0;
`

export const Two = styled.span`
  flex: 1 0 0;
  font-size: 14px;
  line-height: 16px;
`

export const Three = styled.span`
  flex: 1 0 0;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
`
