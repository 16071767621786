import styled from "styled-components"
import { WHITE } from "../../styles/core/colours"

export const EventInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${(props) => (props.editEventIsActive ? WHITE : "inherit")};
`

export const Left = styled.div`
  flex: 3;
  white-space: nowrap;
  padding-right: 20px;
  flex-grow: 0;
  padding-left: 0.5em;
`

export const Right = styled.div`
  flex: 1;
  padding-left: 20px;
  border-left: 1px solid #ccc;
`

export const TeamName = styled.div`
  text-transform: none;
  letter-spacing: normal;
  font-weight: 500;
  padding-top: 0;
  font-size: 14px;
  color: ${(props) => props.theme.colors.greyText};
  padding-bottom: 2px;
`

export const Date = styled.div`
  text-transform: none;
  font-size: 12px;
  letter-spacing: normal;
  font-weight: normal;
  color: ${(props) => props.theme.colors.greyText};
`
