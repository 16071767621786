/*
  Select stying taken from wtf forms : http://wtfforms.com
 */

import styled, { css } from "styled-components"
import {
  DARK_BLUE,
  LIGHTER_GREY,
  LIGHT_BLUE,
} from "../../../styles/core/colours"
import {
  getInputBorderBottom,
  getFocusBorderBottom,
} from "../../../styles/core/forms"

export const Wrapper = styled.div`
  position: relative;
  color: ${DARK_BLUE};
  z-index: 1;
  &:after {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    margin-top: -0.15rem;
    pointer-events: none;
    border-top: 0.35rem solid #d2cfcf;
    border-right: 0.35rem solid transparent;
    border-bottom: 0.35rem solid transparent;
    border-left: 0.35rem solid transparent;
  }
  @media screen and (min-width: 0\0) {
    &:after {
      z-index: 5;
    }
    &:before {
      position: absolute;
      top: 0;
      right: 1rem;
      bottom: 0;
      z-index: 2;
      content: "";
      display: block;
      width: 1.5rem;
      background-color: ${LIGHTER_GREY};
    }
  }

  ${(props) =>
    props.light &&
    css`
      /* Overrides for the light style */
    `}
`

export const Selectfield = styled.select`
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 10px 5px 16px 0;
  line-height: 1.5;
  color: ${DARK_BLUE};
  background-color: ${LIGHTER_GREY};
  border: 0;
  border-radius: 0;
  font-weight: 300;
  font-size: 1em;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: ${(props) => (props.fluid ? "100%" : "inherit")};
  ${(props) => getInputBorderBottom(props)}

  &:focus:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  :focus {
    outline: none;
    ${(props) => getFocusBorderBottom(props)}/* padding-bottom: 15px; */
  }
  &:active {
    color: #fff;
    background-color: ${LIGHT_BLUE};
  }
  &::-ms-expand {
    display: none;
  }
  @media screen and (min-width: 0\0) {
    z-index: 1;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    &:hover,
    &:focus,
    &:active {
      color: ${DARK_BLUE};
      background-color: ${LIGHTER_GREY};
    }
  }

  /* Overrides for the light style */

  ${(props) =>
    props.light &&
    css`
      background-color: transparent;
      color: white;
      &:focus {
        box-shadow: none;
      }
    `}
`
