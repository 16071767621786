import React, { useState } from "react"
import { ReactComponent as Plus } from "../../static/icons/plus.svg"
import styled from "styled-components"
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner"

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.white};
  width: 44px;
  height: 44px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ImageSearchResult = ({ src, onSelectImage }) => {
  const [selecting, setSelecting] = useState()
  return (
    <div
      style={{
        backgroundImage: `url(${src})`,
        height: "100%",
        backgroundSize: "cover",
        position: "relative",
        opacity: selecting ? 0.8 : 1,
      }}
    >
      <Button
        disabled={selecting}
        onClick={async () => {
          setSelecting(true)
          await onSelectImage()
          setSelecting(false)
        }}
      >
        {selecting ? <LoadingSpinner dark size="small" /> : <Plus />}
      </Button>
    </div>
  )
}
