import React, { useState } from "react"
import { SearchInput } from "../form-components/search-input/SearchInput"
import { LoadingOverlay } from "../loading-overlay/LoadingOverlay"
import { TwitterCard } from "./TwitterCard"
import { TwitterCardControls } from "./TwitterCardControls"
import PropTypes from "prop-types"
import { SidebarRefresh } from "../sidebar-refresh/SidebarRefresh"
import { SidebarMessage } from "../sidebar/SidebarMessage"

export const Component = ({
  isSearching,
  searchResults,
  searchTweets,
  instantPost,
  editPost,
  selectedTwitterAssetUrl,
  doClearTwitterAssetUrl,
}) => {
  const [value, setValue] = useState()

  const isValid = value && value.length > 2

  const handleSearch = async () => {
    await searchTweets(value)
  }
  return (
    <div style={{ position: "relative" }}>
      <SearchInput
        value={value}
        isValid={isValid}
        onChange={(value) => setValue(value)}
        onExecuteSearch={handleSearch}
        label="Search by hashtag or username"
      />
      <div>
        {searchResults.map((tweet, i) => {
          const selected = selectedTwitterAssetUrl === tweet.url

          return (
            <TwitterCardControls
              editPost={editPost}
              instantPost={instantPost}
              url={tweet.url}
              key={i}
              selected={selected}
              doClearTwitterAssetUrl={doClearTwitterAssetUrl}
            >
              <TwitterCard
                name={tweet.user.name}
                screenName={tweet.user.screenName}
                profileImage={tweet.user.profileImageUrl}
                favouriteCount={tweet.favoriteCount}
                created={tweet.createdAt}
                text={tweet.text}
                url={tweet.url}
                selected={selected}
              />
            </TwitterCardControls>
          )
        })}
      </div>
      {isSearching && <LoadingOverlay />}
      {searchResults && searchResults.length > 0 && (
        <SidebarRefresh isActive={isSearching} onClick={handleSearch} />
      )}
      {searchResults && searchResults.length === 0 && (
        <SidebarMessage
          title="Nothing to see here."
          text="Try searching or refreshing the feed."
        />
      )}
    </div>
  )
}

Component.propTypes = {
  searchResults: PropTypes.array.isRequired,
  selectedTwitterAssetUrl: PropTypes.string,
  doClearTwitterAssetUrl: PropTypes.func.isRequired,
}

export const SidebarTwitterSearch = Component
