import React, { Fragment } from "react"
import { withRouter } from "react-router-dom"
import { Container, Row, Col } from "react-grid-system"
import MaxWidthContainer from "../layouts/containers/max-width-container"
import { ReactComponent as ProfileIcon } from "../static/profile.svg"

import CreateEventForm from "../components/create-event-form/create-event-form"

import DefaultLayout from "../layouts/index"
import GeneralContainer from "../layouts/containers/container"

import { DARK_BLUE } from "../styles/core/colours"

import TopNavigation from "../components/top-navigation/top-navigation"
import { Heading } from "../components/heading/heading"

import { AccountIcons } from "../styles/pages/editor"
import { Wrapper } from "../styles/pages/create-event"
import useCreateEvent from "./createEventLogic"
import auth from "../Auth"

const CreateEvent = (props) => {
  const {
    isSubmitting,
    currentEvent,
    createEvent,
    seasonList,
    errorMessage,
    client,
  } = useCreateEvent(props.history)

  const renderTopNavigation = () => {
    return (
      <TopNavigation
        left={<Fragment />}
        right={
          <AccountIcons style={{ marginRight: "2em" }}>
            <button onClick={() => auth.signOut()}>
              <ProfileIcon />
            </button>
          </AccountIcons>
        }
        condensed
      />
    )
  }

  return (
    <Fragment>
      <DefaultLayout backgroundColour={DARK_BLUE}>
        <div style={{ paddingLeft: "1em" }}>
          {renderTopNavigation({ ...currentEvent })}
        </div>
        <GeneralContainer colour={DARK_BLUE}>
          <MaxWidthContainer>
            <Wrapper>
              <Container fluid style={{ margin: "0 20px" }}>
                <Row>
                  <Col>
                    <Heading tag="h1">Create new event</Heading>
                  </Col>
                </Row>
              </Container>
            </Wrapper>

            <CreateEventForm
              onSubmit={(values) => createEvent(values)}
              isSubmitting={isSubmitting}
              seasonList={seasonList}
              errorMessage={errorMessage}
              isSimpleGenericForm={client.clientType === "Generic"}
              enableTempusId={client.enableTempusEx}
              languages={client.languages}
            />
          </MaxWidthContainer>
        </GeneralContainer>
      </DefaultLayout>
    </Fragment>
  )
}

export default withRouter(CreateEvent)