import React from "react"
import styled from "styled-components"
import { PHOTOS, TWITTER, TEMPUS } from "../sidebar-menu/SideBarMenu"
import ImageSearch from "../image-search/image-search"
import PropTypes from "prop-types"
import { SidebarTwitter } from "../sidebar-twitter/SidebarTwitter"
import { SidebarTempus } from "../sidebar-tempus/SidebarTempus"

export const Container = styled.div`
  width: 280px;
  padding: 20px;
  height: 100%;
`

const Sidebar = ({
  activeSection,
  images,
  getImages,
  onImageUpload,
  setEditableUpdate,
  setImageAssetId,
  clearImageAssetId,
  setTwitterAssetUrl,
  clearTwitterAssetUrl,
  selectedImageAssetId,
  clearEditableUpdateImage,
  setTwitterSidebarPanel,
  tweets,
  searchTweets,
  onSidebarInstantTweet,
  getHandleTweets,
  opponentTwitterHandle,
  officialTwitterHandle,
  editEvent,
  eventId,
  selectedTwitterAssetUrl,
  showTempusImagePicker,
  getTempusCards,
  tempusExId,
  tempusExApiKey,
  tempusExApiBaseUrl,
}) => {
  return (
    <Container>
      {/* {activeSection === ALL && <div>All</div>} */}
      {activeSection === PHOTOS && (
        <ImageSearch
          images={images}
          getImages={getImages} // check how this async works
          onImageUpload={onImageUpload}
          setEditableUpdate={setEditableUpdate}
          selectedImageAssetId={selectedImageAssetId}
          setImageAssetId={setImageAssetId}
          clearImageAssetId={clearImageAssetId}
          clearEditableUpdateImage={clearEditableUpdateImage}
        />
      )}
      {/* {activeSection === CARDS && <div>Cards</div>} */}
      {activeSection === TWITTER && (
        <SidebarTwitter
          setTwitterSidebarPanel={setTwitterSidebarPanel}
          tweets={tweets}
          searchTweets={searchTweets}
          setEditableUpdate={setEditableUpdate}
          onSidebarInstantTweet={onSidebarInstantTweet}
          getHandleTweets={getHandleTweets}
          editEvent={editEvent}
          eventId={eventId}
          opponentHandle={opponentTwitterHandle}
          officialHandle={officialTwitterHandle}
          setTwitterAssetUrl={setTwitterAssetUrl}
          clearTwitterAssetUrl={clearTwitterAssetUrl}
          selectedTwitterAssetUrl={selectedTwitterAssetUrl}
        />
      )}
      {activeSection === TEMPUS && (
        <SidebarTempus
          eventId={eventId}
          matchId={tempusExId}
          tempusExApiKey={tempusExApiKey}
          tempusExApiBaseUrl={tempusExApiBaseUrl}
          showTempusImagePicker={showTempusImagePicker}
          getTempusCards={getTempusCards}
        />
      )}
    </Container>
  )
}

Sidebar.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
  getImages: PropTypes.func.isRequired,
  activeSection: PropTypes.string,
  images: PropTypes.object.isRequired,
  onSidebarInstantTweet: PropTypes.func.isRequired,
  opponentTwitterHandle: PropTypes.string,
  editEvent: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  officialTwitterHandle: PropTypes.string,
  selectedTwitterAssetUrl: PropTypes.string,
}

export default Sidebar
