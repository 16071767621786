import styled from "styled-components"
import {
  GREY,
  MID_GREY,
  LIGHT_GREY,
  WHITE,
  LIGHT_BLUE_ACTIVE,
} from "../../../styles/core/colours"

const HEADER_HEIGHT = 95

export const Sidebar = styled.section`
  background-color: ${GREY};
  left: 0;
  display: flex;
  z-index: 1;
  position: fixed;
`

export const SidebarContentContainer = styled.div`
  position: absolute;
  left: 50px;
  height: 100%;
  overflow-y: scroll;
`

export const SideBarMenuContainer = styled.div`
  width: 50px;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
  padding-top: 15px;
`

export const InnerContainer = styled.div`
  position: relative;
  margin-top: 70px;
  overflow-x: hidden;
`

export const Content = styled.section`
  position: relative;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  background: ${WHITE};
  right: 0;
  left: 50px;
  background: #fff;
  transition: all 500ms cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  width: calc(100% - 50px);

  ${({ active }) =>
    active &&
    `
    left: 350px;
    width: calc(100% - 350px);
    -webkit-box-shadow: -7px 5px 30px -10px rgba(0,0,0,0.54);
    -moz-box-shadow: -7px 5px 30px -10px rgba(0,0,0,0.54);
    box-shadow: -7px 5px 30px -10px rgba(0,0,0,0.54);
  `};
`
export const MenuItem = styled.button`
  height: 50px;
  width: 50px;
  padding: 0;
  border: 0;
  background: ${GREY};
  color: ${MID_GREY};
  position: relative;

  transition: all 500ms linear;

  &:hover {
    background: ${WHITE};
  }

  &:last-child {
    margin-top: 16px;
    &:before {
      content: " ";
      background: ${LIGHT_GREY};
      height: 1px;
      width: 30px;
      position: absolute;
      left: 10px;
      top: -10px;
    }
  }

  ${({ active }) =>
    active &&
    `
      background: ${LIGHT_BLUE_ACTIVE} !important;
      
      svg {
          text,
          path {
            fill: ${WHITE} !important;
          }
       }
   `}

  svg {
    text,
    path {
      fill: currentColor;
    }
  }
`
