import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const IconWrap = styled.div`
  border-radius: 100%;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 25px;
    height: 25px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  cursor: pointer;
  text-align: center;

  :hover {
    svg g {
      fill: ${(props) => props.theme.colors.lightBlue};
    }
  }
`
const Label = styled.div`
  font-size: 0.8em;
  margin-top: 0.5em;
  padding: 1em 2em;
`
export const Option = ({ title, icon, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <IconWrap>{icon}</IconWrap> <Label>{title}</Label>
    </Wrapper>
  )
}

Option.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
}
