import React from "react"
import FeedItem from "../feed-item/feed-item"
import { UpdateFeedContainer } from "./styles"
import { EmptyState } from "./empty-state"
import { LoadingOverlay } from "../loading-overlay/LoadingOverlay"

class UpdateFeed extends React.Component {
  constructor() {
    super()
    this.state = {
      deleteId: undefined,
      deleting: false,
    }
  }

  render() {
    const {
      updates,
      deleteUpdate,
      inEditMode,
      editableUpdate,
      startUpdateEdit,
      cancelUpdateEdit,
      clientEventTypes,
    } = this.props
    return (
      <>
        <UpdateFeedContainer>
          {updates.isFetching && <LoadingOverlay />}
          {!updates.isFetching && updates.data && updates.data.length === 0 && (
            <EmptyState
              title="NO COMMENTS YET!"
              content="They will appear here as you add them in the editor above."
            />
          )}
          {updates.data.map((update, i) => (
            <FeedItem
              key={i}
              deleteUpdate={deleteUpdate}
              inEditMode={inEditMode}
              editableUpdate={editableUpdate}
              startUpdateEdit={startUpdateEdit}
              cancelUpdateEdit={cancelUpdateEdit}
              clientEventTypes={clientEventTypes}
              {...update}
            />
          ))}
        </UpdateFeedContainer>
      </>
    )
  }
}

export default UpdateFeed
