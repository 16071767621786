import React from "react"
import styled from "styled-components"
import RichTextEditor from "react-rte"
import {
  getInputBorderBottom,
  getFocusBorderBottom,
  getPlaceholderColor,
} from "../../../styles/core/forms"

const TOOLBAR_CONFIG = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: null,
  BLOCK_TYPE_BUTTONS: null,
  extraProps: {
    tabIndex: "-1",
  },
}

const Wrapper = styled.div`
  .rte-toolbar {
    position: absolute;
    top: -5px;
    right: -10px;
    opacity: 0;
    border: none;
    display: block;
    transform: translateY(50px);
    transition: all 300ms ease-in-out;
    z-index: 10;

    button {
      opacity: 0.3;
      transition: opacity 300ms ease-in-out;
      border: none;
      &:hover,
      &:focus {
        opacity: 1;
        border-bottom: 3px solid ${(props) => props.theme.colors.lightGrey};
      }
      &[class*="isActive"] {
        opacity: 1;
        background: transparent;
        border-bottom: 3px solid ${(props) => props.theme.colors.lightBlue};
      }
    }
  }

  .rte-editor {
  }

  //existing react-rte classes. A bit risky to target these but don;t really have a choice.
  [class*="RichTextEditor__root"] {
    border: none !important;
  }

  .DraftEditor-root {
    border: none;
  }

  .DraftEditor-editorContainer {
    border: none;
  }

  .public-DraftEditor-content {
    min-height: 100px;
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    background: none;
    border: 0;
    border-radius: 0;
    border: none;
    ${(props) => getInputBorderBottom(props)};
    padding: 20px 5px 20px 0 !important;
    width: 100%;
    font-weight: 300;
    display: block;
    resize: vertical;
    transition: all 300ms ease-in-out;

    :focus {
      outline: none;
      margin-top: 20px;
      ${(props) => getFocusBorderBottom(props)}
    }
  }
  .public-DraftEditorPlaceholder-root {
    padding: 20px 5px 20px 0 !important;
  }
  .public-DraftEditorPlaceholder-inner {
    ${(props) => getPlaceholderColor(props)};
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 300;
  }

  &.is-focus {
    .rte-toolbar {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

class DraftEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isFocus: false,
      richValue: RichTextEditor.createValueFromString(props.value, "html"),
      htmlValue: props.value,
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value !== this.state.htmlValue) {
      this.setState({
        richValue: RichTextEditor.createValueFromString(newProps.value, "html"),
        htmlValue: newProps.value,
      })
    }
  }

  onChange = (richValue) => {
    this.setState({ richValue, htmlValue: richValue.toString("html") }, () => {
      this.props.onChange(this.state.htmlValue)
    })
  }

  onFocus = () => {
    this.setState({ isFocus: true })
    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }

  onBlur = () => {
    this.setState({ isFocus: false })
    if (this.props.onBlur) {
      this.props.onBlur()
    }
  }

  render() {
    const { isFocus, richValue } = this.state

    return (
      <Wrapper className={isFocus ? "is-focus" : ""}>
        <RichTextEditor
          placeholder="Comment"
          toolbarClassName="rte-toolbar"
          editorClassName="rte-editor"
          toolbarConfig={TOOLBAR_CONFIG}
          value={richValue}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
      </Wrapper>
    )
  }
}

export default DraftEditor
