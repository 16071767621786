import styled from "styled-components"
import { LIGHT_BLUE } from "../../styles/core/colours"

export const Container = styled.div`
  flex: 1 1 48%;
  display: flex;
  border: ${(props) =>
    props.selected === "n/a"
      ? "none"
      : props.selected === true
      ? `5px solid ${LIGHT_BLUE}`
      : "5px solid transparent"};
  cursor: pointer;
  transition: border 100ms ease-in;

  img {
    width: 100%;
    height: 100%;
  }
`
