import React from "react"
import Fade from "react-reveal/Fade"
import ListLink from "./listLink"

import {
  Header,
  ListContainer,
  ListItems,
  Item,
  One,
  Two,
  Three,
} from "./styles"

const renderListItem = ({
  borderColor,
  linkUrl,
  columnOne,
  columnTwo,
  columnThree,
  borderHighlightColor,
}) => (
  <Item key={columnThree}>
    <ListLink
      to={linkUrl}
      bordercolor={borderColor}
      borderhighlightcolor={borderHighlightColor}
    >
      <One>{columnOne}</One>
      <Two>{columnTwo}</Two>
      <Three>{columnThree}</Three>
    </ListLink>
  </Item>
)

const List = (props) => {
  const {
    title,
    items,
    inViewport,
    innerRef,
    borderColor,
    borderHighlightColor,
  } = props
  return (
    <ListContainer pose={inViewport ? "open" : "closed"} ref={innerRef}>
      <Fade delay={400}>
        <Header>{title}</Header>
      </Fade>
      <Fade bottom distance="10px">
        <div>
          {items.length ? (
            <ListItems>
              {items.map((item) =>
                renderListItem({ borderColor, borderHighlightColor, ...item })
              )}
            </ListItems>
          ) : (
            <p>No events to display</p>
          )}
        </div>
      </Fade>
    </ListContainer>
  )
}

export default List
