import styled from "styled-components"
import { DARK_BLUE, WHITE } from "../../styles/core/colours"

function getForeColour(colour) {
  switch (colour) {
    case WHITE:
      return DARK_BLUE
    default:
      return WHITE
  }
}

export default styled.section`
  padding: 50px 0;
  background-color: ${({ colour }) => colour};

  /* Having to continously remove items from here to prevent unecessary overriding.
  */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  input,
  li {
    color: ${({ colour }) => getForeColour(colour)};
  }
`
