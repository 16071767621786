import React from "react"

import {
  Content,
  Sidebar,
  SidebarContentContainer,
  SideBarMenuContainer,
  InnerContainer,
} from "./styles"

class ContainerWithSideBar extends React.Component {
  render() {
    const { top, sidebar, children, activeSection, menu } = this.props
    return (
      <main>
        {top}
        <InnerContainer>
          <Sidebar>
            <SideBarMenuContainer>{menu}</SideBarMenuContainer>
            <SidebarContentContainer>{sidebar}</SidebarContentContainer>
          </Sidebar>
          <Content active={activeSection}>
            <div style={{ overflowY: "scroll", width: "100%" }}>{children}</div>
          </Content>
        </InnerContainer>
      </main>
    )
  }
}

export default ContainerWithSideBar
