import React, { useState } from "react"
import Button from "../form-components/button/button"
import { Input } from "../form-components/input/Input"
import styled from "styled-components"
import { actionsWidth } from "."

const FieldWrap = styled.div`
  display: flex;
  align-items: center;
`
const InputWrap = styled.div`
  flex: 1 auto;
  margin-right: 2em;
`
const ButtonWrap = styled.div`
  width: ${actionsWidth}px;
`
export const Form = ({
  moment,
  onSubmit,
  loading,
  primaryButtonLabel,
  disabled,
}) => {
  const [copy, setCopy] = useState(moment.copy)
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        // Send back as an object, because this form
        // may get new fields later on
        onSubmit({ copy })
      }}
    >
      <FieldWrap>
        <InputWrap>
          <Input
            light
            placeholder="Key moment"
            value={copy}
            disabled={loading || disabled}
            onChange={(e) => setCopy(e.currentTarget.value)}
          />
        </InputWrap>
        <ButtonWrap>
          <Button
            buttonStyle="light"
            type="submit"
            loading={loading}
            disabled={loading || disabled}
            label={primaryButtonLabel}
          />
        </ButtonWrap>
      </FieldWrap>
    </form>
  )
}
