import React from "react"
import styled from "styled-components"

const ItemInner = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midBlue};
  height: 100%;
`
export const BlankItem = () => {
  return <ItemInner />
}
