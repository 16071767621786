// I've named this simial to the existing. Will clean this up when I go through to tidy everything at the end
import React from "react"
import { FormField } from "../form-field/FormField"
import PropTypes from "prop-types"
import styled from "styled-components"

const FieldStyle = styled.input`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  width: 100px;
  height: inherit;
  border: 0;
  padding: 14px 5px 14px 0;

  ::placeholder {
    color: ${(props) => props.theme.colors.black};
  }

  :focus {
    outline: none;

    ::placeholder {
      color: ${(props) => props.theme.colors.lightGrey};
    }
  }
`

/**
 * This InputField is a wrapper that connects a HTML input field into Formik
 */
export const MinuteField = ({
  form, // A formik prop
  field, // A formik prop
  label,
  input,
  animationDelay,
  light,
}) => {
  const errorMessage = form.errors[field.name]
  const touched = form.touched[field.name] === true || form.submitCount > 0
  return (
    <FormField
      name={field.name}
      label={label}
      touched={form.touched[field.name] === true || form.submitCount > 0}
      errorMessage={errorMessage}
      animationDelay={animationDelay}
      light={light}
    >
      <FieldStyle
        {...field}
        {...input}
        onFocus={() => {
          // if (field.value) {
          //   form.setFieldValue(field.name, field.value.replace(`'`, ""))
          // }
          if (input.onFocus) {
            input.onFocus()
          }
        }}
        onBlur={() => {
          // if (field.value) {
          //   form.setFieldValue(field.name, `${field.value.replace(`', ''`)}'`)
          // }

          if (input.onBlur) {
            input.onBlur()
          }
        }}
        disabled={form.isSubmitting}
        isError={touched && errorMessage !== undefined}
      />
    </FormField>
  )
}

MinuteField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  animationDelay: PropTypes.number,
  light: PropTypes.bool,
}
