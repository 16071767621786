import React, { useState } from "react"
import { Form } from "./Form"

export const Edit = ({ moment, editKeyMoment, eventId, onEditComplete }) => {
  const [loading, setLoading] = useState(false)
  return (
    <Form
      moment={moment}
      loading={loading}
      primaryButtonLabel="Add"
      onSubmit={async (values) => {
        setLoading(true)
        await editKeyMoment(moment.id, {
          ...values,
          eventId,
        })
        if (onEditComplete) {
          onEditComplete()
        }
        setLoading(false)
      }}
    />
  )
}
