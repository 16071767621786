import React from "react"
import styled from "styled-components"
import { ReactComponent as Back } from "../../static/icons/back.svg"

const Button = styled.button`
  display: flex;
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
  align-items: center;
  font-size: 0.8em;

  svg {
    height: 15px;
    width: 15px;
    margin-right: 0.5em;
    g {
      fill: ${(props) => props.theme.colors.coreBlue};
    }
  }
`

const NextSvgWrap = styled.div`
  svg {
    transform: rotate(180deg);
    margin-left: 0.5em;
    margin-right: 0;
  }
`

export const PrevButton = ({ onClick, text }) => {
  return (
    <Button onClick={onClick}>
      <Back />
      {text || "Previous"}
    </Button>
  )
}

export const NextButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      Next
      <NextSvgWrap>
        <Back />
      </NextSvgWrap>
    </Button>
  )
}

export const BackButton = ({ onClick }) => {
  return <PrevButton text="Back" onClick={onClick} />
}
