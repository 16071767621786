/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer } from "react"
import { API, extractValidationMessageFromApiError } from "../api"

const createEventReducer = (state, action) => {
  switch (action.type) {
    case "SUBMIT_FORM":
      return { ...state, isSubmitting: true }
    case "CREATE_EVENT_SUCCESS":
      return {
        ...state,
        isSubmitting: false,
        eventId: action.payload.id,
        isSucces: true,
      }
    case "CREATE_EVENT_FAILURE":
      return {
        ...state,
        isSubmitting: false,
        isSucces: false,
        errorMessage: action.error,
      }

    case "GET_SEASON_LIST_REQUEST": {
      return {
        ...state,
        seasonListLoading: true,
      }
    }
    case "GET_SEASON_LIST_SUCCESS": {
      return {
        ...state,
        seasonListLoading: false,
        seasonList: action.payload,
      }
    }

    case "GET_SEASON_LIST_FAILURE": {
      return {
        ...state,
        seasonListLoading: false,
      }
    }

    case "FETCH_CLIENT_SUCCESS": {
      return {
        ...state,
        clientLoading: false,
        client: action.payload,
      }
    }
    case "FETCH_CLIENT_FAILURE": {
      return {
        ...state,
        clientLoading: false,
      }
    }
    default:
      return state
  }
}

const useCreateEvent = (history) => {
  const initialState = {
    isSubmitting: false,
    isSuccess: false,
    currentEvent: {},
    errorMessage: "",
    seasonListLoading: false,
    seasonList: [],
    client: {},
  }

  const [state, dispatch] = useReducer(createEventReducer, initialState)
  let didCancel = false
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "INIT_REDUCER" })
      await getClient()
      await getSeasonList()
    }

    fetchData()
    return () => {
      didCancel = true // Make sure when the component is disposed we don't run any reducers
    }
  }, [])

  const createEvent = async (isCanceled, eventData) => {
    try {
      dispatch({ type: "SUBMIT_FORM" })
      let result = await API.createEvent(eventData)
      if (!isCanceled) {
        dispatch({ type: "CREATE_EVENT_SUCCESS", payload: result.data })
        history.replace("/editor/" + result.data.id)
      }
    } catch (err) {
      if (!isCanceled) {
        dispatch({
          type: "CREATE_EVENT_FAILURE",
          error: extractValidationMessageFromApiError(err),
        })
      }
    }
  }

  // Had to duplicate this from the editor reducer. Would be worth creating
  // a root level reducer in an ancestor component for shared state like this.
  const getClient = async (didCancel) => {
    try {
      let result = await API.getClient()
      if (!didCancel) {
        dispatch({ type: "FETCH_CLIENT_SUCCESS", payload: result.data })
      }
    } catch (err) {
      if (!didCancel) {
        dispatch({ type: "FETCH_CLIENT_FAILURE", error: err })
      }
    }
  }

  // Duplicating so that seasons are available on the create events page
  const getSeasonList = async () => {
    try {
      dispatch({ type: "GET_SEASON_LIST_REQUEST" })
      let result = await API.getSeasonList()
      dispatch({ type: "GET_SEASON_LIST_SUCCESS", payload: result.data })
    } catch (err) {
      dispatch({ type: "GET_SEASON_LIST_FAILURE", error: err })
    }
  }

  return {
    ...state,
    createEvent: (eventData) => createEvent(didCancel, eventData),
  }
}

export default useCreateEvent
