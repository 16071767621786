import auth0 from "auth0-js"
import { oauthRedirectUrl, oauthClientId, oauthReturnToUrl } from "./config"

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      // TODO: get these from a config API endpoint
      domain: "stadion.auth0.com",
      audience: "https://lively2",
      clientID: oauthClientId,
      redirectUri: oauthRedirectUrl,
      responseType: "token id_token",
      scope: "openid profile",
    })

    this.getProfile = this.getProfile.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getAccessToken = this.getAccessToken.bind(this)
    this.signIn = this.signIn.bind(this)
    this.signOut = this.signOut.bind(this)
  }

  getProfile() {
    return this.profile
  }

  getIdToken() {
    return this.idToken
  }

  getAccessToken() {
    return this.accessToken
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt
  }

  signIn() {
    this.auth0.authorize()
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err)
        if (!authResult || !authResult.idToken) {
          return reject(err)
        }
        this.setSession(authResult)

        resolve()
      })
    })
  }

  setSession(authResult) {
    this.idToken = authResult.idToken
    this.profile = authResult.idTokenPayload
    this.accessToken = authResult.accessToken
    // set the time that the id token will expire at
    this.expiresAt = authResult.idTokenPayload.exp * 1000

    window.rg4js("setUser", {
      identifier: this.profile.sub,
      isAnonymous: false,
      email: this.profile.sub.name,
      firstName: this.profile.sub.nickname,
    })
  }

  signOut() {
    this.auth0.logout({
      returnTo: oauthReturnToUrl,
      clientID: oauthClientId,
    })
  }

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err)
        this.setSession(authResult)
        resolve()
      })
    })
  }
}

const auth0Client = new Auth()

export default auth0Client
