import React, { useState } from "react"
import { SidebarTwitterTabs, TwitterPanels } from "./SidebarTwitterTabs"
import PropTypes from "prop-types"
import { SidebarTwitterSearch } from "./SidebarTwitterSearch"
import { SidebarTwitterHandle } from "./SidebarTwitterHandle"

const Component = ({
  setTwitterSidebarPanel,
  tweets,
  searchTweets,
  setEditableUpdate,
  onSidebarInstantTweet,
  getHandleTweets,
  opponentHandle,
  officialHandle,
  eventId,
  editEvent,
  setTwitterAssetUrl,
  clearTwitterAssetUrl,
  selectedTwitterAssetUrl,
}) => {
  const [settingOpponentHandle, setSettingOpponentHandle] = useState()

  const { sidebarPanel, searchResults, isSearching } = tweets

  const instantPost = async (url) => {
    await onSidebarInstantTweet(url)
  }

  const editPost = (url) => {
    setTwitterAssetUrl(url)
    setEditableUpdate({ linkEmbedUrl: url })
  }

  const clearSelectedPost = () => {
    clearTwitterAssetUrl()
    setEditableUpdate({})
  }

  const uiKeyOfficial = "offical"
  const officialHandleTweets = tweets.handleTweets[uiKeyOfficial] || {}

  const uiKeyOpponent = "opponent"
  const opponentHandleTweets = tweets.handleTweets[uiKeyOpponent] || {}

  const setOpponentTwitterHandle = async (handle) => {
    setSettingOpponentHandle(true)
    await editEvent({ id: eventId, opponentTwitterHandle: handle })
    setSettingOpponentHandle(false)
  }

  return (
    <div>
      <SidebarTwitterTabs
        setTwitterSidebarPanel={setTwitterSidebarPanel}
        sidebarPanel={sidebarPanel}
        hasOpponent={opponentHandle}
        accountTitle={officialHandle}
      />

      {sidebarPanel === TwitterPanels.official && (
        <SidebarTwitterHandle
          handle={officialHandle}
          handleTweets={officialHandleTweets}
          getHandleTweets={getHandleTweets}
          instantPost={instantPost}
          editPost={editPost}
          uiKey={uiKeyOfficial}
          selectedTwitterAssetUrl={selectedTwitterAssetUrl}
          doClearTwitterAssetUrl={clearSelectedPost}
        />
      )}
      {sidebarPanel === TwitterPanels.opponent && opponentHandle && (
        <SidebarTwitterHandle
          handle={opponentHandle}
          handleTweets={opponentHandleTweets}
          getHandleTweets={getHandleTweets}
          instantPost={instantPost}
          editPost={editPost}
          uiKey={uiKeyOpponent}
          setHandle={setOpponentTwitterHandle}
          settingHandle={settingOpponentHandle}
          selectedTwitterAssetUrl={selectedTwitterAssetUrl}
          doClearTwitterAssetUrl={clearSelectedPost}
        />
      )}
      {sidebarPanel === TwitterPanels.search && (
        <SidebarTwitterSearch
          searchResults={searchResults}
          isSearching={isSearching}
          searchTweets={searchTweets}
          instantPost={instantPost}
          editPost={editPost}
          selectedTwitterAssetUrl={selectedTwitterAssetUrl}
          doClearTwitterAssetUrl={clearSelectedPost}
        />
      )}
    </div>
  )
}

Component.propTypes = {
  setTwitterSidebarPanel: PropTypes.func.isRequired,
  tweets: PropTypes.object.isRequired,
  setEditableUpdate: PropTypes.func.isRequired,
  onSidebarInstantTweet: PropTypes.func.isRequired,
  getHandleTweets: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  setTwitterAssetUrl: PropTypes.func.isRequired,
  clearTwitterAssetUrl: PropTypes.func.isRequired,
}
export const SidebarTwitter = Component
