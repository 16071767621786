import React, { useState } from "react"
import styled, { css } from "styled-components"
import { ReactComponent as Upload } from "../../static/icons/upload.svg"
import { ReactComponent as Add } from "../../static/icons/plus.svg"
import PropTypes from "prop-types"
import { LoadingOverlay } from "../loading-overlay/LoadingOverlay"

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
`
const Actions = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
  button:first-child {
    border-right: 1px solid ${(props) => props.theme.colors.lightGrey};
  }
`
const Button = styled.button`
  display: inline-block;
  width: 50%;
  padding: 1em;

  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  svg {
    width: 20px;
    height: 20px;
  }
`

const doRotate = (selected) => {
  if (selected) {
    return css`
      transform: rotate(45deg);
    `
  }
}

const SelectedRotate = styled.span`
  display: inline-block;
  ${(props) => doRotate(props.selected)}
`

const CardContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
  padding-bottom: 1.5em;
  margin-bottom: 1.5em;
`

const Component = ({
  instantPost,
  editPost,
  selected,
  url,
  children,
  doClearTwitterAssetUrl,
}) => {
  const [loading, setLoading] = useState()

  const handleInstantPost = async (url) => {
    setLoading(true)
    await instantPost(url)
    setLoading(false)
  }

  const handleEditPost = (url) => {
    if (selected) {
      doClearTwitterAssetUrl()
    } else {
      editPost(url)
    }
  }

  return (
    <CardContainer>
      <Wrapper>
        {loading && (
          <LoadingOverlay opacity={0.8} text="Sending tweet to feed" />
        )}
        {children}
        <Actions>
          <Button onClick={() => handleInstantPost(url)} disabled={selected}>
            <Upload />
          </Button>
          <Button onClick={() => handleEditPost(url)}>
            <SelectedRotate selected={selected}>
              <Add />
            </SelectedRotate>
          </Button>
        </Actions>
      </Wrapper>
    </CardContainer>
  )
}

Component.propTypes = {
  instantPost: PropTypes.func.isRequired,
  editPost: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  url: PropTypes.string.isRequired,
  doClearTwitterAssetUrl: PropTypes.func.isRequired,
}

export const TwitterCardControls = Component
