import React, { Fragment } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

import {
  DARK_BLUE,
  DARK_BLUE_ACTIVE,
  LIGHT_BLUE,
  LIGHT_BLUE_ACTIVE,
  WHITE,
  GREY,
  LIGHT_GREY,
} from "../../../styles/core/colours"
import { LoadingSpinner } from "../../loading-spinner/LoadingSpinner"

const ButtonStyles = {
  light: {
    backgroundColor: LIGHT_BLUE,
    backgroundColorActive: LIGHT_BLUE_ACTIVE,
    textColor: WHITE,
    disabledTextColor: GREY,
    disabledBorderColor: GREY,
  },
  dark: {
    backgroundColor: DARK_BLUE,
    backgroundColorActive: DARK_BLUE_ACTIVE,
    textColor: WHITE,
    disabledTextColor: LIGHT_GREY,
    disabledBorderColor: LIGHT_GREY,
  },
  transparentLight: {
    backgroundColor: "transparent",
    backgroundColorActive: "transparent",
    textColor: WHITE,
    disabledTextColor: GREY,
    disabledBorderColor: GREY,
  },
  transparentDark: {
    backgroundColor: "transparent",
    backgroundColorActive: "transparent",
    textColor: DARK_BLUE,
    disabledTextColor: LIGHT_GREY,
    disabledBorderColor: LIGHT_GREY,
  },
}

const StyledButton = styled.button`
  background: ${({ buttonStyle }) => ButtonStyles[buttonStyle].backgroundColor};
  color: ${({ buttonStyle }) => ButtonStyles[buttonStyle].textColor};
  padding: 12px 20px;
  border: 0;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: ${(props) => props.theme.letterSpacing.button};
  text-decoration: none;
  transition: 0.3s background ease-in-out;
  width: ${(props) => (props.fluid ? "100%" : "inherit")};
  cursor: pointer;
  display: inline-block;

  &:hover {
    background: ${({ buttonStyle }) =>
      ButtonStyles[buttonStyle].backgroundColorActive};
  }

  &:disabled {
    background-color: transparent;
    color: ${({ buttonStyle }) => ButtonStyles[buttonStyle].disabledTextColor};
    border: 1px solid
      ${({ buttonStyle }) => ButtonStyles[buttonStyle].disabledBorderColor};
    cursor: not-allowed;
  }

  /* Make sure clicks can't be fired when the button is loading*/
  ${(props) =>
    props.loading &&
    css`
      pointer-events: none;
    `}
`

const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
`

const Content = ({ loading, label }) => {
  return loading ? <LoadingSpinner size="small" /> : label
}
const Button = ({
  buttonStyle = "dark",
  href,
  label,
  type = "button",
  onClick,
  disabled,
  loading,
  fluid,
}) => (
  <Fragment>
    {type === "button" || type === "submit" ? (
      <StyledButton
        type={type}
        buttonStyle={buttonStyle}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        fluid={fluid}
      >
        <ButtonInner>
          <Content loading={loading} label={label} />
        </ButtonInner>
      </StyledButton>
    ) : (
      <StyledButton
        as="a" // see here for what this is: https://www.styled-components.com/docs/api#as-polymorphic-prop
        href={href}
        buttonStyle={buttonStyle}
        onClick={onClick}
        disabled={disabled}
        fluid={fluid}
      >
        <ButtonInner>
          <Content loading={loading} label={label} />
        </ButtonInner>
      </StyledButton>
    )}
  </Fragment>
)

Button.propTypes = {
  buttonStyle: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

export default Button
