import React, { useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { Container } from "./TempusImageRenderer.styles"

const Component = ({ imageUrl, tempusExApiKey, onClick, selected }) => {
  const [image, setImage] = useState()

  if (!imageUrl) return <></>

  const headers = {
    Authorization:
      tempusExApiKey && tempusExApiKey.includes(".")
        ? `Bearer ${tempusExApiKey}`
        : `token ${tempusExApiKey}`,
    "Content-Type": "application/json",
  }

  axios
    .get(imageUrl, { headers, responseType: "arraybuffer" })
    .then((res) => {
      const base64 = btoa(
        new Uint8Array(res.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      )
      setImage(base64)
    })
    .catch((err) => console.log(err))

  return (
    <Container
      selected={selected === "n/a" ? "n/a" : !!selected}
      onClick={onClick && onClick}
    >
      {image && <img src={`data:;base64,${image}`} alt="" />}
    </Container>
  )
}

Component.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  tempusExApiKey: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}

export const TempusImageRenderer = Component
