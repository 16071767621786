// const GREEN = "#006633"
// const PURPLE = "#54008B"
// const DEFAULT_COLUMN_COUNT = 12
// const GUTTER = 25 // px
// const COLUMN_GUTTER = "10" // px
const CONTAINER_WIDTH = "1000px"

const theme = {
  scaffolding: {
    containerWidth: CONTAINER_WIDTH,
  },
  letterSpacing: {
    button: "2px",
  },
  fonts: {},
  colors: {
    white: "#fff",
    black: "#000",
    midGrey: "#6E6E6E",
    grey: "#9B9B9B",
    lightGrey: "#D1D1D1",
    lighterGrey: "#F6F7F8",
    lighterGreyHighlight: "#e8ecf1", // Ask Tristian about highlight colors
    lightBlue: "#1976DC",
    lightBlueActive: "#006EF9",
    coreBlue: "#1CA3E1", // name from sketch pallete
    midBlue: "#2F5275",
    darkBlue: "#032342",
    darkBlueActive: "#04315C",
    red: "#FF4261",
    green: "#50E3C2",

    // have had to go to semantic naming because the colours above don't
    // meet all the requirements of the sketch file and no time to re
    // set the style guide on colors
    background: "#F4F4F4",
    formLabelLight: "#EBEBEB",
    navEditModeColor: "#8E9DAB",
    greyText: "#595959",
    formPlaceholderDark: "#032342",
    formPlaceholderLight: "#678093",
    fieldFocusHighlight: "#0D92EA",
    sidebarBackgroundText: "#707070",
  },
  sizes: {},
  responsive: {
    xSmall: "359px",
    small: "400px",
    smallish: "700px",
    medium: "900px",
    nav: "1000px",
    large: "1200px",
    xLarge: "1400px",
  },
  transitionSpeed: {
    short: ".5s",
    long: "1s",
  },
}

export default theme
