import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Lozenge from "../../lozenge/Lozenge"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
`

const EventTypeList = ({ value, onChange, eventTypes }) => {
  const maxToShowWhenClosed = 4
  let shouldBeOpen = false

  const filteredEventTypes = eventTypes.filter(
    (type) => type.excludeFromAdmin === false
  )

  if (value) {
    // This code will force the more list to be open
    // if the value that has been set is greater than
    // the number we show when the list is "closed"
    const selected = filteredEventTypes.find((u) => u.key === value)
    if (filteredEventTypes.indexOf(selected) > maxToShowWhenClosed - 1) {
      shouldBeOpen = true
    }
  }

  const [open, setOpen] = useState(shouldBeOpen)

  let toShow
  let moreButton

  if (open) {
    toShow = filteredEventTypes
  } else {
    toShow = filteredEventTypes.slice(0, maxToShowWhenClosed)
    moreButton = (
      <Lozenge onClick={() => setOpen(true)}>
        <strong>More&hellip;</strong>
      </Lozenge>
    )
  }

  return (
    <Wrapper>
      {toShow.map((u, i) => (
        <Lozenge
          key={i}
          selected={value === u.key}
          onClick={(e) => {
            e.preventDefault()
            if (value === u.key) {
              onChange("") // not undefined
            } else {
              onChange(u.key)
            }
          }}
        >
          {u.label}
        </Lozenge>
      ))}
      {moreButton}
    </Wrapper>
  )
}

EventTypeList.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default EventTypeList
