import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

export const TwitterPanels = {
  official: "official",
  opponent: "opponent",
  search: "search",
}
const TabsWrapper = styled.div`
  display: flex;
  padding-top: 12px;
  padding-bottom: 40px;

  button {
    flex-grow: 1;
    flex-basis: 0;
  }
`

const appylActive = (isActive) => {
  if (isActive) {
    return css`
      border-bottom: 3px solid ${(props) => props.theme.colors.lightBlue};
      color: ${(props) => props.theme.colors.darkBlueActive};
    `
  }
}
const ButtonContent = styled.span`
  padding-bottom: 1em;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.midGrey};
  ${(props) => appylActive(props.isActive)};
`

const Component = ({
  setTwitterSidebarPanel,
  sidebarPanel,
  accountTitle,
  hasOpponent,
}) => {
  const renderButton = (panel, title) => {
    return (
      <button onClick={() => setTwitterSidebarPanel(panel)}>
        <ButtonContent isActive={sidebarPanel === panel}>{title}</ButtonContent>
      </button>
    )
  }
  return (
    <TabsWrapper>
      {renderButton(TwitterPanels.official, accountTitle || "Official")}
      {hasOpponent && renderButton(TwitterPanels.opponent, "Opponent")}
      {renderButton(TwitterPanels.search, "Search")}
    </TabsWrapper>
  )
}

Component.propTypes = {
  setTwitterSidebarPanel: PropTypes.func.isRequired,
  sidebarPanel: PropTypes.string.isRequired,
}
export const SidebarTwitterTabs = Component
