import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { TempusImageRenderer } from "../../tempus-image-renderer"
import { ReactComponent as Pencil } from "../../../static/icons/pencil.svg"
import {
  Wrapper,
  Content,
  SubHeading,
  ActionCta,
  MinuteMark,
  TeamName,
  PlayerName,
  GoalImagesSubHeading,
  GoalImageContainer,
  GoalImagesSelector,
  GoalImagesSelectorPlusContainer,
  StyledPlusIcon,
  PostedText,
  GoalImagesSelectorWithImage,
  EditOverlay,
} from "./Card.styles"

const Component = ({
  matchId,
  event,
  showTempusImagePicker,
  tempusExApiKey,
  tempusExApiBaseUrl,
  tempusCard,
}) => {
  const [teamName, setTeamName] = useState("-")

  const handleImageSelectorClick = () => {
    showTempusImagePicker(matchId, event.time, tempusCard)
  }

  const headers = {
    Authorization:
      tempusExApiKey && tempusExApiKey.includes(".")
        ? `Bearer ${tempusExApiKey}`
        : `token ${tempusExApiKey}`,
    "Content-Type": "application/json",
  }

  const requestBody = {
    query: `
    query ChelseaSoccerMatches {
      soccerMatchesConnection(
        first: 1000
        predicate: {expression: """(team("CHE") or team("CHEW"))"""}
      ) {
        edges {
          node {
            ... on SoccerMatch {
              id
              time
              homeTeamEdge {
                node {
                  abbreviation
                  name
                }
              }
              awayTeamEdge {
                node {
                  abbreviation
                  name
                }
              }
            }
          }
        }
        pageInfo {
          hasPreviousPage
          startCursor
          endCursor
          hasNextPage
        }
      }
    }`,
  }

  const { isLoading, error, data } = useQuery({
    queryKey: ["cardTeamName"],
    queryFn: () =>
      axios
        .post(`${tempusExApiBaseUrl}/v2/graphql`, requestBody, { headers })
        .then((res) => res.data),
  })

  useEffect(() => {
    if (!data || !data.data) return

    const match = data.data.soccerMatchesConnection.edges.filter(
      (match) => match.node.id === matchId
    )
    if (match.length > 0) {
      switch (event.team) {
        case "HOME":
          setTeamName(match[0].node.homeTeamEdge.node.name)
          break
        case "AWAY":
          setTeamName(match[0].node.awayTeamEdge.node.name)
          break
        default:
          break
      }
    }
  }, [data, matchId, teamName, event.team])

  if (error) {
    return <div>An error has occurred</div>
  }

  return (
    <Wrapper>
      <Content>
        <SubHeading>Tempus Goal Event</SubHeading>
        <MinuteMark>{event.minuteDisplay}</MinuteMark>
        <TeamName>{isLoading ? "-" : teamName}</TeamName>
        <PlayerName>
          {event.shotBy && event.shotBy.name ? event.shotBy.name : ""}
        </PlayerName>
        <GoalImagesSubHeading>Goal Images</GoalImagesSubHeading>
        <GoalImageContainer>
          {tempusCard && tempusCard.imageUrl1 ? (
            <GoalImagesSelectorWithImage onClick={handleImageSelectorClick}>
              <TempusImageRenderer
                imageUrl={tempusCard.imageUrl1}
                tempusExApiKey={tempusExApiKey}
                selected={"n/a"}
              />
              <EditOverlay>
                <Pencil />
              </EditOverlay>
            </GoalImagesSelectorWithImage>
          ) : (
            <GoalImagesSelector onClick={handleImageSelectorClick}>
              <GoalImagesSelectorPlusContainer>
                <StyledPlusIcon />
              </GoalImagesSelectorPlusContainer>
            </GoalImagesSelector>
          )}
          {tempusCard && tempusCard.imageUrl2 ? (
            <GoalImagesSelectorWithImage onClick={handleImageSelectorClick}>
              <TempusImageRenderer
                imageUrl={tempusCard.imageUrl2}
                tempusExApiKey={tempusExApiKey}
                onClick={() => {}}
                selected={"n/a"}
              />
              <EditOverlay>
                <Pencil />
              </EditOverlay>
            </GoalImagesSelectorWithImage>
          ) : (
            <GoalImagesSelector onClick={handleImageSelectorClick}>
              <GoalImagesSelectorPlusContainer>
                <StyledPlusIcon />
              </GoalImagesSelectorPlusContainer>
            </GoalImagesSelector>
          )}
        </GoalImageContainer>
      </Content>
      {tempusCard && (
        <ActionCta>
          <PostedText>Posted</PostedText>
        </ActionCta>
      )}
    </Wrapper>
  )
}

Component.propTypes = {
  matchId: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
  showTempusImagePicker: PropTypes.func.isRequired,
  tempusExApiKey: PropTypes.string.isRequired,
  tempusExApiBaseUrl: PropTypes.string.isRequired,
  tempusCard: PropTypes.object,
}

export const Card = Component
