import React, { useState } from "react"
import { ImageSearchResult } from "./image-search-result"
import styled from "styled-components"
import { LoadingOverlay } from "../loading-overlay/LoadingOverlay"
import PropTypes from "prop-types"
import { API } from "../../api"
import { Input } from "../form-components/input/Input"
import { SidebarMessage } from "../sidebar/SidebarMessage"

const Wrap = styled.div`
  position: relative;
`
const ImageSearch = (props) => {
  const {
    getImages,
    setImageAssetId,
    images,
    onImageUpload,
    setEditableUpdate,
    selectedImageAssetId,
    clearImageAssetId,
    clearEditableUpdateImage,
  } = props
  const [query, setQuery] = useState()
  const [loading, setLoading] = useState()
  const [hasSearched, setHasSearched] = useState()

  const doImageUpload = async (assetId) => {
    const asset = await API.downloadImage(assetId)
    await onImageUpload(asset.data.imageUrl)
  }

  const doEditImage = async (assetId) => {
    setImageAssetId(assetId)
    const asset = await API.downloadImage(assetId)
    setEditableUpdate({ imageUrl: asset.data.imageUrl })
  }
  const doClearEditImage = () => {
    clearImageAssetId()
    clearEditableUpdateImage()
  }
  return (
    <Wrap>
      <Input
        isValid
        placeholder="Search"
        disabled={loading}
        onChange={(e) => setQuery(e.currentTarget.value)}
        onKeyDown={async (e) => {
          if (e.key === "Enter") {
            if (!hasSearched) {
              setHasSearched(true)
            }
            setLoading(true)
            await getImages(query)
            setLoading(false)
          }
        }}
      />
      {loading && <LoadingOverlay />}
      {images.searchResult.images.map((img, i) => (
        <ImageSearchResult
          key={i}
          assetId={img.assetId}
          src={img.url}
          doImageUpload={doImageUpload}
          doEditImage={doEditImage}
          selected={selectedImageAssetId === img.assetId}
          doClearEditImage={doClearEditImage}
        />
        // <div>
        //   <Image src={img.url} />
        //   <button onClick={() => setImageAsset(img.url)}>Add Image</button>
        // </div>
      ))}
      {!loading && !images.searchResult.images.length && hasSearched && (
        <SidebarMessage
          title="Nothing to see here."
          text="Try searching or refreshing the feed."
        />
      )}
    </Wrap>
  )
}

ImageSearch.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
  getImages: PropTypes.func.isRequired,
  activeSection: PropTypes.string,
  images: PropTypes.object.isRequired,
}

export default ImageSearch
