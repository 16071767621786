export function getEnvValue(key) {
  return String(process.env[`REACT_APP_${key}`])
}

export const oauthRedirectUrl = getEnvValue("OAUTH_REDIRECT_URL")
export const oauthClientId = getEnvValue("OAUTH_CLIENT_ID")
export const oauthReturnToUrl = getEnvValue("OAUTH_RETURN_TO_URL")
export const livelyEndPoint = getEnvValue("LIVELY_API_END_POINT")

export const eventsVariables = {
  //TODO currently just set to 100. This may change going forward as there may be loads of older events for existing clients
  pastEventsDefaultCount: 10,
  pastEventsMaxCount: 100,
  upcomingEventsDefaultCount: 3,
  upcomingEventsMaxCount: 100,
}
