import React from "react"
import styled, { css } from "styled-components"
import Button from "../form-components/button/button"
import PropTypes from "prop-types"
import { Right, Left, CancelButton } from "../feed-item/styles"
import { ReactComponent as Bin } from "../../static/bin.svg"

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2em;
`

const BinButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 200ms ease-in-out;
  cursor: not-allowed;
  font-size: 1em;
  ${(props) =>
    props.dirty &&
    css`
      cursor: pointer;
      opacity: 1;
    `}

  :disabled {
    opacity: 0.5;
    svg {
      opacity: 0.4;
    }
  }
`

const UpdateFormFooter = ({
  isSubmitting,
  isEditing,
  onCancelEditing,
  onCancelNewPost,
  dirty,
  canSubmit,
}) => {
  return (
    <Wrapper>
      <Left>
        {isEditing && (
          <CancelButton
            disabled={isSubmitting}
            onClick={(e) => {
              e.preventDefault()
              onCancelEditing()
            }}
          >
            Cancel editing
          </CancelButton>
        )}
        {!isEditing && onCancelNewPost && (
          <BinButton
            onClick={(e) => {
              e.preventDefault()
              onCancelNewPost()
            }}
            disabled={!dirty || isSubmitting}
            dirty={dirty}
          >
            <Bin /> &nbsp; Clear
          </BinButton>
        )}
      </Left>
      <Right>
        <Button
          label={isEditing ? "Update" : "Post"}
          type="submit"
          loading={isSubmitting}
          disabled={!canSubmit}
        />
      </Right>
    </Wrapper>
  )
}

UpdateFormFooter.propTypes = {
  isSubmitting: PropTypes.bool,
  isEditing: PropTypes.bool,
  onCancelEditing: PropTypes.func.isRequired,
  onCancelNewPost: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
  canSubmit: PropTypes.bool,
}

export default UpdateFormFooter
