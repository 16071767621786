import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.sidebarBackgroundText};
  padding-top: 2em;
`
export const SidebarMessage = ({ title, text }) => {
  return (
    <Wrapper>
      <p style={{ marginBottom: "0.6em" }}>
        <strong>{title}</strong>
      </p>
      <p>{text}</p>
    </Wrapper>
  )
}
