// I've named this simial to the existing. Will clean this up when I go through to tidy everything at the end
import React from "react"
import { FormField } from "../form-field/FormField"
import PropTypes from "prop-types"
import Datetime from "react-datetime"
import "../../../../node_modules/react-datetime/css/react-datetime.css"
import { Input } from "../input/Input"
import { formatDateTime } from "../../../utils/time"
import { ReactComponent as Calendar } from "../../../static/icons/calendar.svg"

/**
 * This InputField is a wrapper that connects a HTML input field into Formik
 */
export const DateField = ({
  form, // A formik prop
  field, // A formik prop
  label,
  input,
  animationDelay,
  light,
  required,
}) => {
  const errorMessage = form.errors[field.name]
  const touched = form.touched[field.name] === true || form.submitCount > 0
  let inputRef = React.createRef()

  return (
    <FormField
      name={field.name}
      label={label}
      touched={touched}
      errorMessage={errorMessage}
      animationDelay={animationDelay}
      light={light}
      required={required}
    >
      <Datetime
        onChange={(moment) => {
          form.setFieldValue(field.name, moment.format("YYYY-MM-DDTHH:mm:ss"))
        }}
        value={field.value ? Date.parse(field.value) : undefined}
        renderInput={(dateTimePluginInputProps) => {
          return (
            <Input
              inputRef={inputRef}
              light={light}
              {...dateTimePluginInputProps}
              value={formatDateTime(field.value)}
              disabled={form.isSubmitting}
              isError={touched && errorMessage !== undefined}
              icon={
                <Calendar
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    inputRef.current.focus()
                  }}
                />
              }
              onKeyDown={(e) => {
                // Avoid any manual input
                e.preventDefault()
                e.stopPropagation()
              }}
            />
          )
        }}
      />
    </FormField>
  )
}

DateField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  animationDelay: PropTypes.number,
  light: PropTypes.bool,
}
