// I've named this simial to the existing. Will clean this up when I go through to tidy everything at the end
import React from "react"
import { FormField } from "../form-field/FormField"
import PropTypes from "prop-types"
import { Input } from "../input/Input"

/**
 * This InputField is a wrapper that connects a HTML input field into Formik
 */
export const InputField = ({
  form, // A formik prop
  field, // A formik prop
  label,
  input,
  animationDelay,
  light,
  required,
}) => {
  const errorMessage = form.errors[field.name]
  const touched = form.touched[field.name] === true || form.submitCount > 0
  return (
    <FormField
      name={field.name}
      label={label}
      touched={form.touched[field.name] === true || form.submitCount > 0}
      errorMessage={errorMessage}
      animationDelay={animationDelay}
      light={light}
      required={required}
    >
      <Input
        light={light}
        {...field}
        {...input}
        disabled={form.isSubmitting}
        isError={touched && errorMessage !== undefined}
      />
    </FormField>
  )
}

InputField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  animationDelay: PropTypes.number,
  light: PropTypes.bool,
}
