import React from "react"
import { formatDate } from "../../utils/time"
import { EventInfoContainer, Left, Right, TeamName, Date } from "./styles"

class EventInfoBanner extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      index: props.selectedIndex,
    }
  }

  //Trying to add this her to reduce text flicker. Not working but leaving in as good perf practice example :-)
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.name !== nextProps.name) {
      return true
    }
    if (this.props.date !== nextProps.date) {
      return true
    }
    if (this.props.editEventIsActive !== nextProps.editEventIsActive) {
      return true
    }
    return false
  }

  render() {
    const {
      name,
      // isLive,
      date,
      time,
      editEventIsActive,
      className,
    } = this.props
    return (
      <EventInfoContainer
        className={className}
        editEventIsActive={editEventIsActive}
      >
        <Left>
          <TeamName className="event-info-team-name">{name}</TeamName>
          <Date className="event-info-date">{formatDate(date)}</Date>
        </Left>
        <Right>{time}</Right>
      </EventInfoContainer>
    )
  }
}

export default EventInfoBanner
