import styled from "styled-components"

export const UpdateFeedContainer = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    padding: 50px 0;
    width: 80%;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    width: 600px;
  }
`
