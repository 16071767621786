import React from "react"

import { TopNavigationContainer, Left, Right, Back } from "./styles"
import { ReactComponent as BackIcon } from "../../static/icons/back.svg"

export default ({
  left,
  right,
  hasTopPanelOpen,
  condensed,
  hideBackButton,
}) => (
  <TopNavigationContainer
    hasTopPanelOpen={hasTopPanelOpen}
    className={condensed ? "condensed" : ""}
  >
    {!hideBackButton && (
      <Back className="event-info-back-icon" href="/" condensed={condensed}>
        <BackIcon />
        <span
          style={{ marginLeft: "1em", fontWeight: 500 }}
          className={!condensed ? "visually-hidden" : ""}
        >
          {" "}
          Back{" "}
        </span>{" "}
      </Back>
    )}

    <Left>{left}</Left>
    <Right>{right}</Right>
  </TopNavigationContainer>
)
