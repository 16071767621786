import moment from "moment"

export const formatDate = (dateString) => {
  if (!dateString) {
    return ""
  }
  return moment(dateString).format("Do MMMM YYYY")
}

export const formatDateTime = (dateString) => {
  if (!dateString) {
    return ""
  }
  return moment(dateString).format("Do MMMM YYYY h:mm A")
}

export const EventLiveStatus = {
  PreMatch: "PreMatch",
  Live: "Live",
  HalfTime: "HalfTime",
  FullTime: "FullTime",
}
