import React from "react"
import Button from "../form-components/button/button"
import PropTypes from "prop-types"
import { API } from "../../api"
import { ErrorMessage } from "../form-components/form-field/FormField"
import { Input } from "../form-components/input/Input"
import { validStringValue } from "../../styles/core/forms"
import styled from "styled-components"
import { DeleteButton } from "../delete-button/DeleteButton"
import { renderGlobalIframelyWidgets } from "../../utils"

class EmbedField extends React.Component {
  constructor(props) {
    super()
    this.state = {
      workingURL: props.url || "",
      processing: false,
      embedHTML: props.initWithEmbedHTML,
      responseError: undefined,
    }
  }
  componentDidMount() {
    const { url } = this.props

    if (url) {
      this.processEmbedUrl(url)
    }
  }

  processEmbedUrl = async (urlToProcess) => {
    if (!urlToProcess) {
      return
    }

    try {
      this.setState({ processing: true, responseError: undefined })
      const previewData = await API.loadEmbedPreview(urlToProcess)
      const embedHTML = previewData.data.htmlNoScript
      if (embedHTML) {
        this.setState({ embedHTML })
        renderGlobalIframelyWidgets()
        this.props.onUrlChange(urlToProcess)
      } else {
        this.setState({
          responseError: "This content doesn't seem to be embeddable.",
        })
      }
    } catch (error) {
      this.setState({
        responseError:
          "There was an error generating the preview. Please try repasting the URL.",
      })
    } finally {
      this.setState({ processing: false })
    }
  }

  deleteEmbed = () => {
    this.setState({
      workingURL: undefined,
    })
    this.props.onUrlChange(undefined)
    if (this.props.onDelete) {
      this.props.onDelete()
    }
  }

  componentDidUpdate(prevProps) {
    const { url } = this.props
    if (url !== prevProps.url) {
      this.setState({
        workingURL: url,
        embedHTML: undefined,
      })
      this.processEmbedUrl(url) // Process the new URL
    }
  }

  render() {
    const { label, onBlur, onFocus, isError } = this.props
    const { embedHTML, workingURL, processing, responseError } = this.state
    if (embedHTML) {
      return (
        <div style={{ position: "relative" }}>
          <DeleteButton onClick={this.deleteEmbed} />
          <div dangerouslySetInnerHTML={{ __html: embedHTML }} />
        </div>
      )
    }
    if (!embedHTML) {
      return (
        <div>
          <InnerWrapper>
            <InputWrapper>
              <Input
                type="text"
                placeholder={label}
                value={workingURL}
                onChange={(e) => {
                  this.setState({ workingURL: e.currentTarget.value })
                }}
                disabled={processing}
                isError={
                  !processing && (responseError !== undefined || isError)
                }
                onBlur={() => {
                  // We had a short time out on blur so that
                  // the message doesn't show immediately. Because
                  // the get button will cause a blur, it can make the
                  // validations to trigger on blur, even if the user
                  // has a valid URL, but it's just not processed yet.
                  // This does feel hacky, so maybe there's a better solution.
                  setTimeout(onBlur, 500)
                }}
                onFocus={onFocus}
              />
            </InputWrapper>
            <ButtonWrapper>
              <Button
                label="Get"
                onClick={() => this.processEmbedUrl(workingURL)}
                loading={processing}
                disabled={!validStringValue(workingURL)}
              />
            </ButtonWrapper>
          </InnerWrapper>
          <ErrorMessage show={!processing && validStringValue(responseError)}>
            {responseError}
          </ErrorMessage>
        </div>
      )
    }
  }
}

const InnerWrapper = styled.div`
  display: flex;
`

const ButtonWrapper = styled.div`
  flex: 0 1 auto;
  margin-left: 1em;
  button {
    /* Hack to get button height same as fields. Button and field baseline haven't been considered in respect with each other, so hard to do retroactively. */
    height: 50px;
  }
`
const InputWrapper = styled.div`
  flex: 1;
`

EmbedField.propTypes = {
  onUrlChange: PropTypes.func.isRequired,
  // deleteEmbed: PropTypes.func.isRequired,
  url: PropTypes.string,
  onTitleRetrieved: PropTypes.func,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  isError: PropTypes.bool,
  onDelete: PropTypes.func,
}

export default EmbedField
