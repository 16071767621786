import React from "react"
import styled, { css } from "styled-components"
import { ReactComponent as Ball } from "../../../static/icons/ball.svg"

const Button = styled.button`
  border-radius: 120px;
  height: 40px;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  appearance: none;
  padding-left: 1.5em;
  padding-right: ${(props) => (props.logoVisible ? "3em" : "1.5em")};
  font-size: 14px;
  line-height: 1;
  ${(props) =>
    props.active &&
    css`
      border-color: ${(props) => props.theme.colors.fieldFocusHighlight};
      border-width: 2px;
    `}
  cursor: pointer;
  :focus {
    outline: none;
  }
`

const Wrapper = styled.div`
  position: relative;
`
const IconWrapper = styled.div`
  position: absolute;
  right: 2px;
  pointer-events: none;
`

export const EventTypeListTrigger = ({ active, onClick, clientType }) => {
  return (
    <Wrapper>
      {clientType !== "Cycling" && (
        <IconWrapper>
          <Ball />
        </IconWrapper>
      )}
      <Button
        logoVisible={clientType !== "Cycling" ? true : false}
        active={active}
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
      >
        Events
      </Button>
    </Wrapper>
  )
}
