import styled from "styled-components"
import { Link } from "react-router-dom"
import React from "react"

//TODO:  Find a way to pass the border
const StyledLink = styled(Link)`
  margin-top: 1.4em;
  padding: 24px 0;
  display: flex;
  align-items: baseline;
  font-size: 18px;
  line-height: 20px;
  text-decoration: none;
  font-weight: 300;
  border-bottom: 1px solid ${(props) => props.borderColor};
  &:hover {
    border-bottom: 1px solid ${(props) => props.borderHighlightColor};
  }
`

export default (props) => {
  return <StyledLink {...props} />
}
