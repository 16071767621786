import styled from "styled-components"
import {
  WHITE,
  CORE_BLUE,
  LIGHT_BLUE,
  MID_BLUE,
} from "../../styles/core/colours"
import { Container } from "../tempus-image-renderer/TempusImageRenderer.styles"

export const Wrapper = styled.section`
  height: calc(100vh - 100px - 6em);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 99;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border: 0px solid #fff;
    border-radius: 16px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  :hover::-webkit-scrollbar-thumb {
    background-color: ${MID_BLUE};
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${LIGHT_BLUE};
  }
`

export const Spacer = styled.div`
  height: 24px;
`

export const BackBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 4px;
  margin-bottom: 8px;
  cursor: pointer;

  svg {
    margin-right: 4px;

    g {
      fill: ${CORE_BLUE};
    }
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: ${WHITE};
  }
`

export const Heading = styled.h1`
  font-size: 24px;
  line-height: 20px;
  padding-top: 16px;
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`

export const ConfirmImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px 0;

  ${Container} {
    flex: 1 1 100%;
  }
`

export const ActionCta = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: fit-content;
  }
`
