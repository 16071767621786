import React, { useState } from "react"

import styled, { css } from "styled-components"

const maxCompletionSeconds = 1

const getAnimation = (props) => {
  if (props.isComplete || props.isLoading) {
    const seconsd = props.isComplete ? 1 : 3
    return css`
      animation-duration: ${seconsd}s;
      animation-name: fullWidth;
    `
  }

  return 0
}

const getColor = (props) => {
  if (props.hasError) {
    return props.theme.colors.red
  }
  return props.theme.colors.green
}

const ProgressBarElement = styled.div`
  height: 3px;
  background-color: ${(props) => getColor(props)};
  width: 0;
  @keyframes fullWidth {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  ${getAnimation};
`

const ThinLine = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.lightGrey};
  position: absolute;
  top: 1px;
  width: 100%;
  z-index: -1;
`

const Container = styled.div`
  position: relative;
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
`
const BarContainer = styled.div`
  flex: 1;
  position: relative;
`
const ErrorMessage = styled.div`
  flex: 0 1 auto;
  margin-left: 1em;
  color: ${(props) => props.theme.colors.red};
  font-size: 0.9em;
`
export const ProgressBar = ({ isLoading, errorMessage }) => {
  const [lastIsLoading, setLastIsLoading] = useState(false)
  const [isComplete, setComplete] = useState()

  if (lastIsLoading !== isLoading) {
    if (lastIsLoading) {
      // if last loading was true, and it's now false
      // then it indicates a success or error state
      // from load, both of which get 100% progress bar width

      setComplete(true)
      if (!errorMessage) {
        setTimeout(() => {
          // after a second we can clean up
          setComplete(false)
        }, (maxCompletionSeconds + 1) * 1000) // clean up 1 second after the completion animation finishes
      }
    }
    setLastIsLoading(isLoading)
  }

  return (
    <Container>
      <InnerContainer>
        <BarContainer>
          <ProgressBarElement
            // inProgress={inProgress}
            hasError={errorMessage !== undefined}
            isLoading={isLoading}
            isComplete={isComplete}
          />
          <ThinLine />
        </BarContainer>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </InnerContainer>
    </Container>
  )
}
