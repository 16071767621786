import React, { useState } from "react"
import styled from "styled-components"
import { ReactComponent as Upload } from "../../static/icons/upload.svg"
import { API } from "../../api"

const Item = styled.div`
  border: 1px solid #8191a0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8191a0;
  height: 100%;
  cursor: pointer;

  :hover {
    border-color: ${(props) => props.theme.colors.coreBlue};
    svg {
      g {
        fill: ${(props) => props.theme.colors.coreBlue};
      }
    }
  }
`

const UploadButton = styled.div`
  svg {
    g {
      fill: ${(props) => props.theme.colors.white};
    }
  }

  border: 1px solid #8191a0;
  border-radius: 100%;
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    svg {
      g {
        fill: ${(props) => props.theme.colors.coreBlue};
      }
    }
  }
`
export const UploadItem = ({ onImageChange, onaddfilestart, pond }) => {
  const [uploading, setUploading] = useState(false)
  const [percent, setPercent] = useState(0)

  const onUploadClick = () => {
    pond.browse()
  }

  if (pond) {
    pond.props = {
      ...pond.props,
      onaddfilestart,
      server: {
        process: async (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort
        ) => {
          try {
            setUploading(true)
            const response = await API.uploadImage(file, (progressEvent) => {
              progress(true, progressEvent.loaded, progressEvent.total)
              setPercent(progressEvent.loaded / progressEvent.total)
            })
            load(response.data.imageUrl)
            onImageChange(response.data.imageUrl)
          } catch (error) {
            console.error("Error occured whilst uploading comment image", error)
          } finally {
            setUploading(false)
          }
        },
      },
    }

    pond.onaddfilestart = onaddfilestart
  }

  return (
    <>
      <Item onClick={onUploadClick}>
        {uploading && <div>Uploading: {Math.ceil(percent * 100)}%</div>}
        {!uploading && (
          <UploadButton>
            <Upload />
          </UploadButton>
        )}
      </Item>
    </>
  )
}
