import React from "react"
import styled from "styled-components"
import Layout from "../layouts/index"
import GeneralContainer from "../layouts/containers/container"
import CollapsibleContainer from "../layouts/containers/collapsible-container/collapsible-container"
import ContainerWithSideBar from "../layouts/containers/container-with-sidebar/container-with-sidebar"
import TopNavigation from "../components/top-navigation/top-navigation"
import EventInfoBanner from "../components/event-info-banner/event-info-banner"
import Sidebar from "../components/sidebar/sidebar"
import StatusUpdate from "../components/status-update/status-update"
import CreateEventForm from "../components/create-event-form/create-event-form"
import auth from "../Auth"

import KeyMoments from "../components/key-moments/key-moments"

import { ContentContainer, AccountIcons } from "../styles/pages/editor"
import { ReactComponent as EditIcon } from "../static/edit.svg"
import { ReactComponent as ProfileIcon } from "../static/profile.svg"
import UpdateFeed from "../components/update-feed"
import { DARK_BLUE } from "../styles/core/colours"
import { Col, Container, Row } from "react-grid-system"

import useEditorPageLogic from "./editorReducer"
import { LoadingOverlay } from "../components/loading-overlay/LoadingOverlay"
import SideBarMenu from "../components/sidebar-menu/SideBarMenu"
import { ProgressBar } from "../components/progress-bar/progress-bar"
import { EventRealTimeDataClient } from "../utils/EventRealTimeDataClient"
import { MatchTime } from "../components/match-time/MatchTime"
import { extractValidationMessageFromApiError, API } from "../api"
import { Gallery } from "../components/gallery/Gallery"
import { TempusImagePicker } from "../components/tempus-image-picker/TempusImagePicker"

const realTimeClient = new EventRealTimeDataClient()

if (process.env.NODE_ENV === "development") {
  // Put it on the window so we can trigger events from chrome inspector
  window.realTimeClient = realTimeClient
}

/**
 * This method gets around the re-rendering issues in the Editor,
 * by keeping a singleton real time connection based on the client
 * key. If the client key changes, then it will reconnect with the
 * new key. The caveat is that the connection will remain open if
 * the user leaves the editor.
 *
 * I can't close it when the Editor
 * component below is unmounted, because it unmounts every time
 * the page re-renders, which can be many times a second. It's a
 * band aid solution, and once time
 * is available to refactor the state, then the connection should
 * be made on mount of the Editor component, and closed on dismount
 * of the component.
 */
const connectToRealTimeEventData = async (livelyClient, event, handler) => {
  if (!livelyClient.ablyApiKey) {
    return
  }

  await establishRealTimeConnection(livelyClient)

  if (
    livelyClient.ablyChannel &&
    event.channel // event.channel to be renamed server side
  ) {
    await realTimeClient.subscribeToEvent(
      livelyClient.ablyChannel,
      event.channel,
      handler
    )
  }
}

const establishRealTimeConnection = async (livelyClient) => {
  if (livelyClient.ablyApiKey !== realTimeClient.connectionKey) {
    // We have a different client now, which requires a completely new connection
    if (realTimeClient.connectionKey) {
      // Disconnect the old lively client
      await realTimeClient.close()

      // EDGE CASE: Because of the unmount issue, we should consider calling
      // disconnect on logout to ensure an existing client's connection doesn't
      // remain. Likely hood of different client logging in is non existant though?
    }

    // Reconnect with the new lively client
    await realTimeClient.connect(livelyClient.ablyApiKey)
  }
}

/**
 * This is a quick fix pattern to overcome the issues with state, without rearchitecting.
 * By extracting the layout out of the main editor component, it prevents the browser from
 * rerendering the whole  layout out again and again.
 */
const EditorWrapper = ({ children }) => {
  return <Layout>{children}</Layout>
}

const MainEditor = (props) => {
  const { id } = props.match.params

  const { actions, state } = useEditorPageLogic(id)
  const {
    // hasErrored,
    // errorMessage,
    activeSection,
    inEditMode,
    editableUpdate,
    event,
    updates,
    images,
    isEditingEvent,
    isEditingKeyMoments,
    isEditingGallery,
    isTempusImagePicker,
    hasTopPanelOpen,
    selectedImageAssetId,
    tweets,
    client,
    clientEventTypes,
    selectedTwitterAssetUrl,
    seasonList,
    realTimeEventData,
    keyMoments,
    gallery,
    tempusImagePickerData,
  } = state

  const editEvent = (data) => actions.editEvent(data)

  const showEditEvent = () => actions.showEditEvent()
  const showKeyMoments = () => actions.showKeyMoments()
  const showGallery = () => actions.showGallery()
  const showTempusImagePicker = (matchId, time, tempusCard) => {
    actions.setTempusImageSelectorData(matchId, time, tempusCard)
    actions.showTempusImagePicker()
  }
  const closeTopPanel = () => actions.closeTopPanel()

  if (client && event.data) {
    connectToRealTimeEventData(client, event.data, (message) => {
      actions.receivedRealTimeEventData(message)
    })
  }

  const renderContent = ({
    eventId,
    updates,
    sendUpdate,
    sendEditUpdate,
    updatePending,
    startUpdateEdit,
    inEditMode,
    editableUpdate,
    deleteUpdate,
    cancelUpdateEdit,
    selectedImage,
    selectedImageAssetId,
    clearImageAssetId,
    clearEditableUpdateImage,
    client,
  }) => {
    return (
      <ContentContainer>
        <div>
          <StatusUpdate
            eventId={eventId}
            clientEventTypes={clientEventTypes}
            sendUpdate={sendUpdate}
            sendEditUpdate={sendEditUpdate}
            updatePending={updatePending}
            inEditMode={inEditMode}
            editableUpdate={editableUpdate}
            selectedImage={selectedImage}
            cancelUpdateEdit={cancelUpdateEdit}
            selectedImageAssetId={selectedImageAssetId}
            clearImageAssetId={clearImageAssetId}
            clearEditableUpdateImage={clearEditableUpdateImage}
            clearTwitterAssetUrl={actions.clearTwitterAssetUrl}
            setEditableUpdate={actions.setEditableUpdate}
            clientType={client.clientType}
          />
          <ProgressBarContainer>
            <ProgressBar
              errorMessage={updates.errorMessage}
              isLoading={updates.isLoading}
            />
          </ProgressBarContainer>
        </div>
        <UpdateFeed
          updates={updates}
          startUpdateEdit={startUpdateEdit}
          deleteUpdate={deleteUpdate}
          inEditMode={inEditMode}
          editableUpdate={editableUpdate}
          cancelUpdateEdit={cancelUpdateEdit}
          clientEventTypes={clientEventTypes}
        />
      </ContentContainer>
    )
  }

  const TopNavigationWrapper = ({
    name,
    eventDate,
    liveStatus,
    liveLabel,
    liveTime,
    realTimeEventData,
    enableKeyMoments,
    enableGallery,
  }) => {
    // liveStatus = "PreMatch"
    // liveStatus = "Live"
    // liveStatus = "HalfTime"
    // liveStatus = "FullTime"
    return (
      <TopNavigation
        left={
          <EventInfoBanner
            className="top-nav-event-info-banner"
            name={name}
            date={eventDate}
            isLive={false}
            time={
              client.clientType !== "Generic" ? (
                <MatchTime
                  liveStatus={liveStatus}
                  liveLabel={liveLabel}
                  liveTime={liveTime}
                  realTimeEventData={realTimeEventData}
                />
              ) : null
            }
            hasTopPanelOpen={hasTopPanelOpen}
          />
        }
        right={
          <AccountIcons className="top-nav-account-icons">
            {!hasTopPanelOpen && enableKeyMoments && (
              <button type="button" onClick={showKeyMoments}>
                <span>Key Moments</span>
              </button>
            )}

            {!hasTopPanelOpen && enableGallery && (
              <button type="button" onClick={showGallery}>
                <span>Gallery</span>
              </button>
            )}

            {!hasTopPanelOpen && (
              <button type="button" onClick={showEditEvent}>
                <EditIcon />
              </button>
            )}

            {hasTopPanelOpen && (
              <button
                onClick={closeTopPanel}
                type="button"
                style={{ fontWeight: 500, color: "#0091EA" }}
              >
                <span>Close</span>
              </button>
            )}

            <button onClick={() => auth.signOut()}>
              <ProfileIcon />
            </button>
          </AccountIcons>
        }
        hasTopPanelOpen={hasTopPanelOpen}
      />
    )
  }

  const renderCollapsibleTopSection = () => {
    return (
      <CollapsibleContainer isCollapsed={!hasTopPanelOpen}>
        <GeneralContainer colour={DARK_BLUE}>
          <Container>
            <Row align="center">
              <Col style={{ paddingTop: "6em" }}>
                {isEditingEvent &&
                  renderEditEventForm(
                    event.data,
                    handleEditEventFormSubmit,
                    client
                  )}
                {isEditingKeyMoments && renderEditKeyMoments(keyMoments)}
                {isEditingGallery && renderEditGallery(gallery)}
                {isTempusImagePicker &&
                  renderTempusImagePicker(tempusImagePickerData)}
              </Col>
            </Row>
          </Container>
        </GeneralContainer>
      </CollapsibleContainer>
    )
  }

  const renderEditEventForm = (currentEvent, handleFormSubmit, client) => {
    return (
      <CreateEventForm
        onSubmit={(values) => handleFormSubmit(values)}
        initialValues={{
          // Because we get an augmented response back from the server for events
          // we need to make sure we're only editing the correct data,
          // or the server will throw errors if we send fields that aren't expected
          id: currentEvent.id,
          name: currentEvent.name,
          eventDate: currentEvent.eventDate,
          commentatorName: currentEvent.commentatorName,
          optaFixtureId: currentEvent.optaFixtureId,
          commentatorTwitterHandle: currentEvent.commentatorTwitterHandle,
          competition: currentEvent.competition,
          seasonId: currentEvent.seasonId,
          tempusExId: currentEvent.tempusExId,
          cultureCode: currentEvent.cultureCode,
          twitterHandle: currentEvent.twitterHandle,
          // websiteAddress: currentEvent.websiteAddress - this not in response but is in create form?
        }}
        editEventIsActive={isEditingEvent}
        seasonList={seasonList}
        isSimpleGenericForm={client.clientType === "Generic"}
        enableTempusId={client.enableTempusEx}
        languages={client.languages}
      />
    )
  }

  const renderEditKeyMoments = (keyMoments) => {
    return (
      <KeyMoments
        keyMoments={keyMoments}
        maximum={6}
        sendKeyMoment={actions.sendKeyMoment}
        deleteKeyMoment={actions.deleteKeyMoment}
        editKeyMoment={actions.editKeyMoment}
        eventId={event.data.id}
      />
    )
  }

  const renderEditGallery = (gallery) => {
    return (
      <Gallery
        gallery={gallery}
        sendGalleryImage={actions.sendGalleryImage}
        deleteGalleryImage={actions.deleteGalleryImage}
        eventId={event.data.id}
      />
    )
  }

  const renderTempusImagePicker = (tempusImagePickerData) => {
    if (tempusImagePickerData.isLoading) return <></>
    return (
      <TempusImagePicker
        eventId={event.data.id}
        matchId={tempusImagePickerData.data.matchId}
        time={tempusImagePickerData.data.time}
        tempusCard={tempusImagePickerData.data.tempusCard}
        tempusExApiKey={client.tempusExApiKey}
        tempusExApiBaseUrl={client.tempusExApiBaseUrl}
        createTempusCard={actions.createTempusCard}
        editTempusCard={actions.editTempusCard}
        closeTopPanel={closeTopPanel}
      />
    )
  }

  const getTime = async (eventId) => {
    const resp = await API.getCurrentTime(eventId)
    return resp.data.time
  }

  const onSidebarImageUpload = async (imageUrl) => {
    await actions.sendUpdate({
      imageUrl,
      eventId: id,
      matchTime: await getTime(id), // Probably better to be able to set this directly on the server
    })
  }

  const onSidebarInstantTweet = async (tweetUrl) => {
    await actions.sendUpdate({
      linkEmbedUrl: tweetUrl,
      eventId: id,
      matchTime: await getTime(id), // Probably better to be able to set this directly on the server
    })
  }

  const handleEditEventFormSubmit = (data) => {
    editEvent(data)
  }

  return (
    <>
      {!event.isLoading && renderCollapsibleTopSection()}
      {/** Temporary error solution until it's better handled from state */}
      {event.hasErrored && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "5em",
            flexDirection: "column",
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            There was an error loading this event:
          </div>
          <div>{extractValidationMessageFromApiError(event.errorMessage)}</div>
          <div style={{ paddingTop: "2em" }}>
            <a href="/">Back to dashboard</a>
          </div>
        </div>
      )}
      {!event.hasErrored && event.isLoading && <LoadingOverlay />}

      {!event.hasErrored && !event.isLoading && (
        <ContainerWithSideBar
          top={
            <TopNavigationWrapper
              {...event.data}
              enableKeyMoments={client.enableKeyMoments}
              enableGallery={client.enableGallery}
              realTimeEventData={realTimeEventData}
            />
          }
          menu={
            <SideBarMenu
              activeSection={activeSection}
              showSection={actions.showSection}
              enableImageSearch={client.enableGettyImages}
              enablePlayingSurface={client.enablePlayingSurface}
              enableVisualCommentaryCards={client.enableVisualCommentaryCards}
              enableTempusEx={client.enableTempusEx}
            />
          }
          activeSection={activeSection}
          sidebar={
            <Sidebar
              getImages={actions.getImages}
              setImageAssetId={actions.setImageAssetId}
              clearImageAssetId={actions.clearImageAssetId}
              setTwitterAssetUrl={actions.setTwitterAssetUrl}
              clearTwitterAssetUrl={actions.clearTwitterAssetUrl}
              selectedImageAssetId={selectedImageAssetId}
              selectedTwitterAssetUrl={selectedTwitterAssetUrl}
              onImageUpload={onSidebarImageUpload}
              images={images}
              activeSection={activeSection}
              setEditableUpdate={actions.setEditableUpdate}
              clearEditableUpdateImage={actions.clearEditableUpdateImage}
              setTwitterSidebarPanel={actions.setTwitterSidebarPanel}
              tweets={tweets}
              searchTweets={actions.searchTweets}
              onSidebarInstantTweet={onSidebarInstantTweet}
              getHandleTweets={actions.getHandleTweets}
              opponentTwitterHandle={event.data.opponentTwitterHandle}
              editEvent={actions.editEvent}
              eventId={event.data.id}
              officialTwitterHandle={
                event.data.twitterHandle || client.twitterHandle
              }
              showTempusImagePicker={showTempusImagePicker}
              getTempusCards={actions.getTempusCards}
              tempusExId={event.data.tempusExId}
              tempusExApiKey={client.tempusExApiKey}
              tempusExApiBaseUrl={client.tempusExApiBaseUrl}
            />
          }
          active={activeSection}
          onClose={() => actions.showSection("")}
        >
          {renderContent({
            eventId: event.data.id,
            updates,
            sendUpdate: actions.sendUpdate,
            sendEditUpdate: actions.sendEditUpdate,
            updatePending: false, // TODO: Hook up to the model
            startUpdateEdit: actions.startUpdateEdit,
            inEditMode,
            editableUpdate,
            deleteUpdate: actions.deleteUpdate,
            cancelUpdateEdit: actions.cancelUpdateEdit,
            clearImageAssetId: actions.clearImageAssetId,
            clearEditableUpdateImage: actions.clearEditableUpdateImage,
            selectedImageAssetId,
            client,
          })}
        </ContainerWithSideBar>
      )}
    </>
  )
}

const Editor = (props) => {
  return (
    <EditorWrapper>
      <MainEditor {...props} />
    </EditorWrapper>
  )
}

export default Editor

const ProgressBarContainer = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    padding: 0 0 50px 0;
    width: 80%;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    width: 600px;
  }
`
