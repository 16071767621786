import React, { useState } from "react"
import { Form } from "./Form"

export const Create = ({ sendKeyMoment, eventId, disabled }) => {
  const [loading, setLoading] = useState(false)
  return (
    <Form
      loading={loading}
      moment={{}}
      primaryButtonLabel="Add"
      disabled={disabled}
      onSubmit={async (values) => {
        setLoading(true)
        await sendKeyMoment({
          ...values,
          eventId,
        })
        setLoading(false)
      }}
    />
  )
}
