import React from "react"
import { BlankItem } from "./BlankItem"
import { UploadItem } from "./UploadItem"
import { Image } from "./Image"
import { ImageGrid, ImageGridItem } from "./ImageGrid"

const buildImageArray = (
  galleryImages,
  max,
  onImageChange,
  onImageDeleted,
  pond
) => {
  let blanksToAdd = max - galleryImages.length

  galleryImages.forEach((u) => {
    u.image.dateAddedObject = Date.parse(u.image.dateAdded)
  })

  galleryImages = galleryImages.sort((a, b) => {
    return a.image.dateAddedObject - b.image.dateAddedObject
  })

  const grid = galleryImages.map((u) => (
    <Image
      src={u.image.url}
      onImageDeleted={async () => await onImageDeleted(u.image.id)}
    />
  ))

  if (blanksToAdd > 0) {
    if (blanksToAdd >= 1) {
      grid.push(<UploadItem onImageChange={onImageChange} pond={pond} />)
      blanksToAdd--
    }

    if (blanksToAdd > 0) {
      for (let index = 0; index < blanksToAdd; index++) {
        grid.push(<BlankItem />)
      }
    }
  }

  return grid
}

export const Images = ({
  images,
  sendGalleryImage,
  deleteGalleryImage,
  eventId,
  pond,
}) => {
  const total = 9 // Can be configurable later if needed
  const onImageChange = async (url) => {
    await sendGalleryImage({
      eventId,
      url,
      caption: "",
    })
  }

  const onImageDeleted = async (id) => {
    await deleteGalleryImage(id)
  }

  const items = buildImageArray(
    images || [],
    total,
    onImageChange,
    onImageDeleted,
    pond
  )
  return (
    <ImageGrid>
      {items.map((u, i) => (
        <ImageGridItem key={i}>{u}</ImageGridItem>
      ))}
    </ImageGrid>
  )
}
