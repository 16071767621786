import React from "react"
import { Formik, Field } from "formik"
import { Container, Row, Col } from "react-grid-system"
import * as Yup from "yup"
import { InputField } from "../form-components/input-field/InputField"
import { DateField } from "../form-components/date-field/DateField"
import PropTypes from "prop-types"
import Button from "../form-components/button/button"
import { Wrapper } from "./styles"
import styled from "styled-components"
import { SelectField } from "../form-components/select-field/SelectField"

const defaultValues = {
  name: "",
  eventDate: "",
  commentatorName: "",
  optaFixtureId: "",
  commentatorTwitterHandle: "",
  websiteAddress: "",
  cultureCode: "",
  twitterHandle: "",
}

export const CreateEventForm = ({
  onSubmit,
  initialValues,
  editEventIsActive,
  seasonList,
  errorMessage,
  isSimpleGenericForm,
  enableTempusId,
  languages
}) => (
  <Formik
    enableReinitialize={true}
    initialValues={{ ...defaultValues, ...initialValues }}
    onSubmit={async (values, formActions) => {
      try {
        await onSubmit(values)
      } finally {
        formActions.setSubmitting(false)
      }
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().required("Please enter the name of the event"),
      eventDate: Yup.string().required(
        "Please enter the date and time of the event"
      ),
      optaFixtureId: Yup.number().min(0, "This Opta ID is not valid"),
    })}
  >
    {(props) => {
      const { isSubmitting, handleSubmit } = props
      return (
        <form onSubmit={handleSubmit}>
          <Wrapper>
            <Container fluid style={{ margin: "0 20px" }}>
              <Row style={{ marginBottom: "2em" }}>
                <Col>
                  <Field name="name">
                    {(fieldProps) => {
                      return (
                        <InputField
                          label="Event Name"
                          input={{ type: "text" }}
                          {...fieldProps}
                          animationDelay={100}
                          light={true}
                          required={true}
                        />
                      )
                    }}
                  </Field>
                </Col>
              </Row>
              <Row style={{ marginBottom: "2em" }}>
                <Col>
                  <Field name="eventDate">
                    {(fieldProps) => {
                      return (
                        <DateField
                          label="Date"
                          {...fieldProps}
                          animationDelay={200}
                          light={true}
                          required={true}
                        />
                      )
                    }}
                  </Field>
                </Col>

                {isSimpleGenericForm && (
                  <Col>
                    <Field name="twitterHandle">
                      {(fieldProps) => {
                        return (
                          <InputField
                            label="Twitter Handle for event"
                            input={{ type: "text" }}
                            {...fieldProps}
                            animationDelay={300}
                            light={true}
                          />
                        )
                      }}
                    </Field>
                  </Col>
                )}

                {!isSimpleGenericForm && (
                  <Col>
                    <Field name="optaFixtureId">
                      {(fieldProps) => {
                        return (
                          <InputField
                            label="Opta Id"
                            input={{ type: "number", min: 0 }}
                            {...fieldProps}
                            animationDelay={300}
                            light={true}
                          />
                        )
                      }}
                    </Field>
                  </Col>
                )}
              </Row>
              {!isSimpleGenericForm && (
                <React.Fragment>
                  <Row style={{ marginBottom: "2em" }}>
                    <Col>
                      <Field name="competition">
                        {(fieldProps) => {
                          return (
                            <InputField
                              label="Competition"
                              input={{ type: "text" }}
                              {...fieldProps}
                              animationDelay={400}
                              light={true}
                            />
                          )
                        }}
                      </Field>
                    </Col>
                    <Col>
                      <Field name="seasonId">
                        {(fieldProps) => {
                          return (
                            <SelectField
                              label="Season"
                              {...fieldProps}
                              animationDelay={500}
                              light={true}
                              options={
                                [
                                  { id: undefined, text: "" },
                                  ...(seasonList || []),
                                ] || []
                              }
                            />
                          )
                        }}
                      </Field>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "2em" }}>
                    {enableTempusId && (
                      <Col>
                        <Field name="tempusExId">
                          {(fieldProps) => {
                            return (
                              <InputField
                                label="TempusEx Match ID"
                                input={{ type: "text" }}
                                {...fieldProps}
                                animationDelay={400}
                                light={true}
                              />
                            )
                          }}
                        </Field>
                      </Col>
                    )}
                    {languages && languages.length > 0 && (
                      <Col>
                        <Field name="cultureCode">
                          {(fieldProps) => {
                            return (
                              <SelectField
                                label="Language"
                                {...fieldProps}
                                animationDelay={500}
                                light={true}
                                options={
                                  [
                                    { id: undefined, title: "" },
                                    ...(languages.map((language) => ({
                                      id: language.cultureCode,
                                      title: language.cultureCode,
                                    })) || []),
                                  ] || []
                                }
                              />
                            )
                          }}
                        </Field>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <FormSectionHeading>
                        Who's commentating?
                      </FormSectionHeading>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "2em" }}>
                    <Col>
                      <Field name="commentatorName">
                        {(fieldProps) => {
                          return (
                            <InputField
                              label="Name"
                              input={{ type: "text" }}
                              {...fieldProps}
                              animationDelay={600}
                              light={true}
                            />
                          )
                        }}
                      </Field>
                    </Col>
                    <Col>
                      <Field name="commentatorTwitterHandle">
                        {(fieldProps) => {
                          return (
                            <InputField
                              label="Twitter name"
                              input={{ type: "text", placeholder: "@" }}
                              {...fieldProps}
                              animationDelay={700}
                              light={true}
                            />
                          )
                        }}
                      </Field>
                    </Col>
                  </Row>
                </React.Fragment>
              )}

              {/* <Row style={{ marginBottom: "2em" }}>
                <Col>
                  <Field name="websiteAddress">
                    {fieldProps => {
                      return (
                        <InputField
                          label="Embed Destination"
                          input={{ type: "url", placeholder: "HTTPS://" }}
                          {...fieldProps}
                          animationDelay={600}
                          light={true}
                        />
                      )
                    }}
                  </Field>
                </Col>
              </Row> */}
              <Row
                style={{
                  marginBottom: "2em",
                  alignItems: "center",
                  paddingTop: "2em",
                }}
              >
                {errorMessage && (
                  <Col
                    style={{ color: "#FF4261", fontWeight: 500 }}
                    align="start"
                  >
                    {errorMessage}
                  </Col>
                )}
                <Col align="end" style={{}}>
                  <Button
                    buttonStyle="light"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    label={editEventIsActive ? "Update" : "Create"}
                  />
                </Col>
              </Row>
            </Container>
          </Wrapper>
        </form>
      )
    }}
  </Formik>
)

const FormSectionHeading = styled.div`
  font-size: 1.2em;
  color: ${(props) => props.theme.colors.white};
  font-weight: 300;
  margin-top: 3em;
  margin-bottom: 2em;
`
CreateEventForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  seasonList: PropTypes.array,
}

export default CreateEventForm
