import React from "react"
import styled from "styled-components"
import Button from "../form-components/button/button"

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`
class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  handleMoreClick = () => {
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }))
    this.props.handler(this.props.totalCount)
  }

  handleLessClick = () => {
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }))
    this.props.handler(this.props.maxCount)
  }

  render() {
    const { buttonStyle } = this.props
    const { isOpen } = this.state
    return (
      <LoadMore>
        {isOpen ? (
          <Button
            type="button"
            label="Hide"
            buttonStyle={buttonStyle}
            onClick={this.handleLessClick}
          />
        ) : (
          <Button
            type="button"
            label="View All"
            onClick={this.handleMoreClick}
            buttonStyle={buttonStyle}
          />
        )}
      </LoadMore>
    )
  }
}

export default Index
