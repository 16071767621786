import React, { useState } from "react"
import { Wrapper, Selectfield } from "./styles"
import styled from "styled-components"
import { getInputWrapBorderBottom } from "../../../styles/core/forms"
import { ReactComponent as Down } from "../../../static/icons/down-arrow.svg"

// We use a border wrapper to prevent jarring when the border size
// changes on focus
const BorderWrapper = styled.div`
  ${(props) => getInputWrapBorderBottom(props)}
`
/**
 * This select is styled to work with the styling of our forms.
 * Use filter-select for standalone uses, like filters
 */
const Select = (props) => {
  const {
    options,
    handleChange,
    value,
    fluid,
    light,
    isError,
    onFocus,
    onBlur,
  } = props
  const [focus, setFocus] = useState()

  // Not to future dev:
  // -------------------
  // I copied the existing select field we had (now called filter-select), so that I could restyle it in
  // isolation for use in the main forms. I've wired it up, but as my use case doesn't need validation,
  // and time is tight, I've not yet tested the validation states are wired up. If it doesn't work
  // and you're scratching your head, referenc <InputField /> and <TextAreaField />. The patterns for
  // how fields are wired up are well established in those components.

  return (
    <Wrapper light={light}>
      <BorderWrapper focus={focus} isError={isError}>
        <Selectfield
          light={light}
          value={value}
          onChange={handleChange}
          fluid={fluid}
          onFocus={(e) => {
            setFocus(true)
            onFocus && onFocus(e)
          }}
          onBlur={(e) => {
            setFocus(false)
            onBlur && onBlur(e)
          }}
        >
          <Down />
          {options.map((option, i) => (
            <option key={i} value={option.id}>
              {option.title}
            </option>
          ))}
        </Selectfield>
      </BorderWrapper>
    </Wrapper>
  )
}

export default Select
