import React from "react"
import { ImageGridItem, ImageGrid } from "./ImageGrid"
import { ImageSearchResult } from "./ImageSearchResult"
import { PrevButton, NextButton } from "./PageButton"
import styled from "styled-components"
import { API } from "../../api"

const PageButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
`
export const ImageSearchResults = ({
  images,
  hasNextPage,
  hasPrevPage,
  onNextPage,
  onPrevPage,
  sendGalleryImage,
  eventId,
  onFinishSearching,
}) => {
  if (images.length === 0) {
    return <div>No images found</div>
  }

  const onSelectImage = async (image) => {
    console.log("Starting image download")
    var response = await API.downloadImage(image.assetId)
    console.log("res", response)
    await sendGalleryImage({
      eventId,
      url: response.data.imageUrl,
      caption: "",
    })
    onFinishSearching()
  }

  return (
    <div>
      <ImageGrid>
        {images.map((image, i) => {
          return (
            <ImageGridItem key={i}>
              <ImageSearchResult
                src={image.url}
                onSelectImage={async () => await onSelectImage(image)}
              />
            </ImageGridItem>
          )
        })}
      </ImageGrid>
      <PageButtonWrap>
        {hasPrevPage ? (
          <PrevButton onClick={onPrevPage} />
        ) : (
          <span>{/* Keep next button flexing correct */}</span>
        )}
        {hasNextPage && <NextButton onClick={onNextPage} />}
      </PageButtonWrap>
    </div>
  )
}
