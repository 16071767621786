import axios from "axios"
import { livelyEndPoint } from "./config"
import authClient from "./Auth"

function getAccessToken() {
  return authClient.getAccessToken()
}

function _buildParams(queryParams) {
  const accessToken = getAccessToken()
  return {
    params: queryParams || {},
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  }
}

function _addContentType(contentType) {
  const accessToken = getAccessToken()
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    },
  }
}

function _addAuthorisation() {
  const accessToken = getAccessToken()
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }
}

const get = (endpoint, queryParams) =>
  axios.get(`${livelyEndPoint}/${endpoint}`, _buildParams(queryParams))

/**
 *
 * @param {string} endpoint
 * @param {object} payload
 * @param {function} mutateParams Callback allowing you to modify the request options before the request is made
 */
const post = (endpoint, payload, mutateParams) =>
  axios.post(
    `${livelyEndPoint}/${endpoint}`,
    payload,
    mutateParams ? mutateParams(_buildParams()) : _buildParams()
  )

const put = (endpoint, payload) =>
  axios.put(`${livelyEndPoint}/${endpoint}`, payload, _addAuthorisation())

const patch = (endpoint, payload) =>
  axios.patch(
    `${livelyEndPoint}/${endpoint}`,
    payload,
    _addContentType("application/merge-patch+json")
  )
const del = (endpoint) =>
  axios.delete(`${livelyEndPoint}/${endpoint}`, _buildParams())

/*
  API INFO
  Swagger docs here: https://livelyv2-api-staging.azurewebsites.net/index.html

    - getClient - Get a client. API will return client date based on association to passed Bearer token for current
     logged in user
      query params:
        None. Works in rest ful fashion: /event/{eventId}

    - getPastEvents - get a list of events in the past
        query params:
          page (int):         Page number required. Defaults to 1 in API (not zero indexed). For future pagination.
          pageSize (int):     defaults to 10 in API if no option passed in
          seasonID (string):  ID of season required.

    - getUpcomingEvents - get a list of upcoming events
        query params:
          page (int):         Page number required. Defaults to 1 in API (not zero indexed). For future pagination.
          pageSize (int):     defaults to 10 in API if no option passed in
          seasonID (string):  ID of season required.

    - getEvent - Get a single event based on passed ID
        query params:
          None. Works in rest ful fashion: /event/{eventId}

 */

export const API = {
  getClient: () => get("client"),
  getConfig: () => axios.get(`${livelyEndPoint}/authConfig`),
  getClientEventTypes: () => get("client/eventtypes"),

  //events
  getPastEvents: (queryParams) => get("client/events/past", queryParams),
  getUpcomingEvents: (queryParams) =>
    get("client/events/upcoming", queryParams),
  getEvent: (eventId) => get(`event/${eventId}`),
  getSeasonList: () => get(`season/list`),
  createEvent: (payload) => post(`event`, payload),
  editEvent: (payload) => patch(`event/${payload.id}`, payload),
  getCurrentTime: (eventId) => get(`event/${eventId}/time`),

  //editor page
  getStatusUpdates: (eventId, pageSize) =>
    get(`event/${eventId}/comments?pageSize=${pageSize}`),
  getImages: (query = "", page = 1, pageSize = 10) =>
    get(`image/search?searchTerm=${query}&page=${page}&pageSize=${pageSize}`),
  downloadImage: (assetId) => get(`image/download?assetId=${assetId}`),

  //key Moments
  getKeyMoments: (eventId) => get(`event/${eventId}/keymoments`),
  sendKeyMoment: (payload) => post(`keymoment`, payload),
  editKeyMoment: (id, payload) => put(`keymoment/${id}`, payload),
  deleteKeyMoment: (id) => del(`keymoment/${id}`),

  //Gallery
  getGallery: (eventId) => get(`event/${eventId}/gallery`),
  sendGalleryImage: (payload) => post(`GalleryImage`, payload),
  deleteGalleryImage: (id) => del(`GalleryImage/${id}`),

  //comments
  searchTweets: (searchTerm) =>
    get(`comment/twitter/search?searchTerm=${searchTerm}`),
  getHandleTweets: (handle) => get(`comment/twitter/timeline?handle=${handle}`),
  sendUpdate: (payload) => post("comment", payload),
  deleteUpdate: (id) => del(`comment/${id}`),
  sendEditUpdate: (payload) => patch(`comment/${payload.id}`, payload),
  loadEmbedPreview: (embedUrl) =>
    get(`comment/embed?resourceUrl=${embedUrl}&iframe=true&omitScript=true`),
  uploadImage: (file, onUploadProgress) => {
    const formData = new FormData()
    formData.append("file", file)
    return post("comment/image/upload", formData, (params) => ({
      headers: {
        ...params.headers,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      onUploadProgress,
    }))
  },

  //tempus events
  getTempusCards: (eventId) => get(`event/${eventId}/tempus-cards`),
  createTempusCard: (payload) => post("tempuscard", payload),
  editTempusCard: (payload) => put(`tempuscard`, payload),

  //TODO
  // GET
  // /comment/embed
  // POST
  // /comment/image/upload
  // GET
  // /comment/twitter/search
}

export const extractValidationMessageFromApiError = (error) => {
  if (error && error.response && error.response.data) {
    if (error.response.data.validationError) {
      return error.response.data.validationError
    }
    if (error.response.data.error) {
      return error.response.data.error
    }
  }

  return "An unexpected error occured whilst making your request. Please try again soon."
}
