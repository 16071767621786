import React from "react"
import styled, { css } from "styled-components"

const Button = styled.button`
  border-radius: 120px;
  height: 40px;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  padding: 0 1.5em;
  font-size: 14px;
  line-height: 1;
  margin: 0 1em 1em 0;
  :focus {
    outline: none;
  }
  &.is-display-only {
    pointer-events: none;
  }

  ${(props) =>
    props.selected &&
    css`
      border-color: ${(props) => props.theme.colors.fieldFocusHighlight};
      border-width: 2px;
    `}
`

const Lozenge = ({ children, onClick, selected, isDisplayOnly }) => {
  return (
    <>
      {isDisplayOnly && (
        <Button className={"is-display-only"}>{children}</Button>
      )}
      {!isDisplayOnly && (
        <Button
          selected={selected}
          onClick={(e) => {
            onClick(e)
          }}
        >
          {children}
        </Button>
      )}
    </>
  )
}

export default Lozenge
