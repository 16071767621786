import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&display=swap');
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  a,
  div {
    font-family: 'Roboto', sans-serif;
  }
  
  h1 {
    font-weight: 300;
    padding-bottom: 30px;
  }
  
  h2,
  h3 {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    padding-bottom: 10px;
  }
  
  li {
    font-weight: 100;
  }
  
  /*
    Margins & Padding 
   */
   h2, h3 {
    padding: 10px 0;
   }
`
