import React, { useEffect, useState } from "react"
import { Route, withRouter, Redirect } from "react-router-dom"
import "./App.css"
import SecureHome from "./SecureHome"
import auth0Client from "./Auth"
import SecureRoute from "./SecuredRoute/SecuredRoute"
import Callback from "./Callback"
import Events from "./pages/events"
import Editor from "./pages/editor"
import CreateEvent from "./pages/createEvent"

window.rg4js("withCustomData", { environment: process.env.NODE_ENV })

function App(props) {
  useEffect(() => {
    async function init() {
      if (props.location.pathname === "/callback") {
        setCheckingsession(false)
        return
      }

      try {
        await auth0Client.silentAuth()
        this.forceUpdate()
      } catch (err) {
        if (err.error !== "login_required") {
          console.log(err.error)
        }
      }
      setCheckingsession(false)
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [checkingSession, setCheckingsession] = useState(true)

  return (
    <div className="App">
      <Route exact path="/" render={() => <Redirect to="/events" />} />
      <SecureRoute
        exact
        path="/create-event"
        component={CreateEvent}
        checkingSession={checkingSession}
      />
      <SecureRoute
        exact
        path="/events"
        component={Events}
        checkingSession={checkingSession}
      />
      <SecureRoute
        exact
        path="/editor/:id"
        component={Editor}
        checkingSession={checkingSession}
      />
      <Route exact path="/callback" component={Callback} />
      <SecureRoute exact path="/secure" component={SecureHome} />
    </div>
  )
}

export default withRouter(App)
