import React from "react"
import styled, { css } from "styled-components"

export const StatusUpdateContainer = styled.div`
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    padding-top: 20px;
    width: 80%;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    width: 600px;
  }
`

export const FormInnerWrap = styled.div`
  position: relative;
`
export const FormContent = styled.div`
  width: 100%;
`

export const FormContainer = styled.div`
  display: flex;
  position: relative;
`

export const FormFields = styled.div`
  width: 100%;
`

export const UpdateTabContent = ({ children }) => {
  return <FormContent>{children}</FormContent>
}

export const TabIcons = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
`

export const TabIcon = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: auto;
  margin-right: 1em;

  ${(props) =>
    props.hide &&
    css`
      width: 0;
      margin-right: 0;
    `}

  ${(props) =>
    !props.active &&
    css`
      cursor: pointer;
      &:hover {
        background-color: #ebebeb;
      }
    `}

  transition: background-color 200ms ease-in-out, width 200ms ease-in-out,
    margin-right 200ms ease-in-out;

  border-bottom: 3px solid ${(props) => props.theme.colors.white};
  ${(props) =>
    props.active &&
    `
    border-bottom: 3px solid ${props.theme.colors.lightBlue};
  `}
`

export const TabContainerReveal = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms ease-in-out;
  ${({ active }) =>
    active &&
    css`
      max-height: 2000px;
    `}
`
export const TabContainer = styled.div`
  padding: 10px 0 60px;
`
