import React from "react"
import PropTypes from "prop-types"
import { LoadingOverlay } from "../loading-overlay/LoadingOverlay"
import { TwitterCardControls } from "./TwitterCardControls"
import { TwitterCard } from "./TwitterCard"

class Component extends React.Component {
  componentDidMount() {
    const { handle } = this.props
    if (!handle) {
      console.error("Component was loaded before handle was set")
    }

    if (!this.props.handleTweets.results) {
      // only load on mount if this handle doesn't already have tweets loaded
      this.updateTweets()
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.handle !== this.props.handle) {
      this.updateTweets()
    }
  }

  updateTweets = () => {
    const { handle, getHandleTweets, uiKey } = this.props
    getHandleTweets(uiKey, handle)
  }

  render() {
    const {
      handleTweets,
      editPost,
      instantPost,
      selectedTwitterAssetUrl,
      doClearTwitterAssetUrl,
    } = this.props

    return (
      <div style={{ opacity: handleTweets.isLoading ? 0.5 : 1 }}>
        {handleTweets.isLoading && <LoadingOverlay opacity={0} />}
        {handleTweets.results &&
          handleTweets.results.map((tweet, i) => {
            const selected = selectedTwitterAssetUrl === tweet.url
            return (
              <TwitterCardControls
                editPost={editPost}
                instantPost={instantPost}
                url={tweet.url}
                key={i}
                selected={selected}
                doClearTwitterAssetUrl={doClearTwitterAssetUrl}
              >
                <TwitterCard
                  name={tweet.user.name}
                  screenName={tweet.user.screenName}
                  profileImage={tweet.user.profileImageUrl}
                  favouriteCount={tweet.favoriteCount}
                  created={tweet.createdAt}
                  text={tweet.text}
                  url={tweet.url}
                  selected={selected}
                />
              </TwitterCardControls>
            )
          })}
      </div>
    )
  }
}

Component.propTypes = {
  handle: PropTypes.string,
  gettingHandleTweets: PropTypes.bool,
  handleTweets: PropTypes.object,
  editPost: PropTypes.func.isRequired,
  instantPost: PropTypes.func.isRequired,
  uiKey: PropTypes.string.isRequired, // Required to keep isolation between panels and their own loaded tweets
  selectedTwitterAssetUrl: PropTypes.string,
  doClearTwitterAssetUrl: PropTypes.func.isRequired,
}

export const TweetsForHandle = Component
