import React from "react"
import styled, { css } from "styled-components"
import { ReactComponent as Refresh } from "../../static/icons/refresh.svg"

export const SidebarRefresh = ({ onClick, isActive }) => {
  return (
    <StickyRefresh onClick={onClick}>
      <ButtonInner isActive={isActive}>
        <Refresh />
      </ButtonInner>
    </StickyRefresh>
  )
}

const ButtonInner = styled.div`
  @keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
  will-change: transform;
  width: 20px;
  height: 20px;
  transform: translate3d(-50%, -50%, 0);
  transform-origin: center;
  top: 50%;
  ${(props) =>
    props.isActive
      ? css`
          animation: 2s linear infinite spinner;
        `
      : undefined};
`

const StickyRefresh = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.background};
  z-index: 666666;
  border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 284px;
  padding-top: 20px;
  cursor: pointer;
`
