import React, { useState } from "react"
import { Formik, Field } from "formik"
import PropTypes from "prop-types"
import UpdateFormFooter from "../update-form-footer/UpdateFormFooter"
import { LivelyFade } from "../lively-fade/LivelyFade"
import { API } from "../../api"
import {
  FormContainer,
  FormFields,
  FormInnerWrap,
} from "../status-update/styles"
import { MatchTimeWrap } from "../../styles/core/forms"
import { InputField } from "../form-components/input-field/InputField"
import { TextAreaField } from "../form-components/text-area-field/TextAreaField"
import { MinuteField } from "../form-components/minute-field/MinuteField"
import {
  SharedEventTypeListTrigger,
  SharedEventTypeField,
} from "../form-components/event-types"

const defaultValues = {
  title: "",
  content: "",
  matchTime: "",
  icon: "",
}

/**
 *
 *
 * NOTE: There is currently an issue when the form is reinitialised. Dirty state is reset.
 * This causes a prolbem if you currently have data in the form, but then select an item
 * from the sidebar (i.e a twitter post). The item will be injected into the form, however
 * the form dirty state will go back to false, and the delet button will be greyed out.
 *
 * This is currently considered an edge case issue, but will likely need a fix once MVP is out.
 *
 */

export const BaseUpdateForm = ({
  onSubmit,
  initialValues,
  eventId,
  shouldInitialiseWithTime,
  clientEventTypes,
  clientType,

  // Form life cycle callbacks
  onSubmitComplete,
  onCancelEdit,
  onCancelNew,

  canSubmitValidator,
  validationSchema,

  // Extra fields
  extraFields, // Use this function to render extra fields into the base form (i.e image, video, link, etc)
  extraFieldsDefaultValues, // use this object to set default values for the fields that you are rendering
}) => {
  const [lastIsDirty, setLastIsDirty] = useState()
  const [loadingTime, setLoadingTime] = useState()
  const [openEventTypes, setOpenEventTypes] = useState()
  const [
    hasAlreadyPopulatedTimeFieldOnInit,
    setHasAlreadyPopulatedTimeFieldOnInit,
  ] = useState(false)

  const resetForm = (formikActions) => {
    setOpenEventTypes(false)
    setHasAlreadyPopulatedTimeFieldOnInit(false)
    formikActions.resetForm()
    formikActions.setStatus({ isResetting: true })
    setTimeout(() => {
      // Need a little delay to allow the form to reset
      // before closing the event types. This is because
      // we trigger the open on render, if values.icon is
      // set, and we need time for it to be cleared.
      setOpenEventTypes(false)

      // I think we can also reset the isResetting flag here as the renders that require it would have run their course.

      formikActions.setStatus({ isResetting: false })
    }, 200)
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...defaultValues,
        ...extraFieldsDefaultValues,
        ...initialValues,
      }}
      onSubmit={async (values, formikActions) => {
        try {
          formikActions.setSubmitting(true)

          console.log("PAVEL SUBMIT TEST:", values)

          await onSubmit(values)
          onSubmitComplete()
          formikActions.setStatus({ success: true, isResetting: false })
          resetForm(formikActions)
        } catch (error) {
          formikActions.setStatus({ success: false })
        } finally {
          formikActions.setSubmitting(false)
        }
      }}
      validationSchema={validationSchema}
    >
      {(props) => {
        const { isSubmitting, handleSubmit, values, dirty } = props
        // Helper to populate the time field from the server
        const populateTimeField = async () => {
          if (!values.matchTime) {
            setLoadingTime(true)
            const response = await API.getCurrentTime(eventId)
            setLoadingTime(false)
            props.setFieldValue("matchTime", response.data.time)
          }
        }

        const updateContentFieldValue = (value) => {
          props.setFieldValue("content", value)
        }

        const isEditing =
          initialValues !== undefined && initialValues.id !== undefined

        if (lastIsDirty !== dirty) {
          setLastIsDirty(dirty)
        }

        if (shouldInitialiseWithTime && !hasAlreadyPopulatedTimeFieldOnInit) {
          setHasAlreadyPopulatedTimeFieldOnInit(true)
          populateTimeField()
        }

        if (values.icon) {
          // If an icon is set then the list of event types should always be open
          setOpenEventTypes(true)
        }

        return (
          <form onSubmit={handleSubmit}>
            {clientType !== "Generic" && (
              <SharedEventTypeListTrigger
                active={openEventTypes}
                setActive={setOpenEventTypes}
                currentValue={values.icon}
                clientType={clientType}
              />
            )}
            <FormInnerWrap>
              <Field name="icon">
                {(fieldProps) => {
                  return (
                    <SharedEventTypeField
                      active={openEventTypes}
                      clientEventTypes={clientEventTypes}
                      onChange={(key) => {
                        props.setFieldValue("icon", key)
                      }}
                      value={fieldProps.field.value}
                    />
                  )
                }}
              </Field>
              <FormContainer>
                <MatchTimeWrap loading={loadingTime}>
                  <Field name="matchTime">
                    {(fieldProps) => {
                      return (
                        <MinuteField
                          input={{
                            type: "text",
                            placeholder: "MM`",
                            onFocus: () => {
                              // if the user focuses on the time field, stop the loading indicator
                              setLoadingTime(false)
                            },
                          }}
                          {...fieldProps}
                          animationDelay={200}
                        />
                      )
                    }}
                  </Field>
                </MatchTimeWrap>
                <FormFields>
                  {extraFields && extraFields(props, populateTimeField)}
                  <Field name="title">
                    {(fieldProps) => {
                      return (
                        <InputField
                          input={{
                            type: "text",
                            placeholder: "Title",
                            onFocus: populateTimeField,
                          }}
                          {...fieldProps}
                          field={{
                            ...fieldProps.field,
                            onChange: (e) => {
                              fieldProps.field.onChange(e)
                            },
                          }}
                          animationDelay={200}
                        />
                      )
                    }}
                  </Field>

                  <Field name="content">
                    {(fieldProps) => {
                      return (
                        <TextAreaField
                          input={{
                            type: "text",
                            placeholder: "Comment",
                            onFocus: populateTimeField,
                            onChange: updateContentFieldValue,
                          }}
                          {...fieldProps}
                          isEditing={isEditing}
                          animationDelay={300}
                        />
                      )
                    }}
                  </Field>

                  <LivelyFade delay={400}>
                    <UpdateFormFooter
                      isSubmitting={isSubmitting}
                      isEditing={isEditing}
                      dirty={props.dirty}
                      onCancelEditing={() => {
                        resetForm(props)
                        onCancelEdit()
                      }}
                      onCancelNewPost={() => {
                        resetForm(props)
                        onCancelNew()
                      }}
                      canSubmit={
                        canSubmitValidator === undefined ||
                        canSubmitValidator(values)
                      }
                    />
                  </LivelyFade>
                </FormFields>
              </FormContainer>
            </FormInnerWrap>
          </form>
        )
      }}
    </Formik>
  )
}

export const sharedBaseUpdateFormProps = {
  onSubmit: PropTypes.func.isRequired,

  onSubmitComplete: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onCancelNew: PropTypes.func.isRequired,
}

BaseUpdateForm.propTypes = {
  ...sharedBaseUpdateFormProps,
  extraFields: PropTypes.func,
  extraFieldsDefaultValues: PropTypes.object,
  canSubmitValidator: PropTypes.func,
}

export default BaseUpdateForm
