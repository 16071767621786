import styled, { css } from "styled-components"
export const getInputBorderBottom = (props) => {
  if (props.isError) {
    return css`
      border-bottom: 1px solid ${props.theme.colors.red};
    `
  } else {
    return css`
      border-bottom: 1px solid ${props.theme.colors.grey};
    `
  }
}

export const getFocusBorderBottom = (props) => {
  if (props.isError) {
    return css`
      border-bottom: 1px solid ${props.theme.colors.red};
    `
  } else {
    return css`
      border-bottom: 1px solid ${props.theme.colors.fieldFocusHighlight};
    `
  }
}
export const getInputWrapBorderBottom = (props) => {
  if (props.isError) {
    return css`
      border-bottom: 1px solid ${props.theme.colors.red};
    `
  } else if (props.focus) {
    return css`
      border-bottom: 1px solid ${props.theme.colors.fieldFocusHighlight};
    `
  } else {
    return css`
      border-bottom: 1px solid transparent;
    `
  }
}

export const getPlaceholderColor = (props) => {
  if (props.light) {
    return css`
      color: ${props.theme.colors.formPlaceholderLight};
    `
  } else {
    return css`
      color: ${(props) => props.theme.colors.formPlaceholderDark};
    `
  }
}

export const validStringValue = (value) => {
  return value !== undefined && value !== ""
}

export const MatchTimeWrap = styled.div`
  @-webkit-keyframes pulse {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }

  ${(props) =>
    props.loading &&
    css`
      animation: pulse 600ms infinite ease-in-out;
    `}
`

export const EventTypeDropdownWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadein {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: 500ms ease 0s normal forwards 1 fadein;
`
