import styled from "styled-components"

export const Banner = styled.div`
  margin: 60px 0 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LogoWrapper = styled.div`
  width: 100px;
  height: 100px;
`

export const Logo = styled.img`
  max-height: 100%;
`

export const StadionLogo = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 100px;
`
