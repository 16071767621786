import React from "react"
import PropTypes from "prop-types"
import { SetHandle } from "./SetHandle"
import { TweetsForHandle } from "./TweetsForHandle"
import { SidebarRefresh } from "../sidebar-refresh/SidebarRefresh"
import { SidebarMessage } from "../sidebar/SidebarMessage"

const Component = (props) => {
  const {
    handle,
    setHandle,
    settingHandle,
    getHandleTweets,
    handleTweets,
    instantPost,
    editPost,
    uiKey,
    selectedTwitterAssetUrl,
    doClearTwitterAssetUrl,
  } = props

  return (
    <div style={{ position: "relative" }}>
      {
        /** If a callback to set the handle is provided, then we allow setting the handle. Otherwise, we use whatever has been sent through. */
        setHandle && (
          <SetHandle
            handle={handle}
            setHandle={setHandle}
            isLoading={settingHandle}
          />
        )
      }

      {handle && (
        <TweetsForHandle
          handle={handle}
          getHandleTweets={getHandleTweets}
          handleTweets={handleTweets}
          instantPost={instantPost}
          editPost={editPost}
          uiKey={uiKey}
          selectedTwitterAssetUrl={selectedTwitterAssetUrl}
          doClearTwitterAssetUrl={doClearTwitterAssetUrl}
        />
      )}
      {!handle && !setHandle && (
        <SidebarMessage
          text="Your official Twitter handle has not yet been set, please ask support
        for help."
        />
      )}

      {handleTweets.error && <SidebarMessage text={handleTweets.error} />}
      {handleTweets.results && handleTweets.results.length > 0 && (
        <SidebarRefresh
          isActive={handleTweets.isLoading}
          onClick={() => getHandleTweets(uiKey, handle)}
        />
      )}
      {/** hasSearched is to make sure we don't show this before any search has happened */}
      {handleTweets.hasSearched &&
        handleTweets.results &&
        handleTweets.results.length === 0 && (
          <SidebarMessage
            title="Nothing to see here."
            text="Try searching or refreshing the feed."
          />
        )}
    </div>
  )
}

Component.propTypes = {
  handle: PropTypes.string,
  setHandle: PropTypes.func,
  settingHandle: PropTypes.bool,
  getHandleTweets: PropTypes.func.isRequired,
  handleTweets: PropTypes.object,
  editPost: PropTypes.func.isRequired,
  instantPost: PropTypes.func.isRequired,
  uiKey: PropTypes.string.isRequired,
  selectedTwitterAssetUrl: PropTypes.string,
  doClearTwitterAssetUrl: PropTypes.func.isRequired,
}

export const SidebarTwitterHandle = Component
