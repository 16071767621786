import React from "react"
import { Field } from "formik"
import { FormField } from "../form-components/form-field/FormField"
import { validStringValue } from "../../styles/core/forms"
import BaseUpdateForm from "../status-update/BaseUpdateForm"
import * as Yup from "yup"
import { ImageUploader } from "../image-uploader/ImageUploader"

export const ImageUpdate = ({ onDeleteLinkEmbed, ...props }) => {
  const fieldName = "imageUrl"
  return (
    <BaseUpdateForm
      {...props}
      canSubmitValidator={(values) => {
        return validStringValue(values[fieldName])
      }}
      extraFieldsDefaultValues={{ [fieldName]: "" }}
      extraFields={(formProps, populateTimeField) => (
        <Field name={fieldName}>
          {({ field, form }) => {
            return (
              <FormField
                name={field.name}
                animationDelay={100}
                touched={
                  form.touched[field.name] === true || form.submitCount > 0
                }
                errorMessage={form.errors[field.name]}
              >
                <ImageUploader
                  imageUrl={field.value}
                  onaddfilestart={populateTimeField}
                  onImageChange={(imageUrl, width, height) => {
                    formProps.setFieldValue(fieldName, imageUrl)
                    formProps.setFieldValue("imageWidth", width)
                    formProps.setFieldValue("imageHeight", height)
                  }}
                />
              </FormField>
            )
          }}
        </Field>
      )}
      validationSchema={Yup.object().shape({
        // Wait till end to run through what validations are required.
        imageUrl: Yup.string().required("An image is required for this update"),
      })}
    />
  )
}
