import React, { useState } from "react"
import styled from "styled-components"
import {
  Container,
  StatusContainer,
  Minute,
  EventType,
  Content,
  CommentElem,
  TitleElem,
  IconContainer,
  Right,
  CancelButton,
} from "./styles"
import { ReactComponent as EditIcon } from "../../static/edit.svg"
import { ReactComponent as BinIcon } from "../../static/bin.svg"
import { ReactComponent as Tick } from "../../static/icons/tick.svg"
import { ReactComponent as Remove } from "../../static/icons/remove.svg"
import LazyLoad from "react-lazyload"
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner"
import animateScrollTo from "animated-scroll-to"
import { renderGlobalIframelyWidgets } from "../../utils"
import Lozenge from "../lozenge/Lozenge"

const UpdateImage = styled.img`
  width: 100%;
  margin-bottom: 1em;
`

const UpdateActions = styled.div`
  padding-right: 10px;
`

const NegativeActionText = styled.span`
  color: ${(props) => props.theme.colors.red};
`

const ActionContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: -8px;

  button {
    height: 48px;
    width: 48px;
  }
`

const getIconLabel = function (clientEventTypes, icon) {
  const matchedIcon = clientEventTypes.filter((ev) => ev.key === icon)
  if (matchedIcon.length) {
    return matchedIcon[0].label
  }
  return icon
}

const FeedItem = ({
  id,
  icon,
  title,
  content,
  imageUrl,
  matchTime,
  linkEmbed,
  videoEmbed,
  startUpdateEdit,
  deleteUpdate,
  editableUpdate,
  cancelUpdateEdit,
  clientEventTypes,
}) => {
  const [deleting, setDeleting] = useState()
  const [deleteMode, setDeleteMode] = useState()
  const editing = editableUpdate && editableUpdate.id === id

  if (linkEmbed || videoEmbed) {
    setTimeout(() => {
      // Need to set a timeout to render embed content to make sure
      // the react render is complete, otherwise the widgets won't yet
      // be in the DOM for these global scripts to access.
      renderGlobalIframelyWidgets()
    }, 800)
  }

  return (
    <LazyLoad once>
      <Container deleting={deleting} deleteMode={deleteMode} editing={editing}>
        <StatusContainer>
          <Minute>{matchTime}</Minute>
          <Content>
            {imageUrl && (
              <UpdateImage
                key={imageUrl}
                alt={title}
                src={`${imageUrl}?w=1600`}
              />
            )}

            {linkEmbed && linkEmbed.htmlNoScript && (
              <div
                style={{ marginBottom: "1em" }}
                dangerouslySetInnerHTML={{ __html: linkEmbed.htmlNoScript }}
              />
            )}

            {/** In the event both link and video embed is set, link trumps video */}
            {!linkEmbed && videoEmbed && videoEmbed.htmlNoScript && (
              <div
                style={{ marginBottom: "1em" }}
                dangerouslySetInnerHTML={{ __html: videoEmbed.htmlNoScript }}
              />
            )}
            {title && <TitleElem>{title}</TitleElem>}
            {content && (
              <CommentElem dangerouslySetInnerHTML={{ __html: content }} />
            )}
          </Content>
        </StatusContainer>
        <UpdateActions>
          <IconContainer>
            <Right>
              {icon && !deleteMode && (
                <EventType>
                  <Lozenge isDisplayOnly={true}>
                    <strong>{getIconLabel(clientEventTypes, icon)}</strong>
                  </Lozenge>
                </EventType>
              )}
              <ActionContainer>
                {!deleting && editing && (
                  <CancelButton onClick={() => cancelUpdateEdit()}>
                    Cancel editing
                  </CancelButton>
                )}
                {!deleting && !editing && !deleteMode && (
                  <>
                    <button
                      onClick={() => {
                        animateScrollTo(20)
                        startUpdateEdit(id)
                      }}
                    >
                      <EditIcon />
                    </button>
                    <button onClick={() => setDeleteMode(true)}>
                      <BinIcon />
                    </button>
                  </>
                )}

                {!deleting && deleteMode && (
                  <>
                    <NegativeActionText>Are you sure?</NegativeActionText>
                    <button
                      onClick={async () => {
                        setDeleteMode(false)
                        setDeleting(true)
                        await deleteUpdate(id)
                        setDeleting(false)
                      }}
                    >
                      <Tick />
                    </button>{" "}
                    <button onClick={() => setDeleteMode(false)}>
                      <Remove />
                    </button>
                  </>
                )}
                {deleting && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <LoadingSpinner
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginRight: "0.5em",
                      }}
                      dark
                      size="small"
                    />
                    <span style={{ fontSize: "0.9em", opacity: 0.6 }}>
                      Deleting&hellip;
                    </span>
                  </div>
                )}
              </ActionContainer>
            </Right>
          </IconContainer>
        </UpdateActions>
      </Container>
    </LazyLoad>
  )
}

export default FeedItem
