import React, { useState } from "react"

import PropTypes from "prop-types"
import { Option } from "./Option"
import { ReactComponent as Upload } from "../../static/icons/upload.svg"
import styled from "styled-components"
import { FilePond, registerPlugin } from "react-filepond"
import { API } from "../../api"
import FilePondPluginImageResize from "filepond-plugin-image-resize"
import FilePondPluginImageTransform from "filepond-plugin-image-transform"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"

registerPlugin(
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateType
)

const Wrapper = styled.div`
  display: flex;
`

export const Choose = ({ style, onImageChange, onaddfilestart }) => {
  let pond
  const [uploading, setUploading] = useState(false)
  const [percent, setPercent] = useState(0)

  const onUploadClick = () => {
    pond.browse()
  }

  // const onWebAddressClick = () => {
  //   alert("TODO")
  //   // WIP is in this component - <WebAddress />
  // }

  return (
    <div style={style}>
      <div>
        {uploading && <div>Uploading: {Math.ceil(percent * 100)}%</div>}
        {!uploading && (
          <Wrapper>
            {/* <Option
              title="By web address"
              icon={<Link />}
              onClick={onWebAddressClick}
            />
            <Divider /> */}
            <Option
              title="Upload from your library"
              icon={<Upload />}
              onClick={onUploadClick}
            />
          </Wrapper>
        )}
      </div>

      {/* 
        We're hiding Filepond in the DOM, and using it programatically via a ref
      */}
      <div style={{ display: "none" }}>
        <FilePond
          allowFileTypeValidation={true}
          acceptedFileTypes={["image/jpeg", "image/png"]}
          imageTransformOutputMimeType="image/jpeg"
          onaddfilestart={onaddfilestart}
          allowImageResize={true}
          allowImageTransform={true}
          imageResizeTargetWidth={1600}
          imageResizeMode="contain"
          ref={(ref) => (pond = ref)}
          server={{
            process: async (
              fieldName,
              file,
              metadata,
              load,
              error,
              progress,
              abort
            ) => {
              try {
                setUploading(true)
                const response = await API.uploadImage(
                  file,
                  (progressEvent) => {
                    progress(true, progressEvent.loaded, progressEvent.total)
                    setPercent(progressEvent.loaded / progressEvent.total)
                  }
                )

                const { imageUrl, imageWidth, imageHeight } = response.data

                load(imageUrl)
                onImageChange(imageUrl, imageWidth, imageHeight)
              } catch (error) {
                console.error(
                  "Error occured whilst uploading comment image",
                  error
                )
              } finally {
                setUploading(false)
              }
            },
          }}
        />
      </div>
    </div>
  )
}

Choose.propTypes = {
  onImageChange: PropTypes.func.isRequired,
  style: PropTypes.object,
}
