import React from "react"
import BaseUpdateForm from "../status-update/BaseUpdateForm"
import { validStringValue } from "../../styles/core/forms"

export const TextUpdate = (props) => {
  return (
    <BaseUpdateForm
      canSubmitValidator={(values) => {
        return (
          validStringValue(values.title) || validStringValue(values.content)
        )
      }}
      {...props}
    />
  )
}
