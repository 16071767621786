import React from "react"
import styled from "styled-components"
import { ReactComponent as Back } from "../../static/icons/back.svg"

import {
  StatusUpdateContainer,
  UpdateTabContent,
  TabIcon,
  TabContainer,
  TabIcons,
  TabContainerReveal,
} from "./styles"

import { ReactComponent as LinkIcon } from "../../static/status-types/Link.svg"
import { ReactComponent as PictureIcon } from "../../static/status-types/Picture.svg"
import { ReactComponent as TextIcon } from "../../static/status-types/Text.svg"
import { ReactComponent as VideoIcon } from "../../static/status-types/Video.svg"

import { TextUpdate } from "../text-update/text-update"
import { ImageUpdate } from "../image-update/image-update"
import { LinkUpdate } from "../link-update/link-update"
import { VideoUpdate } from "../video-update/video-update"
import {
  cleanForVideoUpdate,
  cleanForLinkUpdate,
  determineUpdateType,
  updateType,
} from "."

class StatusUpdate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: -1,
    }
  }

  componentDidUpdate(oldProps) {
    const { editableUpdate, selectedImageAssetId } = this.props

    if (editableUpdate) {
      const isEditingExistingUpdate = editableUpdate.id !== undefined
      const hasSelectedAnImageFromSidebar = selectedImageAssetId !== undefined
      const hasSelectedTweetFromSidebar =
        editableUpdate.linkEmbedUrl !== undefined

      if (
        isEditingExistingUpdate ||
        hasSelectedAnImageFromSidebar ||
        hasSelectedTweetFromSidebar
      ) {
        const newIndex = this.determineTabIndex(editableUpdate)
        if (newIndex !== this.state.tabIndex) {
          this.setState({
            tabIndex: newIndex,
          })
        }
      }
    }

    if (!oldProps.updatePending && this.props.updatePending) {
      this.setState({
        inProgress: true,
      })
    }
  }

  handleSubmit = async (data) => {
    const { sendUpdate, sendEditUpdate, editableUpdate } = this.props
    data.eventId = this.props.eventId

    const isInEditMode =
      editableUpdate !== undefined && editableUpdate.id !== undefined

    if (isInEditMode) {
      await sendEditUpdate(data)
    } else {
      await sendUpdate(data)
    }

    // After submission, there should be no editable update data in state
    this.props.setEditableUpdate({})
  }

  generateTabs = () => {
    const { editableUpdate, eventId, clientType } = this.props

    // Share clean up helper for forms
    const onSubmitComplete = () => {
      this.props.clearTwitterAssetUrl()
      this.props.clearImageAssetId()
      this.props.cancelUpdateEdit()
    }

    const onCancelEdit = () => {
      this.props.cancelUpdateEdit()
    }

    // Called when a user cancels a new update.
    const onCancelNew = () => {
      // When we cancel a new update, we also
      // clear any editable data or sidebar assets
      // as they may have been selected as part of
      // the new post
      this.props.cancelUpdateEdit()
      this.props.clearTwitterAssetUrl()
      this.props.clearImageAssetId()
    }

    const sharedFormProps = {
      eventId,
      clientType,
      clientEventTypes: this.props.clientEventTypes,
      onSubmit: this.handleSubmit,
      onSubmitComplete: onSubmitComplete,
      onCancelEdit: onCancelEdit,
      onCancelNew: onCancelNew,
    }

    return [
      {
        icon: TextIcon,
        content: (
          <UpdateTabContent>
            <TextUpdate {...sharedFormProps} initialValues={editableUpdate} />
          </UpdateTabContent>
        ),
      },
      {
        icon: PictureIcon,
        content: (
          <UpdateTabContent>
            <ImageUpdate
              {...sharedFormProps}
              initialValues={editableUpdate}
              shouldInitialiseWithTime={
                editableUpdate &&
                !editableUpdate.matchTime &&
                editableUpdate.imageUrl
              }
            />
          </UpdateTabContent>
        ),
      },
      {
        icon: VideoIcon,
        content: (
          <UpdateTabContent>
            <VideoUpdate
              {...sharedFormProps}
              initialValues={cleanForVideoUpdate(editableUpdate)}
              shouldInitialiseWithTime={
                editableUpdate &&
                !editableUpdate.matchTime &&
                editableUpdate.videoEmbedUrl
              }
            />
          </UpdateTabContent>
        ),
      },
      {
        icon: LinkIcon,
        content: (
          <UpdateTabContent>
            <LinkUpdate
              {...sharedFormProps}
              initialValues={cleanForLinkUpdate(editableUpdate)}
              shouldInitialiseWithTime={
                editableUpdate &&
                !editableUpdate.matchTime &&
                editableUpdate.linkEmbedUrl
              }
              onDeleteLinkEmbed={() => {
                // If the user deletes the embed, we'll delete the
                // twitter asset URL just in case they selected it
                // from the sidebar
                this.props.clearTwitterAssetUrl()
              }}
            />
          </UpdateTabContent>
        ),
      },
    ]
  }

  determineTabIndex = (update) => {
    const type = determineUpdateType(update)
    switch (type) {
      case updateType.text:
        return 0
      case updateType.picture:
        return 1
      case updateType.video:
        return 2
      case updateType.link:
        return 3
      default:
        throw Error(`Unsupported update type: ${type}`)
    }
  }

  render() {
    const { tabIndex } = this.state

    // Moving this out into this component.
    // Still inefficient as, but it's a step towards
    // cleaning this up
    const tabs = this.generateTabs()

    return (
      <StatusUpdateContainer>
        <div style={{ position: "relative" }}>
          <TabIcons>
            {tabs.map((tab, index) => {
              const active = index === tabIndex
              let hide = tabIndex > -1 && !active

              return (
                <TabIcon
                  hide={hide}
                  key={`tab${index}`}
                  active={active}
                  onClick={() => {
                    if (this.state.tabIndex !== index) {
                      this.setState({
                        tabIndex: index,
                      })
                    }
                  }}
                >
                  {React.createElement(tab.icon)}
                </TabIcon>
              )
            })}
            {tabIndex > -1 && (
              <CloseButtonWrap>
                <CloseFormButton
                  onClick={(e) => {
                    e.preventDefault()
                    if (
                      this.props.editableUpdate &&
                      this.props.editableUpdate.id
                    ) {
                      // If the user closes the tabs whilst editing, let's cancel
                      // the update first. Otherwise, the componentDidUpdate function
                      // above will run again and force the tab related to the editable
                      // update open again.
                      this.props.cancelUpdateEdit()
                    }

                    this.setState({ tabIndex: -1 })
                  }}
                >
                  <Back /> Close
                </CloseFormButton>
              </CloseButtonWrap>
            )}
          </TabIcons>
          <TabContainerReveal active={tabIndex > -1}>
            {tabIndex > -1 && (
              <TabContainer>{tabs[tabIndex].content}</TabContainer>
            )}
          </TabContainerReveal>
        </div>
      </StatusUpdateContainer>
    )
  }
}

const CloseFormButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 200ms ease-in-out;
  font-size: 0.8em;

  transition: color 200ms ease-in-out;

  :hover {
    color: ${(props) => props.theme.colors.lightBlue};

    svg {
      g {
        transition: fill 200ms ease-in-out;
        fill: ${(props) => props.theme.colors.lightBlue};
      }
    }
  }

  svg {
    transform: rotate(180deg);
    width: 15px;
    height: 15px;
    margin-right: 0.5em;
  }
`

const CloseButtonWrap = styled.div`
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadein {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  border-left: 1px solid ${(props) => props.theme.colors.lightGrey};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1em;
  animation-duration: 500ms;
  animation-delay: 0;
  animation-name: fadein;
`

export default StatusUpdate
