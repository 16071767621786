import styled from "styled-components"

export const Container = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  height: auto;
  max-height: 1500px;
  &.is-collapsed {
    max-height: 0;
  }
`
