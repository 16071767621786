import React, { Fragment } from "react"
import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "react-grid-system"
import Fade from "react-reveal/Fade"

import { eventsVariables } from "../config"

import DefaultLayout from "../layouts/index"
import GeneralContainer from "../layouts/containers/container"

import auth from "../Auth"

import List from "../components/list/list"
import LoadMoreButton from "../components/load-more-button"
import Button from "../components/form-components/button/button"
import FilterSelect from "../components/form-components/filter-select/select"
import MaxWidthContainer from "../layouts/containers/max-width-container"

import { Logo, LogoWrapper, Banner } from "../styles/pages"

import {
  DARK_BLUE,
  MID_BLUE,
  WHITE,
  LIGHT_GREY,
  LIGHT_BLUE,
} from "../styles/core/colours"

import useEventsFetch from "./eventsReducer"
import { formatDate } from "../utils/time"
import TopNavigation from "../components/top-navigation/top-navigation"
import { AccountIcons } from "../styles/pages/editor"
import { ReactComponent as ProfileIcon } from "../static/profile.svg"
import styled from "styled-components"
import { ReactComponent as Stadion } from "../static/stadion_logo.svg"

export const GENERIC_CLIENT_IDENTIFIER = "generic"
export const FOOTBALL_CLIENT_IDENTIFIER = "football"
export const RUGBY_CLIENT_IDENTIFIER = "rugby"

const clientType = GENERIC_CLIENT_IDENTIFIER

const EventListItemDetails = ({ name, competition }) => {
  return (
    <div>
      <div style={{ fontWeight: 400 }}>{name}</div>{" "}
      <div
        style={{
          fontStyle: "italic",
          marginTop: "0.4em",
          fontSize: "0.9em",
        }}
      >
        {competition}
      </div>
    </div>
  )
}

const EditorWrapper = ({ children }) => {
  return <DefaultLayout>{children}</DefaultLayout>
}

const MainEvents = (props) => {
  const {
    season,
    // isLoading,
    // hasErrored,
    hasUpcomingEvents,
    hasPastEvents,
    // errorMessage,
    data,
    getPastEvents,
    getUpcomingEvents,
    setSeason,
    client,
    seasonList,
  } = useEventsFetch("", []) //TODO: pass in a function to do the routing

  const handleSeasonChange = async (e) => {
    await setSeason(e.currentTarget.value)
  }

  const handleShowAllPastEvents = async (itemCountToRequest) => {
    await getPastEvents(itemCountToRequest, season)
  }

  const handleShowAllUpcomingEvents = async (itemCountToRequest) => {
    await getUpcomingEvents(itemCountToRequest)
  }

  const { upcomingEvents, pastEvents } = data

  // had to duplicate this because refactoring a common header
  // out will take too much time right now
  const renderTopNavigation = () => {
    return (
      <TopNavigation
        hideBackButton
        left={<Fragment />}
        right={
          <AccountIcons style={{ marginRight: "2em" }}>
            <button onClick={() => auth.signOut()}>
              <ProfileIcon />
            </button>
          </AccountIcons>
        }
        condensed
      />
    )
  }
  return (
    <>
      <GeneralContainer colour={DARK_BLUE}>
        {renderTopNavigation()}
        <MaxWidthContainer>
          <Container fluid style={{ margin: "0 20px" }}>
            <Row>
              <Col>
                <Banner>
                  <Fade>
                    <LogoWrapper>
                      {client && client.logo && <Logo src={client.logo} />}
                    </LogoWrapper>
                  </Fade>
                  <div>
                    <Button
                      buttonStyle="light"
                      type="link"
                      href="/create-event"
                      label="New Event"
                    />
                  </div>
                </Banner>
              </Col>
            </Row>
          </Container>

          {hasUpcomingEvents ? (
            <Container fluid style={{ margin: "0 20px" }}>
              <Row>
                <Col>
                  <List
                    title="Upcoming Events"
                    borderColor={MID_BLUE}
                    borderHighlightColor={LIGHT_BLUE}
                    items={upcomingEvents.items.map((event) => ({
                      columnOne: (
                        <EventListItemDetails
                          name={event.name}
                          competition={event.competition}
                        />
                      ),
                      columnTwo: formatDate(event.eventDate),
                      columnThree: event.id,
                      linkUrl: `/editor/${event.id}`,
                    }))}
                  />

                  {upcomingEvents.totalEvents >
                    eventsVariables.upcomingEventsDefaultCount && (
                    <LoadMoreButton
                      buttonStyle="transparentLight"
                      totalCount={upcomingEvents.totalEvents}
                      maxCount={eventsVariables.upcomingEventsDefaultCount}
                      handler={handleShowAllUpcomingEvents}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          ) : null}
        </MaxWidthContainer>
      </GeneralContainer>

      <GeneralContainer colour={WHITE}>
        <MaxWidthContainer>
          <Container fluid style={{ margin: "0 20px" }}>
            <Row>
              <Col>
                {/*TODO: change clientType to client.clientType once api is built*/}
                {clientType !== GENERIC_CLIENT_IDENTIFIER && (
                  <div
                    style={{ float: "right" /* moved from style component */ }}
                  >
                    {seasonList && seasonList.length > 0 && (
                      <FilterSelect
                        options={seasonList}
                        handleChange={handleSeasonChange}
                      />
                    )}
                  </div>
                )}

                {hasPastEvents ? (
                  <List
                    title="Past Events"
                    borderColor={LIGHT_GREY}
                    borderHighlightColor={LIGHT_BLUE}
                    items={pastEvents.items.map((event) => ({
                      columnOne: (
                        <EventListItemDetails
                          name={event.name}
                          competition={event.competition}
                        />
                      ),
                      columnTwo: formatDate(event.eventDate),
                      columnThree: event.id,
                      linkUrl: `/editor/${event.id}`,
                    }))}
                  />
                ) : null}
                {hasPastEvents &&
                  pastEvents.totalEvents >
                    eventsVariables.pastEventsDefaultCount && (
                    <LoadMoreButton
                      buttonStyle="transparentDark"
                      totalCount={pastEvents.totalEvents}
                      maxCount={eventsVariables.pastEventsDefaultCount}
                      handler={handleShowAllPastEvents}
                    />
                  )}
              </Col>
            </Row>
          </Container>
        </MaxWidthContainer>
        <StadionLogoWrapper>
          <Stadion />
        </StadionLogoWrapper>
      </GeneralContainer>
    </>
  )
}

/**
 * This is a quick fix pattern to overcome the issues with state, without rearchitecting.
 * By extracting the layout out of the main events component, it prevents the browser from
 * rerendering the whole  layout out again and again.
 * One issue we had was that fonts would flicker because the CSS fonts would keep loading, and
 * the app would cause many duplicate network requests.
 */
const Events = (props) => {
  return (
    <EditorWrapper>
      <MainEvents {...props} />
    </EditorWrapper>
  )
}

const StadionLogoWrapper = styled.div`
  margin-top: 5em;
  padding-right: 3em;
  text-align: right;
`

export default withRouter(Events)
