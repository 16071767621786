// I've named this simial to the existing. Will clean this up when I go through to tidy everything at the end
import React, { useState } from "react"
import styled from "styled-components"
import {
  getInputBorderBottom,
  getFocusBorderBottom,
  getInputWrapBorderBottom,
  getPlaceholderColor,
} from "../../../styles/core/forms"

const InputWrapper = styled.div`
  padding: 0.4em 0 0 0;
  position: relative;
`
const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`
const StyledInput = styled.input`
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  background: none;
  border: 0;
  border-radius: 0;
  ${(props) => getInputBorderBottom(props)}
  padding: 10px 5px 16px 0;
  width: 100%;
  font-weight: 300;

  :focus {
    outline: none;
    ${(props) => getFocusBorderBottom(props)}/* padding-bottom: 15px; */
  }

  ::placeholder {
    ${(props) => getPlaceholderColor(props)}
  }
`

// We use a border wrapper to prevent jarring when the border size
// changes on focus
const BorderWrapper = styled.div`
  ${(props) => getInputWrapBorderBottom(props)}
`
export const Input = (props) => {
  const { icon, inputRef } = props
  const [focus, setFocus] = useState()

  return (
    <InputWrapper>
      <BorderWrapper focus={focus} isError={props.isError}>
        <StyledInput
          ref={inputRef}
          {...props}
          onFocus={(e) => {
            setFocus(true)
            props.onFocus && props.onFocus(e)
          }}
          onBlur={(e) => {
            setFocus(false)
            props.onBlur && props.onBlur(e)
          }}
        />
      </BorderWrapper>
      {icon && <IconWrapper>{icon}</IconWrapper>}
    </InputWrapper>
  )
}
