import React from "react"
import { Route } from "react-router-dom"
import auth0Client from "../Auth"
import { LoadingOverlay } from "../components/loading-overlay/LoadingOverlay"

function SecuredRoute(props) {
  const { component: Component, path, checkingSession } = props

  return (
    <Route
      path={path}
      render={(props) => {
        if (checkingSession) {
          return <LoadingOverlay />
        }

        if (!auth0Client.isAuthenticated()) {
          auth0Client.signIn()
          return <div />
        }
        return <Component {...props} />
      }}
    />
  )
}

export default SecuredRoute
